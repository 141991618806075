import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { PICK_UP_ORDER_TYPE } from '../../constants';
import { useAppSelector } from '../../redux/store/store';
import CustomModal from '../customModal/CustomModal';
import ItemDeliveryDateAndTime from './ItemDeliveryDateAndTime';
import { OrderType } from './ItemPickup/PickupMethod/OrderType';

/*
 * TODO: use context to get list of items added in cart and calculate the bill
 */
interface IItemPickupDeliveryModal {
  subTotal?: number;
  tax?: number;
  total?: number;
  handleChangeOrderType?: boolean;
  openDeliveryMethodModal?: any;
  openPickupMethodModal?: any;
  handleClosePickupMethodModal?: (boolean) => void;
  handleCloseDeliveryMethodModal?: (boolean) => void;
  openPickupMethodModalOnCheckout?: boolean;
  openDeliveryMethodModalOnCheckout?: boolean;
  editPickupType?: (boolean) => void;
  editDeliveryType?: (boolean) => void;
  isEditSlot?: boolean;
}

const ItemPickupDeliveryModal = (props: IItemPickupDeliveryModal) => {
  const {
    subTotal,
    tax,
    total,
    handleChangeOrderType,
    openDeliveryMethodModal,
    openPickupMethodModal,
    handleClosePickupMethodModal,
    handleCloseDeliveryMethodModal,
    openPickupMethodModalOnCheckout,
    openDeliveryMethodModalOnCheckout,
    editPickupType,
    editDeliveryType,
    isEditSlot,
  } = props;

  const [openPickupMethod, setOpenPickupMethodModal] = useState(false);
  const [openDeliveryMethod, setOpenDeliveryMethodModal] = useState(false);
  const savedOrderType = useAppSelector((state) => state.cart.orderType);

  useEffect(() => {
    if (openPickupMethodModal || openDeliveryMethodModal) {
      if (savedOrderType == PICK_UP_ORDER_TYPE) setOpenPickupMethodModal(true);
      else setOpenDeliveryMethodModal(true);
    }
  }, [openPickupMethodModal, openDeliveryMethodModal]);

  useEffect(() => {
    if (openPickupMethodModalOnCheckout) {
      setOpenPickupMethodModal(true);
    }
    if (openDeliveryMethodModalOnCheckout) {
      setOpenDeliveryMethodModal(true);
    }
  }, [openPickupMethodModalOnCheckout, openDeliveryMethodModalOnCheckout]);

  const closePickUpMethodModal = () => {
    setOpenPickupMethodModal(false);
    handleClosePickupMethodModal(true);
  };
  const closeDeliveryMethodModal = () => {
    setOpenDeliveryMethodModal(false);
    handleCloseDeliveryMethodModal(true);
  };
  const editPickupTypeMethod = () => {
    editPickupType(true);
  };
  const editDeliveryTypeMethod = () => {
    editDeliveryType(true);
  };

  return (
    <>
      <CustomModal
        showModal={openPickupMethod}
        title={''}
        closeModal={closePickUpMethodModal}
        modalDialogClasses={'itemOrderModal'}
        showClose={true}
        scrollable={false}
      >
        <OrderType
          subTotal={subTotal}
          tax={tax}
          total={total}
          isEditSlot={isEditSlot}
          closePickUpMethodModal={closePickUpMethodModal}
          editPickupType={editPickupTypeMethod}
          handleChangeOrderType={handleChangeOrderType}
        />
      </CustomModal>
      {/* Delivery Modal */}
      <Modal
        show={openDeliveryMethod}
        centered
        className="theme-modal-style app_container_wrap  border-radius-15 px-md-5 order_type_modal select_date_time"
        onHide={closeDeliveryMethodModal}
      >
        <Modal.Header className="x-4 justify-content-center">
          <button
            type="button"
            className="btn modal-close-button pe-0"
            onClick={closeDeliveryMethodModal}
          >
            Close
          </button>
          <h5 className="f-s22 font-Cls text-center fw-normal pt-5 pb-0 mb-0 text-capitalize">
            Select a Date & Time
          </h5>
        </Modal.Header>
        <Modal.Body>
          <ItemDeliveryDateAndTime
            subTotal={subTotal}
            tax={tax}
            total={total}
            isEditSlot={isEditSlot}
            closeDeliveryMethodModal={closeDeliveryMethodModal}
            editDeliveryType={editDeliveryTypeMethod}
            handleChangeOrderType={handleChangeOrderType}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ItemPickupDeliveryModal;
