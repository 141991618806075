import React from 'react';
import { Container } from 'react-bootstrap';
import { ITEM_LOCATION, s3BaseUrl } from 'src/constants';

import SelectCard from '../../../../components/Cards/SelectCard/SelectCard';
import {
  ICoreModifiers,
  IRefStatesForScrolling,
  ISubCategories,
  ItemDetails,
} from '../../../../models/item.model';
import { verifyStatus } from 'src/helper/customizedItem/customizedItem.helper';
import styles from './Packeges.module.scss';

type proTypes = {
  trays: ISubCategories[];
  handleSelect: (item: ItemDetails) => void;
  selectedItem: string | number;
  openItemInfoModal: (item: ItemDetails) => void;
  requiredItemForDynamicSelectedItemAtItemDetailSectionToHandleScroll: IRefStatesForScrolling;
};

const PackegeItemSelect: React.FC<proTypes> = (props) => {
  const {
    trays,
    handleSelect,
    selectedItem,
    openItemInfoModal,
    requiredItemForDynamicSelectedItemAtItemDetailSectionToHandleScroll,
  } = props;

  const showRedBorderAnimation = (index) => {
    return requiredItemForDynamicSelectedItemAtItemDetailSectionToHandleScroll
      ? !requiredItemForDynamicSelectedItemAtItemDetailSectionToHandleScroll
          .isRequiredModifierSelected.status &&
          requiredItemForDynamicSelectedItemAtItemDetailSectionToHandleScroll
            .isRequiredModifierSelected.highLightArea === index
      : false;
  };
  const requiredTrays = useGetRequiredTrays(trays);

  return (
    <React.Fragment>
      {requiredTrays?.map((tray, index) => (
        <Container fluid className="px-0">
          <div className="single-item-modifiers row">
            <h3
              className="f-s20 font-Cls clr-dark-gray f-sm-s18"
              ref={
                requiredItemForDynamicSelectedItemAtItemDetailSectionToHandleScroll
                  ? requiredItemForDynamicSelectedItemAtItemDetailSectionToHandleScroll
                      .refToScrollToMissingRequiredModifiers[index]
                  : null
              }
            >
              {tray.name}
            </h3>
            {tray.items?.map((modifier) => {
              return (
                <div
                  key={modifier?.id.toString()}
                  className={`item__modifier_item mb-3 col-lg-4 col-md-3 col-sm-4 col-4 px-2 ${styles.packegesSaladCard}`}
                >
                  <SelectCard
                    id={modifier.id.toString()}
                    name={modifier.name}
                    // clories={modifier.calories}
                    img={`${s3BaseUrl}${modifier?.image}`}
                    modifier={modifier}
                    handleSelect={handleSelect}
                    selectedItem={selectedItem?.toString()}
                    openItemInfoModal={() => {
                      openItemInfoModal(modifier);
                    }}
                    showRedBorderAnimation={showRedBorderAnimation(index)}
                    isActive={verifyStatus(modifier, ITEM_LOCATION)}
                  />
                </div>
              );
            })}
          </div>
        </Container>
      ))}
    </React.Fragment>
  );
};

export default PackegeItemSelect;

const useGetRequiredTrays = (trays: ISubCategories[]) => {
  const requriedTrays = trays.filter(
    (tray: ISubCategories) => tray.items.length > 1,
  );
  return requriedTrays;
};
