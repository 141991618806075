import { useState } from 'react';
import styles from './header.module.scss';

const LiveOrder = ({ setMultiOrderModal }) => {
  return (
    <>
      <div className={`py-2 ${styles.live_order_bar}`} id={'live-order-bar'}>
        <p className="mb-0 f-s16 f-sm-s12 clr-white f-w8 text-center text-uppercase lh-lg">
          Live Order |{' '}
          <a
            onClick={() => {
              setMultiOrderModal(true);
            }}
            className="pe-auto cursor-pointer clr-white text-decoration-underline"
          >
            click for details
          </a>
        </p>
      </div>
    </>
  );
};

export default LiveOrder;
