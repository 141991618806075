import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Accordion } from 'react-bootstrap';
import { DO_NOT_SHOW } from 'src/constants';
import styleClasses from '../../../../src/Features/ItemCustomization/ItemCustomization.module.scss';
import ItemModifierGroups from './ModifierGroups/ItemModifierGroups';
import { IModifierGroups, IParams } from 'src/models/item.model';

const ModifierGroups: React.FC<any> = (props): JSX.Element => {
  const {
    otherModifierJSXWithByDefaultAccordionControl,
    otherModifierJSXWithCustomAccordionControl,
  }: {
    otherModifierJSXWithByDefaultAccordionControl: JSX.Element[];
    otherModifierJSXWithCustomAccordionControl: JSX.Element;
  } = useGetModifierGroupsJSX(props);

  return (
    <>
      {props.isCreateYourOwnItem
        ? otherModifierJSXWithCustomAccordionControl
        : otherModifierJSXWithByDefaultAccordionControl}
    </>
  );
};

const useGetModifierGroupsJSX = (props: any) => {
  const {
    modifierGroups,
    selectedAddOns,
    selectedCore,
    isLimitExceed,
    handleClick,
    isItEdit,
    cardColSize,
    isBoxLunch,
    Item,
    isPackege = false,
    isCreateYourOwnItem,
    modifierGroupScrolling,
    scrollToNextGroupOnExtendableSelection,
  } = props;

  const [openAccordions, setOpenAccordions] = React.useState<string[]>(
    modifierGroupScrolling
      ? [...modifierGroupScrolling.current.valForAccordian]
      : [],
  );

  useEffect(() => {
    if (isCreateYourOwnItem && modifierGroupScrolling.current.scroll) {
      let scrollTimeoutId;
      clearTimeout(scrollTimeoutId);
      scrollTimeoutId = setTimeout(() => {
        modifierGroupScrolling.current.scroll();
        modifierGroupScrolling.current.scroll = null;
      }, 200);
    }
  }, [openAccordions]);

  useEffect(() => {
    if (!isCreateYourOwnItem) return;
    if (
      modifierGroupScrolling.current.currentMissingIndex !== null &&
      openAccordions.findIndex(
        (value: string) =>
          value === modifierGroupScrolling.current.currentMissingIndex,
      ) < 0
    ) {
      handleToggle(
        undefined,
        modifierGroupScrolling.current.currentMissingIndex,
        'modifier',
      );
      modifierGroupScrolling.current.currentMissingIndex = null;
    } else if (modifierGroupScrolling.current.currentMissingIndex !== null) {
      if (modifierGroupScrolling.current.scroll) {
        let scrollTimeoutId;
        clearTimeout(scrollTimeoutId);
        scrollTimeoutId = setTimeout(() => {
          modifierGroupScrolling.current.scroll();
          modifierGroupScrolling.current.scroll = null;
        }, 200);
      }
      modifierGroupScrolling.current.currentMissingIndex = null;
    }
  }, [modifierGroupScrolling?.current?.currentMissingIndex]);

  const handleToggle = (
    groupId: number | undefined,
    id: string,
    clickedOn: 'accordion' | 'modifier',
  ): void => {
    const indexOfCurrentId = openAccordions.indexOf(id);
    const newData = [...openAccordions];
    let openCurrentAccorion: any = null;
    if (groupId && indexOfCurrentId == -1) {
      openCurrentAccorion = scrollToNextGroupOnExtendableSelection(undefined, {
        name: clickedOn,
        groupId,
      });
    }
    if (
      (!groupId && indexOfCurrentId == -1) ||
      (indexOfCurrentId == -1 && openCurrentAccorion?.status == false)
    ) {
      newData.push(id);
    } else {
      newData.splice(indexOfCurrentId, 1);
    }
    setOpenAccordions(newData);
  };

  const otherModifierJSXWithByDefaultAccordionControl: JSX.Element[] =
    modifierGroups &&
    modifierGroups.map((ingredients: any, index: number): JSX.Element => {
      const label: string = ingredients?.label?.toLowerCase();
      return (
        <React.Fragment>
          {ingredients?.modifiers_groups_modifiers?.length > 0 ? (
            <React.Fragment key={ingredients.id}>
              {showGroup(ingredients) ? (
                <Container
                  fluid
                  className={`${styleClasses.try2_all_dressing} modifiersContainer`}
                >
                  <Accordion
                    defaultActiveKey={''}
                    className="modifiers-accordions-wrapper"
                    // alwaysOpen
                  >
                    <Accordion.Item eventKey={`${index}`}>
                      {accordionHeader({ index, label, ingredients })}
                      {accordionBody(ingredients)}
                    </Accordion.Item>
                  </Accordion>
                </Container>
              ) : null}
            </React.Fragment>
          ) : null}
        </React.Fragment>
      );
    });

  const otherModifierJSXWithCustomAccordionControl: JSX.Element =
    modifierGroups && (
      <Accordion
        className="modifiers-accordions-wrapper"
        activeKey={openAccordions}
      >
        {modifierGroups.map(
          (ingredients: IModifierGroups, index: number): JSX.Element => {
            const label: string = ingredients?.label?.toLowerCase();
            return (
              <React.Fragment key={ingredients.id}>
                {showGroup(ingredients) ? (
                  <Container
                    fluid
                    className={`${styleClasses.try2_all_dressing} modifiersContainer`}
                  >
                    <Accordion.Item eventKey={`${index}`} key={index}>
                      <span
                        onClick={() =>
                          handleToggle(
                            ingredients.id,
                            index.toString(),
                            'accordion',
                          )
                        }
                      >
                        {accordionHeader({ index, label, ingredients })}
                      </span>
                      {accordionBody(ingredients)}
                    </Accordion.Item>
                  </Container>
                ) : null}
              </React.Fragment>
            );
          },
        )}
      </Accordion>
    );

  function accordionHeader({
    index,
    label,
    ingredients,
  }: {
    index: number;
    label: string;
    ingredients: IModifierGroups;
  }): JSX.Element {
    return (
      <Accordion.Header>
        <span
          className="d-block text-capitalize"
          ref={
            isCreateYourOwnItem
              ? (el) =>
                  (modifierGroupScrolling.current.refsToCYOIAddOnsModifier[
                    index
                  ] = el)
              : null
          }
        >
          {label}
          <span className="f-s13 f-w6 font-rale clr-dark-grey d-block lh-normal">
            {isCreateYourOwnItem && ingredients.max > 0 && (
              <span className="caption fs-14">{ingredients.max} Included</span>
            )}
          </span>
        </span>
      </Accordion.Header>
    );
  }

  function accordionBody(ingredients: IModifierGroups): JSX.Element {
    return (
      <Accordion.Body>
        <Row>
          <ItemModifierGroups
            ingredients={ingredients}
            selectedAddOns={selectedAddOns}
            selectedCore={selectedCore}
            handleClick={(
              currentModifier: IParams,
              modifiers: IModifierGroups,
              type: string,
            ) =>
              handleModifierClick(currentModifier, modifiers, type, ingredients)
            }
            isLimitExceed={isLimitExceed}
            isCreateYourOwnItem={isCreateYourOwnItem}
            isItEdit={isItEdit}
            itemModifierItemModalWidth={''}
            cardColSize={cardColSize}
            Item={Item}
          />
        </Row>
      </Accordion.Body>
    );
  }

  function handleModifierClick(
    currentModifier: IParams,
    modifiers: IModifierGroups,
    type: string,
    ingredients,
  ): void {
    const modifier = {
      ...currentModifier,
      ...{
        modifier_group_id: ingredients?.id,
        modifier_group_max: ingredients?.max,
        modifier_group_base: ingredients?.base,
      },
    };
    handleClick(modifier, modifiers, type);
  }

  function showGroup(ingredients: IModifierGroups): boolean {
    if (
      ingredients.modifiers_groups_modifiers.length <= 0 ||
      ingredients?.name?.toLowerCase().includes(DO_NOT_SHOW)
    ) {
      return false;
    } else {
      return true;
    }
  }

  return {
    otherModifierJSXWithByDefaultAccordionControl,
    otherModifierJSXWithCustomAccordionControl,
  };
};

export default ModifierGroups;
