import React, { FC, useState } from 'react';
import { InfoOutline } from 'src/assets/images/Svgs/svg';
import { ItemDetails } from 'src/models/item.model';
import { awsAssetsFetcher } from 'src/utils/awsAssetsFetcher';

import styles from './SelectCard.module.scss';
import NotAvailable from 'src/components/Button/NotAvailable';
import { selectedBtnStyle } from 'src/components/Molecules/Card/cardStyle';

export interface SelectCardProps {
  setItemInfo?: any;
  img?: any;
  id?: string;
  name?: string;
  clories?: string;
  handleSelect?: (item: ItemDetails) => void;
  modifier?: any;
  selectedItem?: any;
  openItemInfoModal?: any;
  showRedBorderAnimation?: boolean;
  isActive?: boolean;
}
const SelectCard: FC<SelectCardProps> = ({
  setItemInfo,
  img,
  name,
  id,
  clories,
  handleSelect,
  modifier,
  selectedItem,
  openItemInfoModal,
  showRedBorderAnimation,
  isActive,
}) => {
  const [isSelected, setSelected] = useState(false);
  const [infoOutline, setInfoOutline] = useState(true);

  const assignClassBasedOnSelectedItemId = (
    primaryClass: string | null,
    secondaryClass: string | null,
  ) => {
    if (selectedItem === id) {
      return primaryClass;
    }
    return secondaryClass;
  };

  const handleOnCartClick = () => {
    if (modifier.id == selectedItem) return;
    if (!isActive) return;
    setSelected(!isSelected);
    handleSelect(modifier);
  };
  const handleInfoOutline = (e: any) => {
    openItemInfoModal(modifier);
    e.stopPropagation();
  };
  return (
    <div
      className={`position-relative ${
        styles.select_card_wrapper
      } ${assignClassBasedOnSelectedItemId(styles.card_selected, null)} ${
        showRedBorderAnimation ? 'border-size-select' : ''
      }`}
      style={{
        ...(!isActive
          ? { opacity: 0.7, cursor: 'auto' }
          : { cursor: 'pointer' }),
      }}
      onClick={handleOnCartClick}
    >
      <div className={styles.img_wrapper}>
        <img src={img} alt="img" />
      </div>
      <div className={styles.selectCard_inner}>
        <h3 className="f-s14 font-rale text-center cz-my-13 text-uppercase">
          {name.toLowerCase()}
          <span className={`${styles.toolTipText} text-uppercase`}>
            {name.toLowerCase()}
          </span>
        </h3>
        <div className={`${styles.buttons_wraper} text-center`}>
          {isActive ? (
            <button
              className={`${assignClassBasedOnSelectedItemId(
                styles.button_selected,
                styles.select_btn,
              )} item-select-btn w-md-50 mb-2 f-w5`}
            >
              {assignClassBasedOnSelectedItemId('Selected', 'Select')}
            </button>
          ) : (
            <NotAvailable selectedBtnStyle={selectedBtnStyle} />
          )}

          {infoOutline && (
            <div
              onClick={handleInfoOutline}
              className={`d-md-block d-none ${styles.info_outline}`}
            >
              <InfoOutline />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SelectCard;
