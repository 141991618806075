import React from "react";
import ContinueToCheckoutForm from "../GuestCheckout/ContinueToCheckoutForm";
import {IUserStatusData} from "../../models/customer.modal";
import DynamicContactInfoForm from "../GuestCheckout/DynamicContactInfoForm";
import CustomModal from "../../components/customModal/CustomModal";

interface IGuestCheckoutModalProps {
    showContactForm: boolean;
    handleCloseContactForm: () => void;
    setShowContactInfo : (value: boolean) => void;
    currentGuestUserInfo?: any;
    showContactInfo?: boolean;
    handleSubmitAsGuest: () => void;
    userStatusData?: IUserStatusData;
}

const GuestCheckoutModal = (props: IGuestCheckoutModalProps) => {

    const { showContactForm, handleCloseContactForm, handleSubmitAsGuest, userStatusData, ...rest } = props;
    return (
        <>
            <CustomModal
                showModal={showContactForm}
                title={''}
                closeModal={handleCloseContactForm}
                modalDialogClasses={"theme-modal-style p-0 app_container_wrap nor-padding   border-radius-15  checkout-modal-mob"}
            >
                {
                    userStatusData?.update_required ?
                        <DynamicContactInfoForm userStatusData={userStatusData} handleContentChange={handleSubmitAsGuest} closeModal={handleCloseContactForm} />
                        :
                        <ContinueToCheckoutForm
                            handleSubmitAsGuest={handleSubmitAsGuest}
                            closeModal={handleCloseContactForm}
                            {...rest}
                        />
                }
            </CustomModal>
        </>
    );
};

export default GuestCheckoutModal;
