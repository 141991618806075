import { useLocation } from 'react-router';
import OtherManuItems from 'src/containers/OtherManuItems';

import BoxLunchesContainer from '../BoxLunchesContainer';

const MasterContainer = () => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const isBoxLunch = query.get('is_box_lunch');
  return (
    <>{isBoxLunch === 'true' ? <BoxLunchesContainer /> : <OtherManuItems />}</>
  );
};

export default MasterContainer;
