type Callback = (...args: any[]) => void;

class EventEmitterWithState {
  private events: Record<string, Callback[]> = {};
  private state: any = {};

  subscribe(event: string, callback: Callback): void {
    if (!this.events[event]) {
      this.events[event] = [];
    }
    this.events[event].push(callback);
  }

  unsubscribe(event: string, callback: Callback): void {
    if (!this.events[event]) return;
    this.events[event] = [];
  }

  emit(event: string, ...args: any[]): any {
    if (!this.events[event]) return;
    return this.events[event].map((callback) => callback(...args));
  }

  updateState(newState: any): void {
    this.state = newState;
  }

  getState(): any {
    return this.state;
  }
}

const EventBusForBYOCategoryScrollingBetweenAccordions =
  new EventEmitterWithState();
export default EventBusForBYOCategoryScrollingBetweenAccordions;
