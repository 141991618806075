import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { userSliceReducer } from 'src/Features/AccountSetup/redux/slices';
import { locationSliceReducer } from 'src/Features/Location/redux/slice';

import { reducer } from '../slices/cartSlice';
import { checkoutReducer } from '../slices/checkoutSlice';
import { specificFlowStatesReducer } from '../slices/handleStatesForSpecificFlows';
import { itemCustomizationReducer } from '../slices/itemCustomizationSlice';
import { itemSliceReducer } from '../slices/itemSlice';
import { orderDetailSliceReducer } from '../slices/orderDetailSlice';
import { addPaymentSliceReducer } from '../slices/addPayment/addPaymentSlice';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['cart', 'user', 'checkout', 'location', 'specificFlowStates'],
};
const reducers = combineReducers({
  location: locationSliceReducer,
  cart: reducer,
  user: userSliceReducer,
  itemCustomization: itemCustomizationReducer,
  Items: itemSliceReducer,
  checkout: checkoutReducer,
  specificFlowStates: specificFlowStatesReducer,
  orderDetails: orderDetailSliceReducer,
  addPayment: addPaymentSliceReducer,
});

const rootReducer = (state, action) => {
  return reducers(state, action);
};

export const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export default store;
