import React, { useEffect } from 'react';
import {
  addCode,
  addExtendableLimit,
  give_me_my_classes,
} from 'src/helper/helperMethods';
import * as Constant from '../../../../constants';
import { ISelectCard } from '../../../../models/card';
import Labels from '../CardLabels/Labels';
import { selectedBtnStyle } from '../cardStyle';
import styleClasses from './selectCard.module.scss';
import NotAvailable from 'src/components/Button/NotAvailable';

const SelectCard: React.FC<ISelectCard> = (props) => {
  const {
    id,
    brink_id,
    image,
    title,
    price,
    calories,
    selected,
    handleClick,
    default_selected,
    parentClassName,
    buttonText,
    is_active,
    disable,
    priceAlternateLabel,
    modifierAdditionalPrice,
    componentUsedIn,
    extendableLimit = 0,
    extendableLimitValue,
    groupMin,
    isItEdit = false,
    nestedParentId,
    showRedBorderAnimation,
    selectBtnSpace,
    count,
    isCreateYourOwnItem,
  } = props;

  const handleSelectButtonClick = (type: string, selected: boolean) => {
    if (
      extendableLimitValue === 1 &&
      groupMin === 1 &&
      selected &&
      type !== Constant.BY_DEFAULT
    ) {
      return;
    } else {
      let payload: any = {
        modifier_id: id,
        modifier_name: title,
        display_price: price,
        modifier_calories: calories,
        modifier_group_min: groupMin,
        brink_id,
        type: type,
        image,
      };
      if (type === Constant.BY_DEFAULT) {
        payload.core = true;
      }
      payload = addCode(
        selected ? Constant.DECREASE : Constant.INCREASE,
        payload,
      );
      payload = addExtendableLimit(payload, type, extendableLimitValue);
      if (nestedParentId) payload.nested_parent_id = nestedParentId;
      handleClick(payload);
    }
  };

  const isItSubstitute = () => {
    return extendableLimitValue === 1 && groupMin === 1;
  };

  const assignBorderClass = () => {
    if (default_selected && !selected && !isItSubstitute()) {
      return give_me_my_classes(['add_on_item_wrap', 'exclude'], styleClasses);
    } else if (!default_selected && selected) {
      return give_me_my_classes(['add_on_item_wrap', 'selected'], styleClasses);
    } else {
      return '';
    }
  };

  const selectCardClickDisable = () => {
    return disable || (extendableLimit && !selected);
  };

  return (
    <div
      className={
        parentClassName ||
        `${
          styleClasses.item_wrap
        } ${assignBorderClass()} add_on_item_wrap shadow-box d-flex flex-column cursor-pointer ${
          showRedBorderAnimation ? 'border-size-select' : ''
        }`
      }
      onClick={() =>
        !selectCardClickDisable() &&
        is_active &&
        handleSelectButtonClick(Constant.SELECTED, selected)
      }
      style={{ ...(!is_active && { opacity: 0.7 }) }}
    >
      <div
        className={`${styleClasses.items_image} mb-0 flex-fill d-flex align-items-center justify-content-center items__image_wrap`}
      >
        <img
          src={image}
          className={`${selectCardClickDisable() && 'opacity-50'}`}
          alt={title}
        />
      </div>
      <div
        className={`${styleClasses.items_inn_content} ${selectBtnSpace} items__inn_content`}
      >
        <h3 className={`${styleClasses.item_title} font-rale mb-1`}>
          {title}
          <span className={`${styleClasses.tooltiptext} `}>{title}</span>
        </h3>
        {is_active ? (
          <div className={`${styleClasses.buttons_wraper}`}>
            <button
              type="button"
              className={`${styleClasses.selected_kids_combo}  item-select-btn w-md-50 mb-2 f-w5`}
              data-testid="select-card-button"
              // as per requirement, this is handled on top level select card div
              // onClick={() => handleSelectButtonClick(Constant.SELECTED, selected)}
              style={{
                ...(!disable &&
                  selected && {
                    ...selectedBtnStyle,
                  }),
                ...(disable && {
                  pointerEvents: 'none',
                }),
              }}
              disabled={selectCardClickDisable()}
            >
              {selected ? buttonText || 'Selected' : buttonText || 'Select'}
            </button>
          </div>
        ) : (
          <NotAvailable selectedBtnStyle={selectedBtnStyle} />
        )}

        <Labels
          price={price}
          priceAlternateLabel={priceAlternateLabel}
          additionalPrice={modifierAdditionalPrice}
          calories={calories}
          isItSubstitute={extendableLimitValue === 1 && groupMin === 1}
          isCreateYourOwnItem={isCreateYourOwnItem}
        />
      </div>
    </div>
  );
};

export default SelectCard;
