import { FC } from 'react';
import { persistZero } from 'src/helper/helperMethods';

import styles from './radioselect.module.scss';

interface RadioProps {
  text?: string;
  amount?: any | number;
  img?: any;
  id?: string;
  menu?: string;
  calories?: string;
  counter?: string;
  grouplabel?: string;
  className?: string;
  selected?: boolean;
  onChange?: (e: any) => void;
  isSize?: boolean;
  orignalPrice?: number;
  servingNumber?: string;
  servingText?: string;
  imageSize?: string;
  isBoxLunch?: boolean;
}

const RadioSelect: FC<RadioProps> = (props) => {
  const {
    text,
    amount,
    img,
    id,
    menu,
    calories,
    counter,
    grouplabel,
    className,
    selected,
    onChange,
    servingNumber,
    orignalPrice,
    servingText,
    imageSize,
    isBoxLunch,
  } = props;

  const displayPrice = () => {
    let itemPrice = 0;
    if (selected) {
      itemPrice = amount;
    } else {
      itemPrice = orignalPrice;
    }
    return itemPrice;
  };
  return (
    <>
      <div className={className} key={id}>
        <input
          type="radio"
          id={id}
          data-testid="6-count"
          name="radio-group"
          checked={selected}
          onClick={onChange}
        />
        <label
          htmlFor={id}
          className={`f-s18 font-Vcf w-100 shadow-box optional-selector size-selector h-100 ${grouplabel}  ${
            props.isSize ? 'border-size-select' : ''
          }`}
        >
          {img && (
            <div className="h-100 d-flex justify-content-between flex-column">
              {img && <img src={img} alt="img" className={`${imageSize}`} />}
              <span>{menu}</span>
            </div>
          )}

          <div>
            <span>{text}</span>
            <br />
            {!!amount && (
              <span
                style={{
                  color: selected && amount > orignalPrice ? 'darkred' : '',
                }}
              >
                {`$${persistZero(displayPrice())}`}
              </span>
            )}
            {calories && (
              <p className="font-Visby-cf f-s16 f-w5 mb-0">{calories}</p>
            )}
          </div>
          {!!servingNumber && (
            <p className="f-s13 font-rale f-w5 mb-0 mt-2 text-center">
              {servingNumber}
            </p>
          )}
          {!!counter && (
            <div
              className={`${styles.rounded_box} rounded-box clr-sec-white mx-auto mx-md-0`}
            >
              <span className="font-Vcf">{counter}</span>
            </div>
          )}
          {!isBoxLunch && !!servingText && (
            <p className="f-s14 font-rale f-w5 clr-light-grey mb-0">
              {servingText}
            </p>
          )}
        </label>
      </div>
    </>
  );
};

export default RadioSelect;
