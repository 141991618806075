import { useState } from 'react';
import { PlusIcon } from 'src/assets/images/Svgs/svg';
import { MinusIcon } from 'src/assets/images/Svgs/svg';

import styles from './quantityCounter.module.scss';

const QuantityCounter = () => {
  const [count, setCount] = useState(0);

  const incrementHandler = () => {
    setCount(count + 1);
  };

  const decrementHandler = () => {
    if (count > 0) {
      setCount(count - 1);
    }
  };
  return (
    <div className={styles.quantityCounterWrap}>
      <span className="text-center f-s14 clr-lt-grey d-block">Quantity</span>
      <div className={styles.counter}>
        <div onClick={decrementHandler}>
          <MinusIcon className={undefined} />
        </div>
        <span>{count}</span>
        <div onClick={incrementHandler}>
          <PlusIcon className={undefined} />
        </div>
      </div>
    </div>
  );
};
export default QuantityCounter;
