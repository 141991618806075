import styles from './location.module.scss';

const NearByLocations = ({ zipCode, markets, handleMarkets, borderNone }) => {
  return (
    <div className={`${styles.nearByLocation} pb-5`}>
      <hr className="custom_hr_sty mb-4 mt-5" />
      <h6 className=" px-0 pt-0">NEARBY LOCATIONS</h6>

      {zipCode && (
        <p>
          Showing location(s) near <span>{zipCode}</span>
        </p>
      )}
      <p className="font-Cls clr-red">{zipCode}</p>
      <p className={styles.noLocationText}>
        Unfortunately we didn’t find a location near you! <br /> For a list of
        locations in each of our states, select from the list below.
      </p>
      <ul className={styles.noFindState}>
        {typeof markets !== 'undefined' &&
          markets.map((market, index) => (
            <li
              key={market.id}
              onClick={() => handleMarkets(market.id)}
              className="font-Cls mb-2"
            >
              {/* <p className="font-Cls"> */}
              {market.name}
              {/* </p> */}
            </li>
          ))}
      </ul>
    </div>
  );
};

export default NearByLocations;
