import React, { useEffect, useState } from 'react';
import { CARD_AMOUNT_REG_EXPRESSION, PAYMENT_CARD } from 'src/constants';
import { isZeroOnStart } from 'src/helper/utils';
import { ICheckoutPayment } from 'src/models/payment.model';

import styleClasses from '../order.module.scss';
import { TrashIcon } from 'src/assets/images/Svgs/svg';
interface ISelectedPaymentCardProps {
  paymentCard?: ICheckoutPayment;
  index?: number;
  removePayment?: (payment: string) => void;
  handleCardSplitAmount?: (amountAdded: number, index: number) => void;
  handleAmountAdded?: (amountAdded: any) => void;
  resetInvalid: () => void;
  paymentMethods?;
}

const SelectedPaymentCard = (props: ISelectedPaymentCardProps) => {
  const {
    paymentCard,
    removePayment,
    paymentMethods,
    index,
    handleAmountAdded,
    resetInvalid,
  } = props;
  const [addedCardSplitAmount, setAddedCardSplitAmount] = useState<string>(
    paymentCard.amount?.toString(),
  );

  const handleCardSplit = (event) => {
    resetInvalid();
    let value = event?.target?.value;
    value = value?.replace('$', '');
    if (isZeroOnStart(value)) return;
    if (value !== '' && value < 1) return;
    if (
      value === '' ||
      value === '.' ||
      CARD_AMOUNT_REG_EXPRESSION.test(value)
    ) {
      setAddedCardSplitAmount(value);
      // handleCardSplitAmount(value, index)
      handleAmountAdded({
        amount: Number(value),
        index: index,
        type: PAYMENT_CARD,
      });
    }
  };
  useEffect(() => {
    setAddedCardSplitAmount(paymentCard.amount?.toString());
  }, [paymentCard]);
  return (
    <div className={styleClasses.splitList}>
      <div className="d-flex  justify-content-between align-items-center">
        <h5 className="font-Visby-cf f-w8 f-s16 ">Credit Card</h5>
      </div>
      <div className="d-flex  justify-content-between align-items-center">
        <div>
          <span className="d-block font-Visby-cf f-w5 f-s14 ">
            {' '}
            {paymentCard.name}
          </span>
          <span className="d-block font-Visby-cf f-w5 f-s14 ">
            {' '}
            {paymentCard.number}
          </span>
          <span className="d-block font-Visby-cf f-w5 f-s14 ">
            {paymentCard.valid}
          </span>
        </div>
        <form className={`new_form_design`}>
          <div className="d-flex align-items-center gap-3 gap-md-5">
            <div className={`form-group mb-0 ${styleClasses.split_input}`}>
              <input
                name="amount"
                type="text"
                className="form-control no-style f-s22 f-sm-s18 f-w8 text-center split-input"
                value={'$' + addedCardSplitAmount}
                placeholder={'Enter Amount'}
                onChange={(e) => handleCardSplit(e)}
              />
            </div>
            <span
              className={styleClasses.trashIcon}
              onClick={() => removePayment(paymentCard.id)}
            >
              <TrashIcon />
            </span>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SelectedPaymentCard;
