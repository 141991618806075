import React, { MutableRefObject, useEffect, useRef } from 'react';
import { Container } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import * as Constants from 'src/constants';
import { filterArraysBasedOnGroupId } from 'src/helper/customizedItem/customizedItem.helper';
import { roundOffLimit } from 'src/helper/helperMethods';
import { itemBuckets } from 'src/priceCalculation/buckets';
import { ADDITIONAL_ADDED } from 'src/priceCalculation/constants';

import NoDressing from '../NoDressing';

import ItemModifierRequiredGroups from './ModifierGroups/ItemModifierRequiredGroups';
import EventBusForBYOCategoryScrollingBetweenAccordions from 'src/utils/EventBusForBYOCategoryScrollingBetweenAccordions';
import { IItemsRequiredModifierGroups } from 'src/models/item.model';

const RequiredModifier: React.FC<any> = (props): JSX.Element => {
  const {
    requiredModifierJSXWithByDefaultAccordionControl,
    requiredModifierJSXWithCustomAccordionControl,
  }: {
    requiredModifierJSXWithByDefaultAccordionControl: JSX.Element[];
    requiredModifierJSXWithCustomAccordionControl: JSX.Element;
  } = useGetRequiredModifierJSX(props);
  return (
    <>
      {props.isCreateYourOwnItem
        ? requiredModifierJSXWithCustomAccordionControl
        : requiredModifierJSXWithByDefaultAccordionControl}
    </>
  );
};

const useGetRequiredModifierJSX = (
  props: any,
): {
  requiredModifierJSXWithByDefaultAccordionControl: JSX.Element[];
  requiredModifierJSXWithCustomAccordionControl: JSX.Element;
} => {
  let {
    itemIngredients,
    sectionSelected,
    handleIngredientSelection,
    noDressingHandler,
    showMaxSelection,
    customizationModule,
    showAdditionalPrice,
    itemModifierItemModalWidth,
    CYOIScrollToMissingRequiredArea,
    isCreateYourOwnItem,
    missingRequiredRef,
    scrollToNextGroupOnExtendableSelection,
    Item,
    isCYOIColSize,
  } = props;
  missingRequiredRef =
    missingRequiredRef ??
    useRef({
      valForAccordian: new Set(),
      valForSearch: {},
      currentMissingIndex: null,
      scroll: null,
    });

  const [openAccordions, setOpenAccordions] = React.useState<string[]>([
    ...missingRequiredRef.current.valForAccordian,
  ]);

  useEffect(() => {
    if (isCreateYourOwnItem && missingRequiredRef.current.scroll) {
      let scrollTimeoutId;
      clearTimeout(scrollTimeoutId);
      scrollTimeoutId = setTimeout(() => {
        missingRequiredRef.current.scroll();
        missingRequiredRef.current.scroll = null;
      }, 200);
    }
    EventBusForBYOCategoryScrollingBetweenAccordions.updateState(
      openAccordions,
    );
  }, [openAccordions]);

  const handleRefChange = (updatedAccorionState: string[]): void => {
    if (
      missingRequiredRef.current.currentMissingIndex !== null &&
      updatedAccorionState.findIndex(
        (value: string) =>
          value === missingRequiredRef.current.currentMissingIndex,
      ) < 0
    ) {
      handleToggle(
        undefined,
        missingRequiredRef.current.currentMissingIndex,
        'modifier',
        updatedAccorionState,
      );
      missingRequiredRef.current.currentMissingIndex = null;
    } else if (missingRequiredRef.current.currentMissingIndex !== null) {
      // setRefUpdateTrigger(prev => prev + 1);
      if (missingRequiredRef.current.scroll) {
        let scrollTimeoutId;
        clearTimeout(scrollTimeoutId);
        scrollTimeoutId = setTimeout(() => {
          missingRequiredRef.current.scroll();
          missingRequiredRef.current.scroll = null;
        }, 200);
      }
      missingRequiredRef.current.currentMissingIndex = null;
    }
  };

  const handleUpdateMissingRequiredRefCurrentMissingIndex = (
    missingIndex: number,
  ) => {
    missingRequiredRef.current.currentMissingIndex = `${missingIndex}`;
  };

  useEffect(() => {
    if (missingRequiredRef.current.currentMissingIndex !== null) {
      handleRefChange(openAccordions);
    }
    EventBusForBYOCategoryScrollingBetweenAccordions.subscribe(
      'refUpdatedForBuildYourOwnRequiredScrollOnAddOnsAccorionClick',
      handleRefChange,
    );
    EventBusForBYOCategoryScrollingBetweenAccordions.subscribe(
      'updateMissingRequiredRefCurrentMissingIndex',
      handleUpdateMissingRequiredRefCurrentMissingIndex,
    );
    return () => {
      EventBusForBYOCategoryScrollingBetweenAccordions.unsubscribe(
        'refUpdatedForBuildYourOwnRequiredScrollOnAddOnsAccorionClick',
        handleRefChange,
      );
      EventBusForBYOCategoryScrollingBetweenAccordions.unsubscribe(
        'updateMissingRequiredRefCurrentMissingIndex',
        handleUpdateMissingRequiredRefCurrentMissingIndex,
      );
    };
  }, [missingRequiredRef.current.currentMissingIndex]);

  const handleToggle = (
    groupId: number | undefined,
    id: string,
    clickedOn: 'accordion' | 'modifier',
    updatedAccorionState: string[] = openAccordions,
  ): void => {
    const indexOfCurrentId: number = updatedAccorionState.indexOf(id);
    const newData: string[] = [...updatedAccorionState];
    let openCurrentAccorion: any = null;
    if (groupId && indexOfCurrentId == -1) {
      openCurrentAccorion = scrollToNextGroupOnExtendableSelection(
        undefined,
        { name: clickedOn, groupId },
        updatedAccorionState,
      );
    }
    if (
      (!groupId && indexOfCurrentId == -1) ||
      (indexOfCurrentId == -1 && openCurrentAccorion?.status == false)
    ) {
      newData.push(id);
      setOpenAccordions(newData);
    }
    if (indexOfCurrentId != -1) {
      newData.splice(indexOfCurrentId, 1);
      setOpenAccordions(newData);
    }
  };

  const selectedIngredients = (function () {
    return itemBuckets.specificItemBucketSelectedModifiers(
      Constants.REQUIRED_MODIFIERS,
      sectionSelected,
    );
  })();

  const requiredModifierJSXWithByDefaultAccordionControl: JSX.Element[] =
    itemIngredients &&
    itemIngredients.map((ingredients, index): JSX.Element => {
      const noDressing: boolean = getNoDressing(ingredients?.id);
      const labelPrices: number = AdditionalPriceCalculation(ingredients);
      const label: string = ingredients?.label?.toLowerCase();
      return (
        <Container fluid key={ingredients?.id} className="modifiersContainer">
          <Accordion
            defaultActiveKey={[...missingRequiredRef.current.valForAccordian]}
            className={
              customizationModule
                ? 'item-customization-acc theme-custom-accordions'
                : 'modifiers-accordions-wrapper'
            }
            alwaysOpen
          >
            <Accordion.Item eventKey={`${index}`}>
              {accordionHeader({ index, label, ingredients })}
              {accordionBody({ ingredients, index, labelPrices, noDressing })}
            </Accordion.Item>
          </Accordion>
        </Container>
      );
    });

  const requiredModifierJSXWithCustomAccordionControl: JSX.Element =
    itemIngredients && (
      <Accordion
        className={
          customizationModule
            ? 'item-customization-acc theme-custom-accordions'
            : 'modifiers-accordions-wrapper'
        }
        activeKey={openAccordions}
      >
        {itemIngredients.map((ingredients, index): JSX.Element => {
          const noDressing: boolean = getNoDressing(ingredients?.id);
          const labelPrices: number = AdditionalPriceCalculation(ingredients);
          const label: string = ingredients?.label?.toLowerCase();
          return (
            <Container
              fluid
              key={ingredients?.id}
              className="modifiersContainer"
            >
              <Accordion.Item eventKey={`${index}`} key={index}>
                <span
                  onClick={() =>
                    handleToggle(ingredients?.id, index.toString(), 'accordion')
                  }
                >
                  {accordionHeader({ index, label, ingredients })}
                </span>
                {accordionBody({ ingredients, index, labelPrices, noDressing })}
              </Accordion.Item>
            </Container>
          );
        })}
      </Accordion>
    );

  function accordionHeader({
    index,
    label,
    ingredients,
  }: {
    index: number;
    label: string;
    ingredients: IItemsRequiredModifierGroups;
  }): JSX.Element {
    return (
      <Accordion.Header>
        <span
          className="d-block text-capitalize f-sm-s18"
          ref={
            isCreateYourOwnItem && CYOIScrollToMissingRequiredArea
              ? CYOIScrollToMissingRequiredArea
                  .refToScrollToMissingRequiredArea[index]
              : null
          }
        >
          {label}
          <span className="f-s13 f-w6 font-rale clr-dark-grey d-block lh-normal">
            {showMaxSelection &&
              !isCreateYourOwnItem &&
              `Select up to ${roundOffLimit(ingredients?.max)}`}
            {!showMaxSelection && !isCreateYourOwnItem && (
              <span className="caption fs-14">Required</span>
            )}
            {isCreateYourOwnItem && ingredients.max > 0 && (
              <span className="caption fs-14">{ingredients.max} Included</span>
            )}
          </span>
        </span>
      </Accordion.Header>
    );
  }

  function accordionBody({
    ingredients,
    index,
    labelPrices,
    noDressing,
  }: {
    ingredients: IItemsRequiredModifierGroups;
    index: number;
    labelPrices: number;
    noDressing: boolean;
  }): JSX.Element {
    return (
      <Accordion.Body>
        <div className={customizationModule ? 'single-item-modifiers' : 'row'}>
          <ItemModifierRequiredGroups
            ingredients={ingredients}
            selectedIngredients={selectedIngredients}
            handleClick={(data, in_item) => handleClick(data, in_item, index)}
            isLimitExceed={(e) => isLimitExceed(e, ingredients.id)}
            customizedItemSize={customizationModule && true}
            itemBase={ingredients?.base / ingredients?.max}
            remainingBase={labelPrices}
            showAdditionalPrice={showAdditionalPrice}
            itemModifierItemModalWidth={itemModifierItemModalWidth}
            showRedBorderAnimation={showRedBorderAnimation(index)}
            isCreateYourOwnItem={isCreateYourOwnItem}
            Item={Item}
            isCYOIColSize={isCYOIColSize}
          />
          {ingredients.min === 0 && (
            <div
              className={`${
                customizationModule
                  ? 'item__modifier_item'
                  : 'py-1 py-md-3 col-md-3 col-4 px-2 col-lg-2'
              } ${itemModifierItemModalWidth}
                            ${isCYOIColSize}`}
            >
              <NoDressing
                label={ingredients?.label}
                groupId={ingredients?.id}
                noDressingHandler={noDressingHandler}
                isNoDressing={noDressing}
                itemNo={sectionSelected}
                showRedBorderAnimation={showRedBorderAnimation(index)}
              />
            </div>
          )}
        </div>
      </Accordion.Body>
    );
  }

  function getNoDressing(groupId) {
    const requiredModifiersBucket = itemBuckets.getSingleBucket({
      name: Constants.REQUIRED_MODIFIERS,
      fromItem: parseInt(sectionSelected),
    });
    if (requiredModifiersBucket?.noDressings) {
      const isNoDressing = requiredModifiersBucket?.noDressings?.find(
        (e) => e.id === groupId,
      );
      if (isNoDressing) {
        return isNoDressing.noDressing;
      }
      return false;
    } else {
      return false;
    }
  }

  function AdditionalPriceCalculation(ingredients): number {
    let remainingBase = ingredients?.base || 0;
    let base = ingredients?.base || 0;
    let totalCalculatedPrice = 0;
    if (selectedIngredients.length) {
      selectedIngredients.forEach((element) => {
        if (element.modifier_group_id === ingredients?.id) {
          totalCalculatedPrice =
            totalCalculatedPrice + element?.quantity * element?.display_price;
        }
      });
      if (totalCalculatedPrice > base) {
        remainingBase = 0;
      } else {
        remainingBase = remainingBase - totalCalculatedPrice;
      }
    }
    return remainingBase.toFixed(2);
  }

  function showRedBorderAnimation(index) {
    const missingRequired =
      isCreateYourOwnItem && CYOIScrollToMissingRequiredArea
        ? CYOIScrollToMissingRequiredArea?.isCYOIRequiredModifierSelected
            .status &&
          CYOIScrollToMissingRequiredArea?.isCYOIRequiredModifierSelected
            .highLightArea === index
        : false;
    if (missingRequired && !missingRequiredRef.current.valForSearch[index]) {
      missingRequiredRef.current.valForAccordian.add(`${index}`);
      missingRequiredRef.current.valForSearch[index] = true;
    }
    return missingRequired;
  }

  function handleClick(data, in_item, index) {
    const modifier = {
      ...data,
      ...{
        modifier_group_id: itemIngredients[index]?.id,
        modifier_group_max: itemIngredients[index]?.max,
        modifier_group_base: itemIngredients[index]?.base,
        brink_modifier_group_id:
          itemIngredients[index]?.brink_modifier_group_id,
        in_item,
        in_slide: true,
        modifier_type: Constants.REQUIRED_MODIFIERS,
      },
    };
    handleIngredientSelection(modifier);
  }

  function isLimitExceed(extendable_limit: number, groupId: number): boolean {
    if (extendable_limit === 1) {
      return false;
    }
    const isNoDressingExist = selectedAddedIngredients.find(
      (mod: any) => mod.modifier_name === Constants.NO_DRESSING_CAPITALIZE,
    );
    if (isNoDressingExist) {
      extendable_limit += 1;
    }
    const modifierGroupsArray = filterArraysBasedOnGroupId(
      [selectedAddedIngredients],
      groupId,
    );
    if (modifierGroupsArray.length >= extendable_limit) {
      return true;
    } else {
      return false;
    }
  }

  const selectedAddedIngredients = (function () {
    const fromItem: any = parseInt(sectionSelected) - 1;
    return itemBuckets.getSingleBucketKeyValue({
      name: Constants.REQUIRED_MODIFIERS,
      fromItem,
      modifierType: ADDITIONAL_ADDED,
      key: 'modifiers',
    });
  })();

  return {
    requiredModifierJSXWithByDefaultAccordionControl,
    requiredModifierJSXWithCustomAccordionControl,
  };
};

export default RequiredModifier;
