import React from 'react';
import { useHistory } from 'react-router';
import styles from './LinkExpired.module.scss';

export default function LinkExpired() {
  const history = useHistory();
  const data: any = history.location.state;
  return (
    <div className={`text-center mb-4 ${styles.linkExpired}`}>
      <p className="f-s40 lh-normal mt-4 font-Cls mb-0 f-sm-s26">
        {data.title}
      </p>
      <span>{data.message}</span>
    </div>
  );
}
