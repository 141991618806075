import { Link } from 'react-router-dom';
import { CateringUser, IndividualUser } from 'src/assets/images/Svgs/svg';

import styles from './NavbarComponent.module.scss';

export default function GuestNavComponent({ expandHandler }) {
  return (
    <>
      <div className={`${styles.selectAccountWrapper} pt-4 px-4`}>
        <p className="text-start font-Cls f-s18 clr-dark-gray">
          Please Select Account Type
        </p>
        <hr className="my-3" />
        <Link to="/login">
          <h5
            className="d-flex align-items-center text-start text-uppercase f-s18 clr-dark-gray ls-1 font-Visby-cf f-w8"
            onClick={expandHandler}
          >
            <CateringUser className="me-2" />
            Catering ordering
          </h5>
        </Link>
        <hr className="my-3" />
        <a href="https://cafezupas.com/" target="_blank">
          <h5 className="d-flex align-items-center text-start text-uppercase f-s18 clr-dark-gray ls-1 font-Visby-cf f-w8">
            <IndividualUser className="me-2" />
            individual ordering
          </h5>
        </a>
        <hr className="my-3" />
        <div className="text-center">
          <p className="font-Cls f-s20 text-center my-4 f-w3">
            Join today to start receiving exclusive Café Zupas rewards, updates
            and seasonal menus.
          </p>
          <Link
            to="/createAccount"
            className="btn-large mb-3 f-s14 lh-normal"
            onClick={expandHandler}
          >
            CREATE MY ACCOUNT
          </Link>
          <p className={`${styles.login_btn_wrapper} f-s14 pb-3 mb-5`}>
            Already have an account?
            <Link
              to="/login"
              className={`${styles.login_btn} font-Vcf`}
              onClick={expandHandler}
            >
              Sign In
            </Link>
          </p>
        </div>
      </div>
    </>
  );
}
