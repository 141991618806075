import { s3BaseUrl } from '../../constants';
import ShelterMap from './Maps';

const MapsRender = ({ styleClasses, lat_longs, handleCurrentLocation }) => {
  return (
    <div className={styleClasses.map_container_wrap + ' w-100'}>
      <ShelterMap lat_longs={lat_longs}></ShelterMap>
      <div className={styleClasses.map_buttons_wrap + ' d-flex flex-column'}>
        <button
          type="button"
          className={styleClasses.current_location_btn + ' btn btn-link'}
          onClick={(e) => handleCurrentLocation()}
        >
          <img
            src={`${s3BaseUrl}/images/icons/Rectangular-pin.svg`}
            className="img-fluid"
            alt="icon"
          />{' '}
          Current Location
        </button>
      </div>
    </div>
  );
};

export default MapsRender;
