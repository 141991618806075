import { useQuery } from 'react-query';

import { QUERY_KEY_CZ_DOLLARS_BALANCE } from 'src/constants';
import { customerBusinessService } from '../../services';

const getCZDollarsBalance = async () => {
  const response = await customerBusinessService.getCZDollarsBalance();
  return response.data.data;
};

export default function useGetCZDollarsBalance(isUser) {
  return useQuery<any>(
    [QUERY_KEY_CZ_DOLLARS_BALANCE],
    () => getCZDollarsBalance(),
    {
      enabled: isUser,
    },
  );
}
