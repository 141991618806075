import { useQuery } from 'react-query';

import { AUTH_USER, GET_ORDER_DETAILS_REFETCH_INTERVAL, GUEST_USER } from '../constants';
import { StoredPaymentMethod } from '../models/payment.model';
import { paymentService } from '../services';
import { bugSnagNotifyAPIError } from 'src/helper/bugSnagHelper';

const getPaymentMethods = async (customer_id: string) => {
  const response = await paymentService.getCustomerPaymentMethods(customer_id);
  return response?.data?.data?.data;
};

export default function usePaymentMethods(
  customer_id: string,
  userType: string = AUTH_USER,
  disable: boolean = false,
) {
  return useQuery<StoredPaymentMethod[]>(
    ['paymentMethod', customer_id],
    () => getPaymentMethods(customer_id),
    {
      enabled: disable ? !disable && !!customer_id && userType !== GUEST_USER : !!customer_id && userType !== GUEST_USER,
      onError: (error: any) => {
        bugSnagNotifyAPIError("Get Payments", { 'user': customer_id }, error?.response?.data?.message)
      }
    },
  );
}
