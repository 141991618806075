import { useQuery } from 'react-query';

import { SEASONAL } from '../constants';
import { locationMenuService } from '../services';

const getMenuCategories = async () => {
  const response = await locationMenuService.getMenuCategories();
  const menuData = response.data.data;
  const seasonalIndex = menuData.findIndex(
    (item) => item.name.toLowerCase() === SEASONAL.toLowerCase(),
  );
  if (seasonalIndex >= 0) menuData.splice(seasonalIndex, 1);
  return menuData;
};

export default function useGetMenuCategories() {
  return useQuery<any>('menu-categories', () => getMenuCategories(), {
    staleTime: 1000 * 60 * 60,
  });
}
