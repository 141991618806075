import React from 'react';

import { quantityCounterOperations as operations } from '../../../models/cart.model';

import { MAX_BOX_LUNCH_QUANTITY } from 'src/constants';
import styles from './CartItems.module.scss';

interface IQuantityCounter {
  quantity?: number;
  editQuantity?: (operation: operations) => void;
  disabled?: boolean;
  itemDetailCounterStyle?: string;
}

const QuantityCounter: React.FC<IQuantityCounter> = (props) => {
  const {
    quantity,
    editQuantity,
    disabled = false,
    itemDetailCounterStyle,
  } = props;

  const increaseQuantity = () => editQuantity(operations.increase);
  const decreaseQuantity = () => editQuantity(operations.decrease);

  return (
    <div className="text-center me-3">
      <span className="clr-text-grey f-s14 ls-normal lh-normal font-Visby-cf f-w5">
        Quantity
      </span>
      <span
        className={`${styles.card_increments} d-flex align-items-center ${itemDetailCounterStyle}`}
      >
        <button
          type="button"
          className=" f-s18 f-w4 font-rale lh-normal"
          onClick={decreaseQuantity}
          disabled={quantity === 1 || disabled}
        >
          -
        </button>
        <span className="d-inline-block px-4 f-s16">{quantity}</span>
        <button
          type="button"
          className=" f-s18 f-w4 font-rale lh-normal"
          onClick={increaseQuantity}
          disabled={disabled || quantity > MAX_BOX_LUNCH_QUANTITY - 1}
        >
          +
        </button>
      </span>
    </div>
  );
};

export default QuantityCounter;
