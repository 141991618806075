import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { useLocation } from 'react-router';
import { ROUTE_ADD_PAYMENT } from 'src/constants';
import { unauthorizeLogout } from 'src/Features/AccountSetup/redux/actions';
import { bugSnagNotifyAPIError } from 'src/helper/bugSnagHelper';
import {
  getAddPaymentUser,
  getRequestId,
  getUser,
} from 'src/helper/helperMethods';
import store, { useAppSelector } from 'src/redux/store/store';

const { dispatch } = store;

export const requestHandler = (request: AxiosRequestConfig) => {
  let authInfo = getUser();
  let addPaymentUser = getAddPaymentUser();

  let requestId = getRequestId();

  if (addPaymentUser?.authentication_token) {
    authInfo = {
      authentication_token: addPaymentUser.authentication_token,
      token: addPaymentUser.authentication_token,
    };
  }
  if (authInfo.token) {
    request.headers['Authorization'] = request.headers['Authorization'] || 'Bearer ' + authInfo.token;
    request.headers['token'] = request.headers['token'] || authInfo.authentication_token;
    request.headers['request-id'] = request.headers['request-id'] || requestId;
    request.headers['Refresh-token'] = request.headers['Refresh-token'] || "";
  }
  return request;
};

export const successResponseHandler = (response: AxiosResponse) => {
  return response;
};

export const errorResponseHandler = (error: AxiosError) => {
  const authInfo = getUser();
  if (error?.response?.status === 401 && authInfo.token) {
    unauthorizeLogout(dispatch);
    bugSnagNotifyAPIError("Authentication Error", error?.response, `Request failed with status code 401`);
  }
  // Handles Server Errors for Bugsnag reporting
  if (error?.response?.status >= 500 && error?.response?.status < 600) {
    bugSnagNotifyAPIError("Server Error occurred", error?.response, `Server Error with status code ${error?.response?.status}`);
  }
  // Handles Network Errors for Bugsnag reporting
  if (!error?.response) {
    bugSnagNotifyAPIError("Network error occurred", { "Error": "Network failed" }, `Network Error - ${error?.message}`);
  }
  return Promise.reject(error);
};
