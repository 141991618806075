import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { ArrowRightIcon, NotesIcon } from 'src/assets/images/Svgs/svg';
import { useAppSelector } from 'src/redux/store/store';
import { awsAssetsFetcher, formatImageName } from 'src/utils/awsAssetsFetcher';
import {
    GUEST_USER,
    ROUTE_MENU,
    ROUTE_PROFILE_DETAILS,
    VIEW_MORE_RECENT_ORDER,
} from '../../constants';

import useGetMenuCategories from '../../react-query-hooks/useGetCategories';
import styles from './MegaMenuDropDown.module.scss';

interface MegaMenuProps {
    HandleHideMegaMenu: () => void;
    megaMenuAnimateClass: string;
}
const MegaMenuDropDown: React.FC<MegaMenuProps> = ({
    HandleHideMegaMenu,
    megaMenuAnimateClass,
}) => {
    const { type } = useAppSelector((state) => state.user.user);
    const isGuestUser = type !== GUEST_USER;
    const { data: menus = [] } = useGetMenuCategories();
    const history = useHistory();


    const handleMenuRoute = (item) => {
        history.push(`${ROUTE_MENU}/${item.name.replace(/\s/g, '%20')}`);
        HandleHideMegaMenu();
    };
    return (
        <>
            <div className={`${styles.megaMenuWrapper}  ${megaMenuAnimateClass}`}>
                <div className={styles.innerDiv}>
                    <Row>
                        <Col sm={6} md={isGuestUser ? 9 : 12} className="px-4">
                            <Row>
                                {menus.map((menu) => {
                                    return (
                                        <Col md={4} key={menu.id}>
                                            <div
                                                className={styles.menuCategory}
                                                onClick={() => {
                                                    handleMenuRoute(menu);
                                                }}
                                            >
                                                <img
                                                    src={awsAssetsFetcher(
                                                        `categories/${formatImageName(menu.name)}`,
                                                        'png',
                                                    )}
                                                    className="img-fluid"
                                                    alt={menu.name}
                                                />
                                                <div className="w-100 ms-2">
                                                    {' '}
                                                    <h4> {menu.name}</h4>
                                                </div>

                                                <div className={styles.iconDiv}>
                                                    <ArrowRightIcon />
                                                </div>
                                            </div>
                                        </Col>
                                    );
                                })}
                            </Row>
                        </Col>
                        {isGuestUser && (
                            <Col md={3} className="border-start px-4">
                                <div
                                    className={styles.menuCategory}
                                    onClick={(event: any) => {
                                        event.preventDefault();
                                        history.push(VIEW_MORE_RECENT_ORDER);
                                        HandleHideMegaMenu();
                                    }}
                                >
                                    <div className={styles.notesIcon}>
                                        {' '}
                                        <NotesIcon />
                                    </div>

                                    <div className="w-100 ms-2">
                                        {' '}
                                        <h4>Recent Orders</h4>
                                    </div>

                                    <div className={styles.iconDiv}>
                                        <ArrowRightIcon />
                                    </div>
                                </div>
                            </Col>
                        )}

                        <div
                            className={`d-flex justify-content-center mt-3 w-100 ${styles.btnDiv}`}
                        >
                            <button
                                className=" btn-large"
                                onClick={() => {
                                    history.push(ROUTE_MENU);
                                    HandleHideMegaMenu();
                                }}
                            >
                                View full menu{' '}
                            </button>
                        </div>
                    </Row>
                </div>
            </div>
        </>
    );
};

export default MegaMenuDropDown;
