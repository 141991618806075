import { useMutation } from 'react-query';

import { paymentService } from '../services';
import { bugSnagNotifyAPIError } from 'src/helper/bugSnagHelper';

const addPaymentMethod = async (newPaymentMethod: any) => {
  const response = await paymentService.addPaymentMethod(newPaymentMethod);
  return response.data.data;
};

export default function useAddPaymentMethod() {
  return useMutation((data: { newPaymentMethod: any }) =>
    addPaymentMethod(data.newPaymentMethod),
    {
      onError: (error: any) => {
        bugSnagNotifyAPIError("Add Payments", error?.response?.data, error?.response?.data.message)
      }
    }
  );
}
