import { useEffect, useState } from 'react';
import { dmasNumber, roundNumber } from 'src/helper/helperMethods';
import {
  DELIVERY_ORDER_TYPE,
  DELIVERY_TIP_REG_EXPRESSION,
  UPPERCASE_DVISION,
  UPPERCASE_MULTI,
} from '../../../constants';
import styleClasses from '../order.module.scss';

interface IItemDeliveryTippingProps {
  tippingTotal?: number;
  updatedOrderTotal?: (number) => void;
  orderTippingAmount?: number;
  setOrderTippingAmount?: (number) => void;
  setDeliveryTipType?: (any) => void;
  orderType: string;
  tipType?: string;
  tipAmount?: number;
  isEditOrder?: boolean;
  disabled?: boolean;
}
export const DeliveryTipping = (props: IItemDeliveryTippingProps) => {
  const {
    tippingTotal,
    updatedOrderTotal,
    orderTippingAmount,
    setOrderTippingAmount,
    setDeliveryTipType,
    orderType,
    tipAmount = 0,
    tipType = '%',
    isEditOrder = false,
    disabled = false,
  } = props;
  const [customTippingAmount, setCustomTippingAmount] = useState<string>('');
  const [selectedTip, setSelectedTip] = useState<number>(tipAmount);
  const [showDollar, setShowDollar] = useState(false);
  const [showTipMessage, setShowTipMessage] = useState(false);
  const updateSelectedTip = (tip: number) => {
    setSelectedTip(tip);
  };
  const tipSyncHelper = (amount) => {
    setDeliveryTipType({ type: '$', tip: Number(amount) });
    setSelectedTip(-1);
    setShowDollar(true);
    setShowTipMessage(true);
    setCustomTippingAmount(String(amount));
    setOrderTippingAmount(amount);
  };

  useEffect(() => {
    calculateTippingOfTotalAmount(tipAmount);
    if (tipType === '$' && isEditOrder) {
      if (tipAmount > tippingTotal) {
        tipSyncHelper(0);
      } else {
        tipSyncHelper(tipAmount);
      }
    } else if (tipType === '%' && isEditOrder) {
      setShowTipMessage(false);
    }
  }, [tippingTotal]);

  const calculateTippingOfTotalAmount = (value: number) => {
    setDeliveryTipType({ type: '%', tip: value });
    updateSelectedTip(value);
    if (customTippingAmount != '') {
      setCustomTippingAmount('');
    }
    if (tippingTotal > 0) {
      const tippingAmount = dmasNumber(
        tippingTotal,
        dmasNumber(value, 100, UPPERCASE_DVISION),
        UPPERCASE_MULTI,
      );
      setOrderTippingAmount(roundNumber(tippingAmount, 2));
      // updateOrderTotalAfterAddingTippingAmount(tippingAmount);
    }
  };

  const handleChange = (event) => {
    if (event?.target?.value > tippingTotal) return;
    setDeliveryTipType({ type: '$', tip: Number(event.target.value) });
    setSelectedTip(-1);
    setShowDollar(true);
    setShowTipMessage(true);
    if (
      event.target.value === '' ||
      event.target.value === '.' ||
      DELIVERY_TIP_REG_EXPRESSION.test(event.target.value)
    ) {
      setOrderTippingAmount(0);
      let tippingAmount = 0;
      if (tippingTotal > 0) {
        tippingAmount = Number(event.target.value) || 0;
        setOrderTippingAmount(tippingAmount);
        setCustomTippingAmount(event.target.value);
        // updateOrderTotalAfterAddingTippingAmount(tippingAmount);
      }
    }
  };

  const updateOrderTotalAfterAddingTippingAmount = (tippingAmount: number) => {
    let updatedTotalAmount = 0;
    if (tippingTotal > 0) {
      updatedTotalAmount = tippingTotal + Number(tippingAmount.toFixed(2));
      updatedOrderTotal(updatedTotalAmount);
    }
  };

  return (
    <div className={styleClasses.tipping_wrapper}>
      <div className="shadow-divider d-md-none mt-4" />
      <h4 className="f-s20 text-start h-22 text-uppercase font-Cls fw-normal f-sm-s16 lh-normal">
        {orderType === DELIVERY_ORDER_TYPE ? 'Delivery driver tip' : 'Tip'}
      </h4>
      <div className="d-flex  gap-3 mt-3">
        <div className={`${styleClasses.tip_custom_input} d-flex gap-3`}>
          <input
            type="text"
            id="tip_1"
            className={`${selectedTip === 0 && styleClasses.selected_tip}`}
            onClick={() => {
              calculateTippingOfTotalAmount(0);
            }}
            disabled={disabled}
            readOnly
            placeholder="0"
            value="0"
          />
          <input
            type="text"
            id="tip_2"
            className={`${selectedTip === 12 && styleClasses.selected_tip}`}
            onClick={() => {
              calculateTippingOfTotalAmount(12);
            }}
            disabled={disabled}
            readOnly
            placeholder="12%"
            value="12%"
          />
          <input
            type="text"
            id="tip_3"
            className={`${selectedTip === 15 && styleClasses.selected_tip}`}
            onClick={() => {
              calculateTippingOfTotalAmount(15);
            }}
            disabled={disabled}
            readOnly
            placeholder="15%"
            value="15%"
          />
          <input
            type="text"
            id="tip_4"
            className={`${selectedTip === 18 && styleClasses.selected_tip}`}
            onClick={() => {
              calculateTippingOfTotalAmount(18);
            }}
            disabled={disabled}
            readOnly
            placeholder="18%"
            value="18%"
          />
          <div className="position-relative">
            {showDollar && (
              <span className={`${styleClasses.dollar_sign} f-s16`}>$</span>
            )}
            <input
              type="text"
              placeholder="Custom Amount"
              value={customTippingAmount}
              className={`${selectedTip === -1 && styleClasses.selected_tip}`}
              onClick={handleChange}
              onChange={handleChange}
              id="tip_5"
              disabled={disabled}
              onBlur={() => {
                if (!customTippingAmount) setShowDollar(false);
                setShowTipMessage(false);
              }}
            />
          </div>
        </div>
      </div>

      {showTipMessage && (
        <p className="f-s14 h-25 f-w5 font-Visby-cf my-3 clr-dark-red text-start">
          Tip will always be smaller than Sub-total of the order.
        </p>
      )}

      <div className="f-s16 h-25 f-w5 font-Visby-cf my-3 text-start">
        Your Tip <span className="f-w6">${orderTippingAmount.toFixed(2)}</span>
      </div>
      <hr className="custom_hr_sty d-md-block d-none my-4" />
    </div>
  );
};
