import { FC } from 'react';
import React from 'react';

import Footer from './Footer/Footer';
import Header from './Header/Header';
import styles from './layout.module.scss';
import { LayoutProps } from './types';

const Layout: FC<LayoutProps> = ({ children }) => {
  return (
    <>
      {/* <Header /> */}
      <main className={styles.main}>{children}</main>
      {/* <Footer /> */}
    </>
  );
};

export default Layout;
