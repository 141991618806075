import React from 'react';

interface FormFieldProps {
  id?: string;
  name: string;
  onChange: ((e: React.ChangeEvent<any>) => void) & ((values: any) => void);
  checked: boolean;
  labelText?: string;
  value?: any;
}

const InputCheckbox: React.FC<FormFieldProps> = ({
  id,
  name,
  onChange,
  checked,
  labelText,
}) => {
  return (
    <>
      <div className="form-group theme_check_boxes">
        <input
          name="marketing_email_subscription"
          type="checkbox"
          id={id}
          onChange={onChange}
          checked={checked}
        />
        <label htmlFor={id} className="d-flex align-items-start">
          <span className="box_wrap"></span> {labelText || ''}
        </label>
      </div>
    </>
  );
};

export default InputCheckbox;
