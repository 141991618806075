import React from 'react';
import { withRouter } from 'react-router-dom';
import {
  captureException as SentryCaptureException,
  withScope as SentryWithScope,
} from '@sentry/react';
import { ROUTE_404, ROUTE_MENU } from 'src/constants';

class ErrorBoundary extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null, eventId: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    SentryWithScope((scope) => {
      scope.setExtras(errorInfo);
      const eventId = SentryCaptureException(error);
      this.setState({ eventId });
    });
    // this.props.history.push(ROUTE_404, { eventId: this.state.eventId });

    // Doing hard refresh as we don't need 404 as per RAZA
    window.location.reload();

    // Redirect to another URL
    window.location.href = ROUTE_MENU;
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
