import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import _debounce from 'lodash/debounce';
import CustomModal from 'src/components/customModal/CustomModal';
import Loader from 'src/components/Loader/Loader';
import { appliedPaymentMethods } from 'src/helper/checkout/PaymentHelper';
import {
  getGiftCards,
  getPayments,
} from 'src/helper/checkout/PlaceOrderHelper';
import {
  getUser,
  isCartItemsUnavailable,
  isCartModifiersUnavailable,
  isVerifyPaymentUserSignedIn,
  persistZero,
  setRequestId,
  roundNumber,
  getAddPaymentUserCart,
  removeTextToPayUserSessionDetail,
  getPaymentErrorMessage,
} from 'src/helper/helperMethods';
import { Toast_Func } from 'src/helper/toast.helper';
import usePaymentMethods from 'src/hooks/usePaymentMethods';
import useOrderDetails from 'src/react-query-hooks/useGetOrderDetails';

import * as CONSTANT from '../../constants';
import {
  DELIVERY_ORDER_TYPE,
  GUEST_USER,
  OFFER_TYPE_FIXED_DISCOUNT,
  PICK_UP_ORDER_TYPE,
  ROUTE_MENU,
} from '../../constants';
import usePlaceOrder from '../../hooks/usePlaceOrder';
import useSyncCart from '../../hooks/useSyncCart';
import useUnload from '../../hooks/useUnload';
import useApplyOfferOnCart from '../../react-query-hooks/Cart/useApplyOfferOnCart';
import { clearCartOfferAuxData } from '../../redux/slices/cartSlice';
import {
  addGiftCardForTextToPayFlow,
  removeAddPaymentUserData,
  resetCheckoutForTextToPayFlow,
  updateAddPaymentUserData,
  updateAmountOnSplitForTextToPayFlow,
  updateDefaultGiftCardForTextToPayFlow,
  updateGiftCardForTextToPayFlow,
  updateIsSplitPaymentForTextToPayFlow,
  updatePaymentMethodForTextToPayFlow,
} from '../../redux/slices/addPayment/addPaymentSlice';
import { useAppDispatch, useAppSelector } from '../../redux/store/store';
import { orderMicroService } from '../../services';
import Billing from '../Cart/Billing/Billing';

import ContactMethod from './ContactMethod/ContactMethod';
import DeliveryLocation from './DeliveryLocation/DeliveryLocation';
import { DeliveryTipping } from './DeliveryTipping/DeliveryTipping';
import OrderDetails from './OrderDetails/OrderDetails';
import OrderDetailsPickupMethod from './OrderDetailsPickupMethod/OrderDetailsPickupMethod';
import PaymentModal from './PaymentMethod/PaymentModal';
import SelectedPayments from './PaymentMethod/SelectedPayments';
import SplitPaymentMethod from './PaymentMethod/SplitPaymentMethod';
import StoreLocation from './StoreLocation/StoreLocation';
import styleClasses from './order.module.scss';

import 'react-datepicker/dist/react-datepicker.css';
import useGetCZDollarsBalance from 'src/react-query-hooks/Loyalty/useGetCZDollarsBalance';
import { brazeLogEventOfferAdded } from 'src/helper/brazeHelper';
import { PickUpMethods } from 'src/helper/tracking';
import useRequestPaymentLink from 'src/react-query-hooks/useRequestPaymentLink';
import SubmittedPaymentMethod from './SubmittedPaymentMethod';

const RequestPayment: React.FunctionComponent = () => {
  const checkoutDispatch = useAppDispatch();
  const dispatch = useDispatch();
  const { id } = useParams<any>();
  const [order, setOrderData] = useState<any>();
  const locationReact = useLocation();

  const [callCZDollar, setCallCZDollar] = useState(false);
  const {
    data: verifyPaymentLinkData,
    isFetching: isFetchingVerifyPaymentLink,
    refetch: refetchVerifyPaymentLinkData
  } = useRequestPaymentLink(id);

  const locationInfo = order?.store_details;
  const orderType = [CONSTANT.IN_STORE].includes(
    PickUpMethods[order?.pickup_details?.id],
  )
    ? CONSTANT.PICK_UP_ORDER_TYPE
    : CONSTANT.DELIVERY_ORDER_TYPE;
  const date = order?.pos_pickup_time?.split(' ');
  const PickupSlotDetailsPickupSlots =
    date?.length > 1
      ? {
          date: date[0],
          time: date[1],
        }
      : {};

  //Fund raiser data
  const { syncCartForAddPayment, loading: syncingCart } = useSyncCart();
  const cart = getAddPaymentUserCart();
  const authInfo = useAppSelector((state) => state.addPayment.addPaymentUser);
  const { data: czDollarsBalance, refetch: refetchCZDollar } =
    useGetCZDollarsBalance(Boolean(callCZDollar));

  const {
    data: paymentRecords = [],
    isFetching: isFetchingPaymentMethod,
    refetch: refetchingPaymentMethods,
  } = usePaymentMethods(
    verifyPaymentLinkData?.customer_id,
    authInfo?.type,
    true,
  );
  const handlePaymentForAddPaymentUser = locationReact.pathname.includes(CONSTANT.ROUTE_REQUEST_PAYMENT);

  useEffect(() => {
    if (callCZDollar) {
      refetchCZDollar();
      setCallCZDollar(false);
    }
  }, [callCZDollar]);
  useEffect(() => {
    if (verifyPaymentLinkData?.authentication_token) {
      dispatch(removeAddPaymentUserData());
      dispatch(resetCheckoutForTextToPayFlow());
      removeTextToPayUserSessionDetail();
      dispatch(updateAddPaymentUserData(verifyPaymentLinkData));
      sessionStorage.setItem(
        CONSTANT.ADD_PAYMENT_USER,
        JSON.stringify(verifyPaymentLinkData),
      );
      if (verifyPaymentLinkData?.payment_confirmed && verifyPaymentLinkData?.is_expired) {
        history.push({
          pathname: CONSTANT.REQUEST_PAYMENT_THANK_YOU,
          state: {
            orderId: verifyPaymentLinkData?.order_id,
            message: verifyPaymentLinkData.message,
            title: verifyPaymentLinkData.title,
            receivePayment: true
          },
        });
        return;
      }
      else if (verifyPaymentLinkData?.is_expired) {
        dispatch(removeAddPaymentUserData());
        dispatch(resetCheckoutForTextToPayFlow());
        removeTextToPayUserSessionDetail();
        history.push({
          pathname: CONSTANT.REQUEST_PAYMENT_LINK_EXPIRED,
          state: {
            message: verifyPaymentLinkData.message,
            title: verifyPaymentLinkData.title,
            receivePayment: true
          },
        });
        return;
      }
      syncCartForAddPayment(verifyPaymentLinkData, true);
      refetchingPaymentMethods();
      setCallCZDollar(true);
      setOrderData(verifyPaymentLinkData?.order);
    }
  }, [verifyPaymentLinkData]);

  useEffect(() => {
    if (czDollarsBalance?.id) {
      checkoutDispatch(updateDefaultGiftCardForTextToPayFlow(czDollarsBalance));
    }
  }, [czDollarsBalance]);

  const { mutate: applyOfferOnCart } = useApplyOfferOnCart();

  const isApplyingOfferOnCart = useRef<boolean>(false);
  const { user_offer_id, root_offer_id, eligible_item_offer_id } = cart;
  const isOfferAppliedOnCartModal = !!user_offer_id && !!root_offer_id;

  const [
    disablePlaceOrderUntilTaxCalculation,
    setDisablePlaceOrderUntilTaxCalculation,
  ] = useState<boolean>(isOfferAppliedOnCartModal);
  useEffect(() => {
    if (isOfferAppliedOnCartModal) {
      const applyOfferOnCartPayload = {
        user_offer_id,
        root_offer_id,
        eligible_item_offer_id,
      };
      isApplyingOfferOnCart.current = true;
      applyOfferOnCart(applyOfferOnCartPayload, {
        onSuccess: async (res: any) => {
          dispatch(clearCartOfferAuxData());
          await syncCartForAddPayment(verifyPaymentLinkData, true);
          isApplyingOfferOnCart.current = false;
          setDisablePlaceOrderUntilTaxCalculation(false);
          brazeLogEventOfferAdded(
            applyOfferOnCartPayload,
            authInfo?.authentication_token,
            cart?.cartId,
          );
        },
        onError: (error: any) => {
          isApplyingOfferOnCart.current = true;
          Toast_Func({
            status: false,
            message:
              error.response.data?.message || CONSTANT.SOMETHING_WENT_WRONG,
          });
          setDisablePlaceOrderUntilTaxCalculation(false);
        },
      });
    }
  }, []);
  const history = useHistory();

  const [showEditPayment, setShowEditPayment] = useState(false);

  const handleShowAddPayment = () => {
    setShowEditPayment(true);
  };

  const handleCloseEditPayment = () => setShowEditPayment(false);
  const [showSplitPayment, setShowSplitPayment] = useState(false);
  const handleShowSplitPayment = () => setShowSplitPayment(true);
  const handleCloseSplitPayment = () => setShowSplitPayment(false);

  const [showGiftCard, setShowGiftCard] = useState(false);
  const [isPlacingOrder, setIsPlacingOrder] = useState<boolean>(false);

  const [showContactForm, setShowContactForm] = useState(false);
  const handleShowContactForm = () => setShowContactForm(true);
  const handleCloseContactForm = () => setShowContactForm(false);

  const [editRecordId, setEditRecordId] = useState(null);

  const [openPickupMethodModal, setOpenPickupMethodModal] = useState(false);
  const handleShowPickupMethodModal = () => setOpenPickupMethodModal(true);
  const handleClosePickupMethodModal = () => setOpenPickupMethodModal(false);
  const [openDeliveryMethodModal, setOpenDeliveryMethodModal] = useState(false);
  const handleShowDeliveryMethodModal = () => setOpenDeliveryMethodModal(true);
  const handleCloseDeliveryMethodModal = () =>
    setOpenDeliveryMethodModal(false);
  const [changToDeliveryModal, setChangToDeliveryModal] = useState(false);
  const [changToPickupModal, setChangToPickupModal] = useState(false);
  const editPickupType = () => setChangToDeliveryModal(true);
  const editDeliveryType = () => setChangToPickupModal(true);
  const [orderTypeValidationMessage, setOrderTypeValidationMessage] =
    useState<string>('');
  const [discountedSubTotal, setDiscountedSubTotal] = useState<number>(0);
  const [orderDeliveryValidationMessage, setOrderDeliveryValidationMessage] =
    useState<string>('');
  const [paymentValidationMessage, setPaymentValidationMessage] =
    useState<string>('');
  const [contactInfoMessage, setContactInfoMessage] = useState<string>('');
  const setOrderTypeInvalidMessage = (message: string) =>
    setOrderTypeValidationMessage(message);
  const setOrderDeliveryInvalidMessage = (message: string) =>
    setOrderDeliveryValidationMessage(message);
  const setPaymentInvalidMessage = (message: string) =>
    setPaymentValidationMessage(message);
  const setContactInfoInvalidMessage = (message: string) =>
    setContactInfoMessage(message);
  const closeChangToDeliveryModal = () => setChangToDeliveryModal(false);

  const [orderTippingAmount, setOrderTippingAmount] = useState<number>(0);
  const [deliveryTipObject, setDeliveryTipObject] = useState<any>(null);
  const [isVisible, setIsVisible] = useState(!document.hidden);
  const handleVisibility = useCallback(() => {
    setIsVisible(!document.hidden);
  }, []);

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibility);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibility);
    };
  }, [handleVisibility]);

  useEffect(() => {
    return () => {
     sessionStorage.removeItem(CONSTANT.ERROR_MESSAGE)
    };
  }, []);

  const handleShowDeliveryMethodModalOnEdidCheckOut = () => {
    setChangToDeliveryModal(false);
    setOpenDeliveryMethodModal(true);
  };

  const PaymentErrorMessage = handlePaymentForAddPaymentUser
    ? getPaymentErrorMessage()
    : '';
  const [updatedAmount, setUpdatedAmount] = useState(0);
  const [remainingAmount, setRemainingAmount] = useState(0);
  const [subTotalBeforeDiscount, setSubTotalBeforeDiscount] =
    useState<number>(0);
  const updatedOrderTotal = (updatedTotalAmount) => {
    setUpdatedAmount(updatedTotalAmount);
  };
  const [location, setLocalLocation] = useState({
    id: null,
    name: '',
    address: '',
    city_state_zip: '',
    phone: '',
  });

  const itemsOrderDetails = cart?.items || [];

  const checkout = useAppSelector((state) => state.addPayment);
  //selected credit cards for checkout
  const paymentMethods = checkout.paymentMethods;
  //selected gift cards for checkout
  const addedCards = checkout.giftCard;
  //default CZ Gift card for loyalty user
  const defaultGiftCard = checkout.defaultGiftCard;
  const allSelectedCards = paymentMethods
    ? appliedPaymentMethods(paymentMethods, addedCards)
    : [];
  // Leaving it now for build purposes
  useEffect(() => {
    if (!paymentMethods?.length && !addedCards?.length && !showSplitPayment)
      checkoutDispatch(updateIsSplitPaymentForTextToPayFlow(false));
  }, [showSplitPayment, showEditPayment, showGiftCard]);
  const userContactInfo = getUser();
  const { validateInformation } = usePlaceOrder(
    cart,
    checkout,
    userContactInfo,
  );
  const queryClient = useQueryClient();
  const getTotal = (): number => {
    return remainingAmount ? Number(remainingAmount) : 0;
  };

  const calculateTotal = (payments) => {
    let amount = 0;
    payments?.map((item) => (amount = amount + item?.amount));
    return Number(amount.toFixed(2));
  };

  // run on confirmation click
  const invalidInformation = (isExternalPayment: boolean) => {
    let validationToastMessage = '';
    let invalidInformation: boolean =
      isCartItemsUnavailable(cart?.items) ||
      isCartModifiersUnavailable(cart?.items);
    queryClient.refetchQueries(['get-cart', locationInfo?.selectedStore?.id]);
    const totalAmount = getTotal();
    if (
      subTotalBeforeDiscount < CONSTANT.MINIMUM_SUBTOTAL &&
      orderType === DELIVERY_ORDER_TYPE
    ) {
      Toast_Func({
        status: false,
        message:
          'Subtotal should be greater then $' + CONSTANT.MINIMUM_SUBTOTAL,
      });
      return true;
    }

    if (!isExternalPayment) {
      if (
        checkout.paymentMethods?.length == 0 &&
        checkout.giftCard.length == 0
      ) {
        invalidInformation = true;
        setPaymentInvalidMessage('Please add a payment method');
        validationToastMessage = 'Please add a payment method';
        return true;
      }
      if (
        (checkout.paymentMethods.length || checkout.giftCard.length) &&
        calculateTotal(allSelectedCards) != totalAmount
      ) {
        invalidInformation = true;
        setPaymentInvalidMessage("Split amount doesn't match the total.");
        validationToastMessage = "Split amount doesn't match the total.";
        setShowSplitPayment(true);
        Toast_Func({
          status: false,
          message: "Split amount doesn't match the total.",
        });
        return true;
      }
      if (checkout.giftCard.length) {
        const czCard = checkout.giftCard;
        if (czCard?.amount > checkout.defaultGiftCard?.balance) {
          invalidInformation = true;
          setPaymentInvalidMessage(
            'CZ Dollars are less than the amount added.',
          );
          setShowSplitPayment(true);
          Toast_Func({
            status: false,
            message: 'CZ Dollars are less than the amount added.',
          });
          return true;
        }
      }
    }
  };

  useEffect(() => {
    if (locationInfo) {
      const cityStateZipInfo =
        locationInfo.city +
        ' City, ' +
        locationInfo?.state +
        ' ' +
        locationInfo?.zip;
      setLocalLocation({
        id: locationInfo?.id || null,
        name: locationInfo?.name || '',
        address: locationInfo?.address || '',
        city_state_zip: cityStateZipInfo || '',
        phone: locationInfo?.phone || '',
      });
    }
  }, [locationInfo]);

  const [loadingDefault, setLoadingDefault] = useState<boolean>(false);

  const handleGiftCardButton = () => {
    if (!defaultGiftCard?.balance) {
      Toast_Func({
        status: false,
        message: 'You have no available CZ DOLLARS right now.',
      });
      return;
    }
    checkoutDispatch(updateIsSplitPaymentForTextToPayFlow(true));
    if (!addedCards.length) {
      checkoutDispatch(
        addGiftCardForTextToPayFlow({
          name: defaultGiftCard.label,
          id: defaultGiftCard.id,
          amount: defaultGiftCard?.balance,
          type: CONSTANT.GIFT_CARD,
          balance: defaultGiftCard?.balance,
        }),
      );
      setShowGiftCard(true);
      setShowSplitPayment(true);
    } else {
      checkoutDispatch(updateGiftCardForTextToPayFlow([]));
      if (paymentRecords?.length) {
        const defaultPayment = paymentRecords.find((payment) => {
          return paymentMethods.length
            ? payment.id === paymentMethods[0]?.id
            : payment?.is_default;
        });
        const paymentMethod = {
          id: defaultPayment?.id,
          name: defaultPayment?.name,
          number: `Card Ending in *${defaultPayment?.card.number}`,
          valid: `Valid Until ${defaultPayment?.card.exp_month}/${defaultPayment?.card.exp_year}`,
          amount: remainingAmount,
        };
        checkoutDispatch(updatePaymentMethodForTextToPayFlow([paymentMethod]));
      }
      checkoutDispatch(updateIsSplitPaymentForTextToPayFlow(false));
    }
    checkoutDispatch(
      updateAmountOnSplitForTextToPayFlow({ totalAmount: remainingAmount }),
    );
  };

  useEffect(() => {
    !showEditPayment && editRecordId && setEditRecordId(null);
  }, [showEditPayment]);

  useEffect(() => {
    if (!checkout.isSplitPayment && allSelectedCards?.length > 0) {
      checkoutDispatch(
        updateAmountOnSplitForTextToPayFlow({ totalAmount: getTotal() }),
      );
    }
  }, [remainingAmount]);

  useUnload((e) => {
    if (isPlacingOrder) {
      e.preventDefault();
      e.returnValue = true;
      return true;
    }
    return null;
  });

  useEffect(() => {
    if (order) {
      if (order?.payment_details.length > 1) {
        checkoutDispatch(updateIsSplitPaymentForTextToPayFlow(false));
      }
      order?.payment_details.map((payment) => {
        if (payment?.gift_card?.amount) {
          const giftCardReduxHelper = addedCards.length
            ? updateGiftCardForTextToPayFlow
            : addGiftCardForTextToPayFlow;
          const giftCardObj = {
            name: payment?.gift_card?.card_name,
            id: payment?.gift_card?.id,
            amount: payment?.gift_card?.amount,
            type: CONSTANT.GIFT_CARD,
            balance: payment?.gift_card?.balance,
          };
          const giftCardState = addedCards.length ? [giftCardObj] : giftCardObj;
          checkoutDispatch(giftCardReduxHelper(giftCardState));
          checkoutDispatch(updateIsSplitPaymentForTextToPayFlow(true));
          setShowGiftCard(true);
        }
        if (payment?.card?.id) {
          let defaultPayment;
          if (paymentRecords?.length) {
            defaultPayment = paymentRecords.find((paymentCard) => {
              return paymentCard.id == payment?.card?.id;
            });
            if (!defaultPayment) {
              defaultPayment = paymentRecords.find((paymentCard) => {
                return paymentCard?.is_default;
              });
            }
            if (defaultPayment) {
              const paymentMethod = {
                id: defaultPayment?.id,
                name: defaultPayment?.name,
                number: `Card Ending in *${defaultPayment?.card.number}`,
                valid: `Valid Until ${defaultPayment?.card.exp_month}/${defaultPayment?.card.exp_year}`,
                amount: payment?.card?.amount,
              };
              checkoutDispatch(
                updatePaymentMethodForTextToPayFlow([paymentMethod]),
              );
            }
          }
        }
      });
      setRemainingAmount(order?.total_remaining);
    }
  }, [order]);

  const shouldDisableDigitalPaymentsAndOrdering =
    syncingCart ||
    loadingDefault ||
    isCartItemsUnavailable(cart?.items) ||
    isCartModifiersUnavailable(cart?.items) ||
    disablePlaceOrderUntilTaxCalculation;

  const handleConfirmPayment = async () => {
    // Update The api and payload for Request Payment
    if (!invalidInformation(false)) {
      setLoadingDefault(true);
      const payload: any = {
        customer_id: verifyPaymentLinkData.customer_id,
        customer: {
          id: verifyPaymentLinkData.customer_id,
          first_name: order.customer.first_name,
          last_name: order.customer.last_name,
          email: order.customer.email,
          phone: order.customer.phone,
          vehicle: {
            vehicle_information_id: null,
            make: null,
            model: null,
            color: null,
            is_saved: 1,
          },
          address: {
            address_id: null,
            address_name: order.customer.city,
            full_address: order.customer.address,
            street_name: order.customer.street_1,
            zipcode: order.customer.zipcode,
            city: order.customer.city,
            state: order.customer.state,
            apartment_no: null,
            is_saved: 1,
          },
        },
        location_id: verifyPaymentLinkData.location_id,
        destination_id: verifyPaymentLinkData.destination_id,
        payment_methods: getPayments(
          checkout.paymentMethods,
          Number(orderTippingAmount),
          cart.orderDetails?.PickupSlotDetails.pickupMethod,
        ),
        gift_cards: getGiftCards(checkout.giftCard),
        digital_payments: [],
        status: 'open',
        source: 'web_source',
        slot: PickupSlotDetailsPickupSlots.time,
        business_date: PickupSlotDetailsPickupSlots.date,
        mins: '0',
        delivery_tip: deliveryTipObject?.tip || null,
        delivery_tip_format: deliveryTipObject?.type || null,
        add_payment: true,
        is_catering: true,
        send_payment_request: order.send_payment_request,
      };

      if (order?.is_tax_exempted) {
        payload.tax_exempt = { id: order.tax_exempt_id };
      }

      try {
        sessionStorage.removeItem(CONSTANT.ERROR_MESSAGE);
        const response = await orderMicroService.requestPaymentLinkPost(
          id,
          payload,
        );
        const responseOrder = response?.data?.data;
        if (response) {
          if (!response?.data?.status) {
            Toast_Func({
              status: false,
              message: 'Something went wrong',
            });
            setTimeout(() => window.location.reload(), 1000);
          } else {
            setRequestId(true);
            history.push({
              pathname: CONSTANT.REQUEST_PAYMENT_THANK_YOU,
              state: {
                orderId: order?.id,
                message: responseOrder.message,
                title: responseOrder.title,
                receivePayment: true
              },
            });
            return;
          }
        }
      } catch (error) {
        const errorMessage =
          error?.response?.data?.errors[0] ||
          error?.response?.data?.message ||
          'Something went wrong!';
        
        sessionStorage.setItem(
          CONSTANT.ERROR_MESSAGE,
          JSON.stringify(error?.response?.data?.message)
        );
        setTimeout(() =>  refetchVerifyPaymentLinkData(), 1200);
      }
      setLoadingDefault(false);
    }
  };

  return isFetchingVerifyPaymentLink ||
    syncingCart ||
    verifyPaymentLinkData?.is_expired ||
    !order ? (
    <Loader />
  ) : (
    <div
      className={`${styleClasses.checkout_wrapper} pb-5 ${
        loadingDefault ? 'payment-overlay' : ''
      }`}
    >
      <div className={`text-start ms-2 ms-md-4 cz-mt-40`}></div>
      <Container>
        <div className={styleClasses.checkout_container}>
          {PaymentErrorMessage ? <div className='f-s14 alert alert-danger fw-bold clr-dark-red text-center mt-4' dangerouslySetInnerHTML={{ __html: PaymentErrorMessage }} /> : ""}
          <OrderDetails Items={itemsOrderDetails} />
          <OrderDetailsPickupMethod
            orderTypeValidationMessage={orderTypeValidationMessage}
            setOrderTypeValidationMessage={setOrderTypeValidationMessage}
            handleShowPickupMethodModal={handleShowPickupMethodModal}
            handleShowDeliveryMethodModal={handleShowDeliveryMethodModal}
            orderType={orderType}
            pickUpSlotDetails={PickupSlotDetailsPickupSlots}
            hideEditButton={true}
          />
          {orderType == DELIVERY_ORDER_TYPE ? (
            <DeliveryLocation
              setOrderDeliveryInvalidMessage={setOrderDeliveryInvalidMessage}
              handleShowDeliveryMethodModal={
                handleShowDeliveryMethodModalOnEdidCheckOut
              }
              closeOpenOrderModal={closeChangToDeliveryModal}
              editChangeToDelivery={changToDeliveryModal}
              orderDeliveryValidationMessage={orderDeliveryValidationMessage}
              storeDetilsForAddPayment={{
                address_name: order.customer.city,
                full_address: order.customer.address,
                street_name: order.customer.street_1,
                zipcode: order.customer.zipcode,
                city: order.customer.city,
                state: order.customer.state,
                apartment_no: null,
              }}
            />
          ) : null}
          <StoreLocation
            location={location}
            isDeliveryOrder={orderType == DELIVERY_ORDER_TYPE}
            isChangeAble={false}
          />
          <ContactMethod
            contactInfoMessage={contactInfoMessage}
            setContactInfoMessage={setContactInfoMessage}
            showContactForm={showContactForm}
            handleShowContactForm={handleShowContactForm}
            handleCloseContactForm={handleCloseContactForm}
            hideEditButton={true}
          />
          {order?.current_payment_details?.length ? (
            <SubmittedPaymentMethod order={order} />
          ) : null}
          <div className="shadow-divider d-md-none mt-4" />
          {
            <div className={`${styleClasses.payment_details} text-start`}>
              {
                <div className="d-flex justify-content-between">
                  <h2 className="f-s20 text-start h-22 text-uppercase font-Cls fw-normal f-sm-s16 mt-3">
                    Payment Method
                  </h2>
                </div>
              }
              {
                <div>
                  <SelectedPayments
                    allSelectedCards={allSelectedCards}
                    paymentValidationMessage={paymentValidationMessage}
                    setPaymentValidationMessage={setPaymentValidationMessage}
                    checkout={checkout}
                  />
                </div>
              }
              {
                <div className={`${styleClasses.payment_method_wrap} mt-2 mb-2`}>
                  <Row>
                    <Col
                      md={`${
                        isVerifyPaymentUserSignedIn(authInfo) ? '6' : '12'
                      }`}
                      sm="12"
                    >
                      <button
                        type="button"
                        className={`btn-large outline w-100`}
                        disabled={loadingDefault}
                        onClick={() => {
                          checkout.isSplitPayment && allSelectedCards.length
                            ? handleShowSplitPayment()
                            : handleShowAddPayment();
                        }}
                      >
                        {paymentMethods.length > 0
                          ? 'EDIT PAYMENT METHOD'
                          : 'ADD PAYMENT METHOD'}
                      </button>
                    </Col>
                    {isVerifyPaymentUserSignedIn(authInfo) ? (
                      <Col md="6" sm="12">
                        <button
                          type="button"
                          className={`btn-large ${
                            !addedCards.length && 'outline'
                          } w-100 mt-md-0 mt-4`}
                          disabled={loadingDefault}
                          onClick={() => {
                            handleGiftCardButton();
                          }}
                        >
                          USE CZ DOLLARS (${defaultGiftCard?.balance || 0})
                        </button>
                      </Col>
                    ) : null}
                  </Row>
                </div>
              }
              {!(<hr className="my-4 d-none d-md-block" />)}
              <div className="shadow-divider d-md-none mt-3" />
              <Billing
                // setTotal={setRemainingAmount}
                cartItems={cart?.items}
                orderType={orderType}
                orderTippingAmount={order.tip}
                isCheckoutBilling={true}
                setDiscountedSubTotal={setDiscountedSubTotal}
                offerDiscount={cart?.discount ?? 0}
                disableCheckout={syncingCart}
                offerDiscountType={OFFER_TYPE_FIXED_DISCOUNT}
                setSubTotal={setSubTotalBeforeDiscount}
                cartInCaseOfAddPaymentFlow={cart}
                isTaxExempt={Boolean(order?.tax_exempt_id)}
                remainingAmount={order?.total_remaining}
                handlePaymentForAddPaymentUser={handlePaymentForAddPaymentUser}
              />{' '}
              <div>
                <button
                  type="button"
                  className="btn-large w-100 d-flex justify-content-between"
                  onClick={() => handleConfirmPayment()}
                  disabled={shouldDisableDigitalPaymentsAndOrdering}
                >
                  confirm payment
                  <span>${persistZero(roundNumber(remainingAmount, 2))}</span>
                </button>
              </div>
            </div>
          }
        </div>
      </Container>

      {/*Payment Method Modal */}
      <CustomModal
        showModal={showEditPayment}
        closeModal={handleCloseEditPayment}
        modalDialogClasses={`${styleClasses.paymentModal}`}
        title={''}
      >
        <PaymentModal
          setOrderTippingAmount={setOrderTippingAmount}
          handleCloseEditPayment={handleCloseEditPayment}
          editPayment={
            paymentMethods.length > 0
              ? paymentRecords.find(
                  (payment) => payment.id == paymentMethods[0].id,
                )
              : null
          }
          checkout={checkout}
          totalAmount={getTotal()}
          guestEditPayment={
            authInfo?.type === GUEST_USER ? paymentMethods[0]?.cardInfo : null
          }
        />
      </CustomModal>

      <CustomModal
        showModal={showSplitPayment}
        closeModal={handleCloseSplitPayment}
        title={''}
        modalDialogClasses={`${styleClasses.paymentModal} ${styleClasses.splitPaymentMethod}`}
      >
        <SplitPaymentMethod
          backBTn={null}
          backBtnHiden={`backBtnHiden`}
          totalAmount={getTotal()}
          checkout={checkout}
          handleCloseSplitPayment={handleCloseSplitPayment}
          setOrderTippingAmount={setOrderTippingAmount}
          paymentValidationMessage={paymentValidationMessage}
          setPaymentInvalidMessage={setPaymentInvalidMessage}
          showGiftCard={showGiftCard}
        />
      </CustomModal>
    </div>
  );
};

export default RequestPayment;
