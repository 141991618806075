import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { s3BaseUrl } from '../../../constants';
import { ISelectedItemDetailsForModal } from '../../../models/try2Combo';
import { setShowCart } from '../../../redux/slices/cartSlice';
import { useAppDispatch } from '../../../redux/store/store';

import styleClasses from './order.module.scss';

interface ModalProps {
  showModal: boolean;
  closeModal: () => void;
  name: string;
  image: string;
  comboItems?: ISelectedItemDetailsForModal[];
  styleTitle?: string;
  styleName?: string;
  isSingleItem?: boolean;
  isCombo?: boolean;
  item?: any;
}
const AddedModal: React.FunctionComponent<ModalProps> = (props) => {
  const {
    showModal,
    closeModal,
    name,
    image,
    comboItems,
    styleTitle,
    styleName,
    isSingleItem,
    item,
  } = props;
  const dispatch = useAppDispatch();
  const itemImage = comboItems ? (
    <div className=" pt-2 pb-2 row">
      {comboItems?.map((item) => {
        return item?.is_selected ? (
          <div
            className={`${
              isSingleItem ? 'd-flex flex-column align-items-center' : 'col-6'
            }`}
          >
            <div
              className={`${styleClasses.imgWrapper} d-flex align-items-center`}
            >
              <img
                src={`${s3BaseUrl}${item.image}?version=1`}
                className={`mb-1 ${styleClasses.img_fluid_border}`}
                alt="icon"
              />
            </div>
            <p className="text-center font-rale mt-2 fw-500 f-s16 f-sm-s14">
              {' '}
              {item?.itemName}
            </p>
          </div>
        ) : null;
      })}
    </div>
  ) : (
    <img src={image} className="img-fluid" alt="menu_image" />
  );

  useEffect(() => {
    const isOpenCart = sessionStorage.getItem('recently_item_added_to_cart');
    parseInt(isOpenCart) && openCartAfterAddingAndItem();
  }, []);

  const openCartAfterAddingAndItem = () => {
    dispatch(setShowCart(true));
    sessionStorage.setItem('recently_item_added_to_cart', '0');
  };

  const onContinueClick = () => {
    closeModal();
    // to={`/menu/${menu.name}`}
    // window.location.reload();
  };

  const onViewCartClick = () => {
    window.location.reload();
    sessionStorage.setItem('recently_item_added_to_cart', '1');
  };
  return (
    <>
      <Modal
        show={showModal}
        centered
        // className="theme-modal-style app_container_wrap xs-modal border-radius-15 align-sm-bottom added-modal-size"
        className={`theme-modal-style app_container_wrap xs-modal border-radius-15 align-sm-bottom ${styleClasses.addedModalSize}`}
        // onHide={closeModal}
      >
        <Modal.Header className="pt-0">
          {/* <button
            type="button"
            className="btn modal-close-button pe-0"
            onClick={closeModal}
          >
            Close
          </button> */}
        </Modal.Header>
        <Modal.Body className="px-0 pb-5 pt-3">
          <div className="d-flex flex-column h-100 no-shadow">
            <div className={`${styleClasses.item_title_wrap} menu-title`}>
              <h6 className={`sub_title1 text-center text-uppercase f-s22`}>
                Added to your cart!
              </h6>
            </div>

            <div
              className={`${styleClasses.item_calor_wrap} menu-calories d-flex justify-content-center`}
            >
              <p
                className={`f-s18 clr-dark-grey font-Visby-cf f-w6  text-center pt-1 ${styleName}`}
              >
                {name}
              </p>
            </div>
            <hr className="my-2" />
            <div className="px-md-5 pt-0">{itemImage}</div>

            <Link to={`/menu/${item}`}>
              <div
                // type="button"
                className={` btn-large position-relative py-2 btn-disabled-bg w-100  ${styleClasses.added_modal_btn}`}
                // onClick={() => onContinueClick()}
              >
                Continue Ordering
              </div>
            </Link>
            <button
              type="button"
              className={`btn-large outline mt-2 py-2 w-100 ${styleClasses.added_modal_btn}`}
              onClick={() => onViewCartClick()}
            >
              View Cart
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddedModal;
