import React from 'react';

import { IOtherDressing } from '../../../models/item.model';
import styleClasses from '../try2.module.scss';

const OtherDressing: React.FC<IOtherDressing> = (props) => {
  const {
    otherDressingHandler,
    styleClass,
    selectCard,
    isNoDressing,
    groupMin,
    label,
    showRedBorderAnimation,
  } = props;
  const cardSelection = () => {
    return selectCard || isNoDressing ? styleClasses.selected : {};
  };
  const name = label ?? 'Modifiers';
  return (
    <div
      className={`${styleClasses.add_on_item_wrap} ${styleClasses.noDresingCard
        } cursor-pointer shadow-box d-flex flex-column justify-content-between other-dressing-item-wrap ${styleClass && styleClasses.styleClass
        } ${showRedBorderAnimation ? 'border-size-select' : ''}`}
      onClick={otherDressingHandler}
    >
      <p className="f-s14 f-w5 mb-0 mt-3">
        Other
        <br />
        {name}
        {groupMin === 0 && (
          <>
            <br />
            or
            <br />
            No {name}
          </>
        )}
      </p>
      <div className={styleClasses.buttons_wraper}>
        <button
          type="button"
          className={`${styleClasses.item_select_btn} item-select-btn mx-auto mw-auto`}
        >
          Select
        </button>
      </div>
    </div>
  );
};

export default OtherDressing;
