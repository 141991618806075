import React from 'react';
import { Container, Row } from 'react-bootstrap';
import { filterArraysBasedOnGroupId } from 'src/helper/customizedItem/customizedItem.helper';
import { itemBuckets } from 'src/priceCalculation/buckets';
import {
  ADDITIONAL_ADDED,
  COMPLIMENTARY_MODIFIERS,
} from 'src/priceCalculation/constants';
import { removeDuplicateModifiers } from 'src/redux/utility';

import styleClasses from './try2/try2.module.scss';
import AddOns from './AddOns';

const ComplementaryItems: React.FC<any> = (props) => {
  const {
    itemFromApi,
    unrepeatedIngredients,
    order,
    itemNo,
    isItEdit,
    isSingleItemCustomization,
    handleIngredientSelection,
    titleshow,
    FullSize,
    items_count,
  } = props;

  const handleComplementaryIngredientSelection: (data: {
    modifier_id: number;
    modifier_name: string;
    modifier_calories: number;
    display_price: number;
    brink_id: number;
    quantity?: number;
    modifier_group_id: number;
    brink_modifier_group_id: number;
    modifier_group_max?: number;
    complimentary_modifier?: number;
    is_selected?: boolean;
  }) => void = (data) => {
    handleIngredientSelection(data);
  };
  const selectedIngredients = () => {
    return itemBuckets.specificItemBucketSelectedModifiers(
      COMPLIMENTARY_MODIFIERS,
      itemNo,
    );
  };

  const complementaryBucket = (function () {
    return itemBuckets.getSingleBucket({
      name: COMPLIMENTARY_MODIFIERS,
      fromItem: itemNo,
    });
  })();

  const isLimitExceed = (extendable_limit: number, groupId: number) => {
    // const byDefaultComplementary =
    //   complementaryBucket?.byDefaultAdded.modifiers;
    // const additionalAddedComplementary =
    //   complementaryBucket[ADDITIONAL_ADDED]?.modifiers;

    // const ComplementaryModifiers = filterArraysBasedOnGroupId(
    //   [byDefaultComplementary, additionalAddedComplementary],
    //   groupId,
    // );
    // return ComplementaryModifiers.length >= extendable_limit ? true : false;
    return false;
  };

  const modifiers = removeDuplicateModifiers(
    itemFromApi?.complementary_items.filter(
      (group) => !group.is_customizable_menu,
    ),
    unrepeatedIngredients,
  );

  const addOns = () => {
    return modifiers?.map((addOn) => (
      <AddOns
        key={addOn.id}
        order={order}
        ingredients={addOn.modifiers_groups_modifiers}
        selectedIngredients={selectedIngredients()}
        title={addOn.label || addOn.name}
        handleClick={handleComplementaryIngredientSelection}
        modifierGroup={addOn}
        isLimitExceed={isLimitExceed(addOn.extendable_limit, addOn?.id)}
        items_count={items_count}
        itemNo={itemNo}
        isItEdit={isItEdit}
        isSingleItemCustomization={isSingleItemCustomization}
        FullSize={FullSize}
        contentCenter={'justify-content-center'}
      />
    ));
  };

  return (
    <>
      {isSingleItemCustomization ? (
        <>
          {addOns()}
          {addOns().length > 0 ? (
            <hr className="d-md-none custom_hr_sty" />
          ) : null}
        </>
      ) : (
        <Container className="px-0">
          <Row className="justify-content-center mx-0 mx-md-n3">{addOns()}</Row>
        </Container>
      )}
    </>
  );
};

export default ComplementaryItems;
