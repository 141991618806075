import RadioSelect from 'src/components/RadioSelect/RadioSelect';
import * as CONSTANT from 'src/constants';
import { getCustomizedItemPrice } from 'src/helper/getCombinePrice/getCombinePrice.helper';
import { ItemDetails, Order } from 'src/models/item.model';
import { toFixedNumber } from 'src/priceCalculation/helper';

interface propTypes {
  item: ItemDetails; // true if the item is selected for a specific size or false otherwise
  handleSelection: (e: any, size: string, isHalf?: boolean) => void;
  order?: Order;
  isSize: boolean;
  price?: any;
}

const index: React.FC<propTypes> = (props) => {
  const { item, handleSelection, order, isSize, price } = props;

  const getItemTotal = (itemPrice, size) => {
    const isSelected =
      order.size === CONSTANT.FULL_SIZE.id ||
      order.size === CONSTANT.HALF_SIZE.id;
    let HalfSizeOrder = order;
    let totalPrice = 0;
    if (size == CONSTANT.HALF_SIZE.id) {
      HalfSizeOrder = {
        ...order,
        display_price: item.half_brink_item_price,
      };
    }
    totalPrice = getCustomizedItemPrice(HalfSizeOrder, itemPrice);
    if (isSelected) {
      totalPrice = toFixedNumber(totalPrice + order?.display_price);
    }

    return totalPrice;
  };
  return (
    <>
      {item.is_salad_tray ? (
        <h4 className="f-s25 font-Cls mb-0 lh-normal">Select Size</h4>
      ) : null}
      <div
        className={`d-flex gap-3 select_item_size ${
          item.is_salad_tray ? 'pt-0' : 'pt-4'
        }`}
      >
        {item.half_brink_item_id && (
          <RadioSelect
            text={item.half_item_label}
            amount={
              item.is_box_lunch
                ? price
                : getItemTotal(
                    item.half_brink_item_price,
                    CONSTANT.HALF_SIZE.id,
                  )
            }
            orignalPrice={item.half_brink_item_price}
            img={undefined}
            id={item.half_item_label}
            className={item.half_brink_item_id ? 'w-50' : 'w-100'}
            onChange={(e) => handleSelection(e, CONSTANT.HALF_SIZE.id, true)}
            selected={order?.size === CONSTANT.HALF_SIZE.id}
            isSize={isSize}
            servingText={item?.half_item_short_description}
          />
        )}
        <RadioSelect
          text={item.full_item_label}
          amount={
            item.is_box_lunch
              ? price
              : getItemTotal(item.price, CONSTANT.LARGE_SIZE.id)
          }
          orignalPrice={item.price}
          img={undefined}
          id={item.full_item_label}
          className={item.half_brink_item_id ? 'w-50' : 'w-100'}
          onChange={(e) => handleSelection(e, CONSTANT.LARGE_SIZE.id)}
          selected={order?.size === CONSTANT.LARGE_SIZE.id}
          isSize={isSize}
          servingText={item?.full_item_short_description}
          isBoxLunch={item?.is_box_lunch}
        />
      </div>
    </>
  );
};

export default index;
