import { useQuery } from 'react-query';
import { orderMicroService } from '../services';

const requestPaymentLink = async (id: string) => {
  const response = await orderMicroService.requestPaymentLink(id);
  return response.data.data;
};

export default function useRequestPaymentLink(id: string) {
  return useQuery(id, () => requestPaymentLink(id), {
    // retry: false,
    cacheTime: 0,
    staleTime: 0,
    enabled: Boolean(id),
  });
}
