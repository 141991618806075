import { useState } from 'react';
import * as CONSTANT from 'src/constants';
import AddAndRemoveModal from 'src/Features/BoxLunches/ComboSection/AddAndRemoveModal';
import useCheckMobileScreen from 'src/hooks/useCheckMobileScreen';
import { ItemModal, Order } from 'src/models/item.model';

import styles from '../itemDetails.module.scss';
import { isItCreateYourOwnItem } from 'src/helper/helperMethods';
interface propTypes {
  handleSelection: (e: any, size: string) => void;
  order?: Order;
  boxLunchCombo: ItemModal[];
  findSelectedItemAvailability: any;
  Item: any;
  Category: any;
  isSingleItem?: boolean;
  onRemoveClickHandler?: (id: string) => void;
  onEditClickHandler: any;
  setEditFromDetails: any;
}

const index: React.FC<propTypes> = (props) => {
  const {
    order,
    boxLunchCombo,
    findSelectedItemAvailability,
    Item,
    Category,
    handleSelection,
    isSingleItem,
    onRemoveClickHandler,
    onEditClickHandler,
    setEditFromDetails,
  } = props;
  const isSelected =
    order.size === CONSTANT.FULL_SIZE.id ||
    order.size === CONSTANT.HALF_SIZE.id;
  const [AddAndRemoveModalState, setAddAndRemoveModalState] = useState([
    false,
    false,
  ]);
  const AndRemoveModalCloseHandler = (index) => {
    AddAndRemoveModalState[index] = false;
    setAddAndRemoveModalState([...AddAndRemoveModalState]);
    // setEditFromDetails(false);
  };
  const AddAndRemoveModalHandler = (index) => {
    setEditFromDetails(true);
    AddAndRemoveModalState[index] = true;
    setAddAndRemoveModalState([...AddAndRemoveModalState]);
  };
  const isItMobile = useCheckMobileScreen();
  const makeASelection = isItMobile
    ? CONSTANT.SELECT_AN_ITEM
    : CONSTANT.MAKE_A_SELECTION;
  return (
    <>
      <div
        className={`${
          isSingleItem ? 'd-flex align-items-center' : 'row'
        }   select_item_size ${styles.radioInputFields}`}
      >
        {boxLunchCombo?.map((section, index) => {
          const selected = findSelectedItemAvailability(section.id);
          // Get selected Item
          const item = Item(selected, section.id);

          // Get selected category
          const category = Category(selected, section.id);
          const isDisabled =
            item?.is_customizable !== undefined &&
            !item?.is_customizable &&
            !isItCreateYourOwnItem(item?.is_create_your_own);
          return (
            <>
              {item ? (
                <div
                  className={`${isSingleItem ? 'w-50 mx-auto' : 'col-6'}`}
                  key={section.id}
                >
                  <div
                    onClick={() => {
                      AddAndRemoveModalHandler(index);
                    }}
                    className={`${styles.boxlunxhSelectedItems}`}
                    key={section.id}
                  >
                    <div className="">
                      <img
                        src={
                          item
                            ? `${CONSTANT.s3BaseUrl}${item.image}?version=1`
                            : ''
                        }
                        alt="img"
                        className=" m-auto"
                      />
                      <div className="cz-pb-32">
                        <p className={styles.categoryName}>
                          {category?.name ? item.name : ''}
                        </p>
                        {/* <p
                          className={styles.calories}
                        >{`${category?.calories} cal`}</p> */}
                      </div>
                    </div>

                    <div className={`${styles.clickToEdit}`} key={section.id}>
                      Click to Change
                    </div>
                  </div>
                  <AddAndRemoveModal
                    onRemoveClickHandler={onRemoveClickHandler}
                    onEditClickHandler={onEditClickHandler}
                    showAddAndRemoveModal={AddAndRemoveModalState[index]}
                    hideAddAndRemoveModal={() => {
                      AndRemoveModalCloseHandler(index);
                    }}
                    name={category?.name ? item.name : makeASelection}
                    id={section.id}
                    isCustomizeable={isDisabled}
                  />
                </div>
              ) : undefined}
            </>
          );
        })}
      </div>
    </>
  );
};

export default index;
