/* eslint-disable prefer-const */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'src/components/Button/Button';
import CustomModal from 'src/components/customModal/CustomModal';
import AddBoxLunchIngredients from 'src/containers/Modifiers/AddBoxLunchIngredients';

import {
  REQUIRED_MODIFIERS,
  BOX_LUNCH_SELECTED_SECTION_FIRST_SECTION,
} from '../../../constants';
import styleClasses from '../../../containers/Modifiers/try2.module.scss';
import {
  IModifiersObjects,
  ITry2Customization,
} from '../../../models/try2Combo';
import { itemBuckets } from '../../../priceCalculation/buckets';
import { modifiersAgainstAnItem } from '../../../priceCalculation/helper';
import {
  removeTemporarilyAddedPriceForItem,
  updateSpecificItemSelectedModifiers,
} from '../../../redux/slices/itemCustomizationSlice';

import 'swiper/css/bundle';
import EventBusForBYOCategoryScrollingBetweenAccordions from 'src/utils/EventBusForBYOCategoryScrollingBetweenAccordions';
import { persistZero } from 'src/helper/helperMethods';

const BoxLunchesCustomization: React.FunctionComponent<any> = (props) => {
  let {
    showCustomizationModal,
    closeCustomizationModal,
    modifierGroups,
    handleIngredientSelection,
    coreModifiers,
    requiredModifiers,
    addItemtoComboClick,
    handleSyncIngredients,
    selectedItem,
    sectionSelected,
    removeSelectedIngredients,
    currentEditItem,
    noDressingHandler,
    itemName,
    isItEdit = false,
    styles,
    setSelectedItem,
    Item,
    isCreateYourOwnItem,
  } = props;

  const order = useSelector((state: any) => state.itemCustomization);
  const dispatch = useDispatch();

  const totalPrice = persistZero(order?.display_price + Item.price);

  const [disableApply, setDisableApply] = useState<boolean>(false);
  const [selectedCustomizeData, setSelectedCustomizeData] = useState<
    IModifiersObjects[]
  >([]);
  const addSelectedModifiers = (selectedModifier, commulativeItems): void => {
    selectedModifier?.modifiers.forEach((element) => {
      const index = commulativeItems.findIndex(
        (e) => e.groupId === element?.modifier_group_id,
      );
      if (index !== -1) {
        commulativeItems[index].quantity =
          commulativeItems[index].quantity + element?.quantity;
        commulativeItems[index].noDressing = false;
      } else {
        const trackObj = {
          groupId: element?.modifier_group_id,
          quantity: element?.quantity,
          noDressing: false,
        };
        commulativeItems.push(trackObj);
      }
    });
  };

  const addNoDressings = (selectedModifier, commulativeItems): void => {
    selectedModifier?.noDressings.forEach((element) => {
      const index = commulativeItems.findIndex(
        (e) => e.groupId === element?.id,
      );
      if (index === -1) {
        const trackObj = {
          groupId: element?.id,
          quantity: 0,
          noDressing: element?.noDressing,
        };
        commulativeItems.push(trackObj);
      } else {
        commulativeItems[index].noDressing = element?.noDressing;
      }
    });
  };

  const getOrderButtonState = () => {
    let activeItem = null;
    if (currentEditItem) {
      activeItem = currentEditItem;
    } else {
      currentEditItem = sectionSelected;
    }
    if (requiredModifiers) {
      const selectedModifier = itemBuckets.getSingleBucket({
        name: REQUIRED_MODIFIERS,
        fromItem: parseInt(activeItem),
      });
      const commulativeItems = [];
      if (!requiredModifiers.length) {
        setDisableApply(false);
        return;
      }
      addSelectedModifiers(selectedModifier, commulativeItems);

      if (selectedModifier?.noDressings) {
        addNoDressings(selectedModifier, commulativeItems);
      }

      let isMinExeceeded = null;
      for (let i = 0; i < requiredModifiers.length; i++) {
        const itemIndex = commulativeItems.findIndex(
          (e) => e.groupId === requiredModifiers[i].id,
        );
        const minReached =
          commulativeItems[itemIndex]?.noDressing === false
            ? commulativeItems[itemIndex]?.quantity > 0 &&
              commulativeItems[itemIndex]?.quantity >= requiredModifiers[i].min
            : true;
        if (itemIndex === -1 || !minReached) {
          isMinExeceeded = false;
          break;
        } else {
          isMinExeceeded = true;
        }
      }

      if (isMinExeceeded) {
        setDisableApply(false);
      } else {
        setDisableApply(true);
      }
    } else {
      setDisableApply(true);
    }
  };

  useEffect(() => {
    getOrderButtonState();
  }, [order]);

  const updateSelectedModifiers = (modifier: IModifiersObjects) => {
    updateSelectedCustomizeData(modifier);
    handleIngredientSelection(modifier);
  };

  const addOldQuantityToModifier = (quantity: number, index: number) => {
    const exist = selectedCustomizeData[index];
    if (index === -1 || typeof exist?.oldQuantity === 'undefined') {
      if (quantity) {
        return quantity;
      } else {
        return 0;
      }
    } else {
      return exist.oldQuantity;
    }
  };

  const updateSelectedCustomizeData = (modifier: IModifiersObjects) => {
    let quantity = null;
    if (modifier?.quantity) {
      const item = order?.modifiers.find(
        (item) => item.item === currentEditItem,
      );
      const updateModifier = item?.modifiers.find(
        (currentModifier) =>
          currentModifier?.modifier_id === modifier.modifier_id,
      );
      quantity = updateModifier?.quantity;
    }
    const index = selectedCustomizeData?.findIndex(
      (selectedModifier) =>
        selectedModifier.modifier_id === modifier.modifier_id,
    );
    modifier.oldQuantity = addOldQuantityToModifier(quantity, index);
    if (index !== -1) {
      selectedCustomizeData[index] = modifier;
      setSelectedCustomizeData([...selectedCustomizeData]);
    } else {
      setSelectedCustomizeData([...selectedCustomizeData, modifier]);
    }
  };

  const addSelectedIngredients = () => {
    closeCustomizationModal(false);
    addItemtoComboClick(selectedItem);
  };

  const closeModal = () => {
    const itemId = currentEditItem ? currentEditItem : sectionSelected;
    removeSelectedIngredients(selectedCustomizeData, sectionSelected);
    closeCustomizationModal(true);
    dispatch(
      removeTemporarilyAddedPriceForItem({
        price: itemBuckets.discardBucketChanges(itemId),
        item: itemId,
      }),
    );
    dispatch(
      updateSpecificItemSelectedModifiers({
        itemModifiers: modifiersAgainstAnItem({
          itemId: BOX_LUNCH_SELECTED_SECTION_FIRST_SECTION,
        }),
        itemId: '1',
      }),
    );
    setSelectedItem('');
  };

  return (
    <CustomModal
      showModal={showCustomizationModal}
      closeModal={closeModal}
      title={''}
      size="lg"
      modalBodyClass={`${styles.boxCustomizeModal} px-lg-2`}
      modalDialogClasses={styles.boxCustomizationWraper}
      scrollable={true}
      id="modal_handle_for_byo_items"
    >
      {isCreateYourOwnItem && (
        <>
          <h2 className="font-Cls f-s22 f-sm-s17 ls-normal lh-normal text-center py-2 text-uppercase">
            {Item.name + ' - ' + itemName}
          </h2>
          <h6 className="font-Cls f-s22 f-sm-s17 ls-normal lh-normal text-start ms-3 mb-0">
            Total: ${totalPrice}
          </h6>
        </>
      )}
      <div className={`${styleClasses.items_wrap} mb-4 mb-sm-3 py-0 px-md-0`}>
        <AddBoxLunchIngredients
          modifierGroups={modifierGroups}
          coreModifiers={coreModifiers}
          requiredModifiers={requiredModifiers}
          handleIngredientSelection={(data: IModifiersObjects) =>
            updateSelectedModifiers(data)
          }
          selectedItem={selectedItem}
          handleSyncIngredients={handleSyncIngredients}
          sectionSelected={currentEditItem}
          noDressingHandler={noDressingHandler}
          itemName={itemName}
          isItEdit={isItEdit}
          Item={Item}
          isCreateYourOwnItem={isCreateYourOwnItem}
          isCYOIColSize={'col-lg-3'}
        />
        <Button
          disabled={!isCreateYourOwnItem && disableApply}
          onClick={() => {
            let eventResult;
            if (isCreateYourOwnItem) {
              eventResult =
                EventBusForBYOCategoryScrollingBetweenAccordions.emit(
                  'checkForMissingRequiredModifers',
                );
            }
            if (eventResult?.length > 0 && eventResult[0].status) {
              EventBusForBYOCategoryScrollingBetweenAccordions.emit(
                'updateMissingRequiredRefCurrentMissingIndex',
                eventResult[0].missingGroupIndex,
              );
              EventBusForBYOCategoryScrollingBetweenAccordions.emit(
                'refUpdatedForBuildYourOwnRequiredScrollOnAddOnsAccorionClick',
                EventBusForBYOCategoryScrollingBetweenAccordions.getState(),
              );
              setTimeout(() => {
                EventBusForBYOCategoryScrollingBetweenAccordions.emit(
                  'scrollToMissingIndex',
                  {
                    status: true,
                    missingGroupIndex: eventResult[0].missingGroupIndex,
                    startScrollingFor: 'required',
                    limitForCompareType: 'min',
                  },
                );
              }, 300);
            } else {
              addSelectedIngredients();
            }
          }}
          className="text-center d-block m-auto mt-4"
        >
          {isCreateYourOwnItem ? 'Build' : 'Apply'}
        </Button>
      </div>
    </CustomModal>
  );
};
export default BoxLunchesCustomization;
