import React from 'react'
import { Toast_Func } from 'src/helper/toast.helper';

interface ISplitPaymentToggleProps {
    setSplitToggle?: (isSplitPayment: boolean) => void,
    isSplitPayment?: boolean,
    handleSplitToggle?: () => void,
    defaultCard?: any,
}
const SplitPaymentToggle = (props: ISplitPaymentToggleProps) => {
    const { setSplitToggle, isSplitPayment, handleSplitToggle, defaultCard } = props;
    const handleCZCardToggle = () => {
        if (defaultCard && !defaultCard?.balance)
        {
            Toast_Func({
                status: false,
                message: 'You have no available CZ DOLLARS right now.',
            });
            return;
        }
        setSplitToggle(!isSplitPayment);
        if (!isSplitPayment) handleSplitToggle?.();
    }
    return (
        <>
            <div className="form-group theme_check_boxes Switch_btn rounded  my-4">
                <input
                    type="checkbox"
                    id={`radiobox_cart`}
                    name="address_group"
                    onChange={handleCZCardToggle}
                    checked={isSplitPayment}
                />
                <label
                    htmlFor={`radiobox_cart`}
                    className="d-flex align-items-center f-s18 f-sm-s16"
                >
                    <span className="box_wrap mt-1"></span>
                    <span className={`d-flex justify-content-between info_label`}>
                        {
                            defaultCard ?
                                'Use CZ Dollars' :
                                'Split Payment'
                        }
                    </span>
                </label>
            </div>
        </>
    )
}

export default SplitPaymentToggle