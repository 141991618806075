import React from 'react';
import { Col, Row } from 'react-bootstrap';
import CounterCard from 'src/components/Molecules/Card/CounterCard/CounterCard';
import * as Constant from 'src/constants';
import { verifyStatus } from 'src/helper/customizedItem/customizedItem.helper';

import styleClasses from './try2.module.scss';

interface Param {
  modifier_id: number;
  modifier_name: string;
  display_price: number;
  brink_id: number;
  quantity?: number;
  modifier_group_id: number;
  brink_modifier_group_id: number;
  modifier_group_max?: number;
  plusminus?: string;
}

interface IAddOns {
  title: string;
  items_count: any;
  itemNo: number;
  isSingleItemCustomization?: boolean;
  compli_title_size?: string;
  ingredients: {
    extendable_limit: number;
    id: number;
    brink_modifier_id: number;
    name: string;
    price: number;
    image: string;
    calories: number;
    max: number;
    changes: any;
    items_count: string;
    compli_title_size?: string;
    is_selected: number;
    modifier_location: {
      status: string | null;
    }[];
  }[];
  selectedIngredients: {
    additionalPrice?: number;
    modifier_id: number;
    quantity: number;
  }[];
  handleClick: (args: Param) => void;
  isLimitExceed?: boolean;
  modifierGroup: {
    id: number;
    brink_modifier_group_id: number;
    max?: number;
  };
  FullSize?: string;
  isItEdit?: boolean;
  order?: any;
  contentCenter?: string;
}

const AddOns: React.FC<IAddOns> = (props) => {
  const {
    title,
    ingredients,
    selectedIngredients,
    handleClick,
    modifierGroup,
    isLimitExceed,
    items_count,
    compli_title_size,
    isSingleItemCustomization,
    FullSize,
    isItEdit,
    contentCenter,
  } = props;

  const handleIngredientSelection = (data, ingredient) => {
    handleClick({
      ...data,
      ...{
        modifier_group_id: modifierGroup?.id,
        modifier_group_max: modifierGroup?.max,
        modifier_type: Constant.COMPLIMENTARY_MODIFIER,
        brink_modifier_group_id: modifierGroup?.brink_modifier_group_id,
        complimentary_modifier: ingredient?.complimentary_modifier,
      },
    });
  };

  const selectedIngredient = (ingredient: any) => {
    return selectedIngredients?.find(
      (selected) => selected.modifier_id === ingredient.id,
    );
  };

  const counterCard = (ingredient, selectedSide) => {
    return (
      <CounterCard
        id={ingredient.id}
        brink_id={ingredient.brink_modifier_id}
        image={`${Constant.s3BaseUrl}${ingredient?.image}`}
        title={ingredient.name}
        default_selected={ingredient.is_selected}
        calories={ingredient.calories}
        price={ingredient.price}
        is_active={verifyStatus(ingredient, Constant.MODIFIER_LOCATION)}
        handleClick={(data) => handleIngredientSelection(data, ingredient)}
        max={ingredient.max}
        count={selectedSide?.quantity || 0}
        additionalPrice={selectedSide?.additionalPrice}
        priceAlternateLabel={selectedSide ? 'Included' : 'include'}
        from={Constant.TRY_2_COMBO}
        // isAddByDefault={isAddByDefault()}
        extendableLimit={isLimitExceed}
        isItEdit={isItEdit}
      />
    );
  };

  return (
    <>
      {isSingleItemCustomization ? (
        <div className="Complimentary_sides-view mt-4">
          <h5
            className={`${
              compli_title_size
                ? compli_title_size
                : 'f-s20 f-sm-s18 text-capitalize'
            } lh-normal font-Cls text-start py-2 mb-0`}
          >
            {title}
          </h5>
          <div className={`${styleClasses.dressing_items_scrollbar} row`}>
            {ingredients.map((ingredient, i) => {
              const selectedSide = selectedIngredient(ingredient);
              return (
                <div
                  key={i}
                  className={`px-2 py-2 col-xl-4 col-lg-4 col-md-4 col-4`}
                >
                  {counterCard(ingredient, selectedSide)}
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <Col
          lg="7"
          className={`${FullSize} Complimentary_sides-view px-0 px-md-3`}
        >
          <h5
            className={`${
              compli_title_size ? compli_title_size : 'f-s30 f-sm-s18'
            } large_text text-center mb-sm-3  pt-md-2`}
          >
            {title}
          </h5>
          <Row
            className={`${styleClasses.items_inner_wrap} ${contentCenter} mb-3`}
          >
            {ingredients.map((ingredient, i) => {
              const selectedSide = selectedIngredient(ingredient);
              return (
                <div
                  className={`${items_count} mb-4 complimentry__items_wrap`}
                  key={ingredient?.id}
                >
                  {counterCard(ingredient, selectedSide)}
                </div>
              );
            })}
          </Row>
        </Col>
      )}
    </>
  );
};

export default AddOns;
