import { FC, useState } from 'react';
import Button from 'src/components/Button/Button';
import CustomModal from 'src/components/customModal/CustomModal';

import QuantityCounter from '../QuantityCounter/QuantityCounter';

import styles from '../CartDetailCard/cartDetailCard.module.scss';

interface cardDetailProps {
  cartItem: string;
  ingredients: string;
  name: string;
  ingredient1: string;
  ingredient2: string;
  ingredient3: string;
  ingredient4: string;
}

const GroupOrderCartDetailCard: FC<cardDetailProps> = ({
  cartItem,
  ingredient1,
  ingredient2,
  ingredient3,
  ingredient4,
  name,
}) => {
  const [removeItemModal, setRemoveItemModal] = useState(false);
  return (
    <>
      <div className={styles.cartDetailCardWrap}>
        <span className="f-s14">{name}</span>
        <hr />

        <div className={styles.cardHeading}>
          <h4 className="font-Cls f-sm-s12 f-s16 mb-0 fw-light cs_lt">
            {cartItem}
          </h4>
          <div className={styles.cardBtn}>
            <p className="">edit</p>
            <p className="" onClick={() => setRemoveItemModal(true)}>
              Remove
            </p>
          </div>
        </div>
        <div className={styles.ingredientList}>
          <p className="clr-lt-gray cz-mb-4">{ingredient1}</p>
          <p className="clr-lt-gray cz-mb-4">{ingredient2}</p>
          <p className="clr-lt-gray cz-mb-4">{ingredient3}</p>
          <p className="cz-mb-4 clr-red">{ingredient4}</p>
        </div>
        <div className={styles.cardFooter}>
          <QuantityCounter />
          <div className={styles.totalAmount}>
            <span className="f-s14 clr-lt-gray ">Total</span>
            <p className="mb-0 fw-bold">$106.49</p>
          </div>
        </div>
        <hr />
        <div className={styles.cardHeading}>
          <h4 className="font-Cls f-sm-s12 f-s16 mb-0 fw-light cs_lt">
            Raspberry Citrus Infusion
          </h4>
          <div className={styles.cardBtn}>
            <p className="">edit</p>
            <p className="" onClick={() => setRemoveItemModal(true)}>
              Remove
            </p>
          </div>
        </div>
        <div className={styles.ingredientList}>
          <p className="clr-lt-gray cz-mb-4">Large</p>
        </div>
        <div className={styles.cardFooter}>
          <QuantityCounter />
          <div className={styles.totalAmount}>
            <span className="f-s14 clr-lt-gray ">Total</span>
            <p className="mb-0 fw-bold">$106.49</p>
          </div>
        </div>
      </div>
      <CustomModal
        showModal={removeItemModal}
        title={''}
        closeModal={() => setRemoveItemModal(false)}
        modalBodyClass=""
        modalDialogClasses={styles.shareLinkModal}
      >
        <div className="text-center">
          <h6 className="font-Cls ls-normal mb-sm-4 f-s22">Remove Item</h6>
          <p className="mt-3 f-s14">
            Are you sure you want to remove this item from your order?
          </p>
          <div>
            <Button className="mt-4" onClick={() => setRemoveItemModal(false)}>
              Remove
            </Button>
            <Button
              className="btn-text mt-4 mb-4"
              onClick={() => setRemoveItemModal(false)}
            >
              No, keep this order
            </Button>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default GroupOrderCartDetailCard;
