import { getUser } from 'src/helper/helperMethods';
import QRCodeCanvas from 'qrcode.react';
import useGetGiftCardNumber from 'src/react-query-hooks/Loyalty/useGetGiftCardNumber';

interface RedeemDollarsModalProps {
  header: string;
  title: string;
  description: string;
  qrCodeValue: string;
}

const RedeemDollarsModal = ({
  header,
  title,
  description,
  qrCodeValue
}: RedeemDollarsModalProps) => {
  return (
    <div>
      <h2 className="font-Cls f-s22 text-center text-uppercase lh-base clr-dar-gray">
        {header}
      </h2>
      <p className="font-Visby-cf f-s14 text-center text-uppercase lh-base clr-dar-gray">
        <span className="f-w8">{title}</span>
      </p>
      <hr className="mt-3 mb-0" />
      <p className="font-Visby-cf f-s12 text-center text-uppercase lh-base clr-dar-gray cz-mb-32 mt-3">
        {description}
      </p>
      <div className="text-center cz-mt-60 cz-mb-40">
        <QRCodeCanvas value={qrCodeValue} size={200} />
      </div>
    </div>
  );
};
export default RedeemDollarsModal;
