import React, { useEffect, useState } from 'react';
import { Col, Spinner } from 'react-bootstrap';
import styleclasses from 'src/Features/order/order.module.scss';
import { GUEST_USER } from 'src/constants';
import { LocationModel } from 'src/models/location.model';
import useAddFavoriteLocation from 'src/react-query-hooks/Favorites/useAddFavoriteLocation';
import useRemoveFavoriteLocation from 'src/react-query-hooks/Favorites/useRemoveFavoriteLocation';
import { useAppSelector } from 'src/redux/store/store';
import ActiveLocation from './ActiveLocation';
import InActiveLocation from './InActiveLocation';
interface ISearchedLocationsCardProps {
  styleClasses: { readonly [key: string]: string };
  location: any;
  isDeviceIos: boolean;
  isLocationLoading: boolean;
  myRefs: any;
  hovered: number;
  handleSelectedLocation: (selectedLocation: LocationModel) => void;
  currentSelectedLocation: LocationModel;
}

const SearchedLocationsCard: React.FC<ISearchedLocationsCardProps> = ({
  styleClasses,
  location,
  isDeviceIos,
  isLocationLoading,
  myRefs,
  hovered,
  handleSelectedLocation,
  currentSelectedLocation,
}) => {
  let { mutateAsync: removeFavorite } = useRemoveFavoriteLocation();
  let { mutateAsync: addFavorite } = useAddFavoriteLocation();

  const [favoriteLocations, setFavoriteLocations] = useState([]);
  const { user: authInfo } = useAppSelector((state) => state.user);

  const selectedLocation: any = JSON.parse(
    localStorage.getItem('SelectedLocation'),
  );

  useEffect(() => {
    const favoriteLocations = [];
    location.map((currentLocation) => {
      if (currentLocation.is_favorite) {
        favoriteLocations.push(currentLocation?.id);
      }
    });
    setFavoriteLocations(favoriteLocations);
  }, [location]);

  const removeFavoriteItem = (favoriteItemId: any, item: any) => {
    const userId = authInfo.id;
    removeFavorite(
      { locationId: favoriteItemId, userId },
      {
        onError: (error, variables, context) => {
          setFavoriteLocations([
            ...favoriteLocations,
            { ...item, item_id: item.id },
          ]);
        },
      },
    );
  };

  const addFavoriteLocation = (location: any) => {
    addFavorite(
      { customer_id: authInfo.id, location_id: location?.id },
      {
        onError: (error, variables, context) => {
          const favoritesCopy = [...favoriteLocations];
          const favoriteLocationId = favoriteLocations.findIndex(
            (fav) => fav === location?.id,
          );
          favoritesCopy.splice(favoriteLocationId, 1);
          setFavoriteLocations(favoritesCopy);
        },
      },
    );
  };

  const favoriteButtonHandler = (
    e: React.MouseEvent<HTMLButtonElement>,
    location,
  ) => {
    try {
      const favoriteLocationId = favoriteLocations.findIndex(
        (fav) => fav === location?.id,
      );
      const favoritesCopy = [...favoriteLocations];
      const locationId = location.id;
      if (favoriteLocationId === -1) {
        favoritesCopy.push(location?.id);
        setFavoriteLocations(favoritesCopy);
        addFavoriteLocation(location);
      } else {
        favoritesCopy.splice(favoriteLocationId, 1);
        setFavoriteLocations(favoritesCopy);
        removeFavoriteItem(locationId, location);
      }
      e.stopPropagation();
    } catch (error) {
      console.log(error);
    }
  };

  const isThisActiveLocation = (location: LocationModel) => {
    if (currentSelectedLocation) {
      return location?.id === currentSelectedLocation?.id;
    } else {
      return location?.id === selectedLocation?.id;
    }
  };

  return (
    <Col
      md="12"
      lg="12"
      xxl="12"
      className={`${styleclasses.card_map_scroll} p-0`}
      id="scroll2"
    >
      {isLocationLoading ? (
        <div className="text-center">
          <Spinner />
        </div>
      ) : (
        location?.map((location) => {
          return (
            <div
              ref={(el) => (myRefs.current[location.id] = el)}
              key={location.id}
            >
              {isThisActiveLocation(location) ? (
                <ActiveLocation
                  styleClasses={styleClasses}
                  location={location}
                  isDeviceIos={isDeviceIos}
                  hovered={hovered}
                  favoriteButtonHandler={favoriteButtonHandler}
                  favoriteLocations={favoriteLocations}
                  handleSelectedLocation={handleSelectedLocation}
                  isGuest={authInfo.type === GUEST_USER}
                />
              ) : (
                <div className="p-1">
                  <InActiveLocation
                    styleClasses={styleClasses}
                    location={location}
                    isDeviceIos={isDeviceIos}
                    hovered={hovered}
                    favoriteButtonHandler={favoriteButtonHandler}
                    favoriteLocations={favoriteLocations}
                    handleSelectedLocation={handleSelectedLocation}
                    isGuest={authInfo.type === GUEST_USER}
                  />
                </div>
              )}
            </div>
          );
        })
      )}
    </Col>
  );
};

export default SearchedLocationsCard;
