import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import Button from 'src/components/Button/Button';
import InputCheckbox from 'src/components/FormFields/InputCheckbox';
import { GUEST_USER } from 'src/constants';
import { bugSnagNotifyAPIError } from 'src/helper/bugSnagHelper';
import { getUserData } from 'src/helper/helperMethods';
import { Toast_Func } from 'src/helper/toast.helper';
import useAddPaymentMethod from 'src/hooks/useAddPaymentMethod';
import useProfile from 'src/react-query-hooks/useProfile';
import { useAppSelector } from 'src/redux/store/store';
import { zipCodeSchema } from 'src/validationSchemas/paymentRecordSchema';

interface ICheckoutForm {
  handleCheckoutNewPaymentSuccess?: (data, is_saved) => void;
  handleCheckoutNewPaymentFailure?: () => void;
  isCheckout: boolean;
  closeProfileModal?: () => void;
  handlePaymentForAddPaymentUser?: any;
}

const CheckoutForm = (props: ICheckoutForm) => {
  const {
    handleCheckoutNewPaymentSuccess,
    handleCheckoutNewPaymentFailure,
    isCheckout,
    closeProfileModal,
    handlePaymentForAddPaymentUser,
  } = props;
  const stripe = useStripe();
  const elements = useElements();

  const authInfo = handlePaymentForAddPaymentUser
    ? useAppSelector((state) => state.addPayment.addPaymentUser)
    : useAppSelector((state) => state.user.user);

  let { mutateAsync: addPayment } = useAddPaymentMethod();
  const { data: userProfileData } = useProfile(authInfo.id, authInfo.type);
  const userInfo = getUserData(authInfo, userProfileData);
  let queryClient = useQueryClient();

  const [paymentMethodName, setPaymentMethodName] = useState<string>('');
  const [paymentMethodNameError, setPaymentMethodNameError] =
    useState<string>(null);
  const [zipCode, setZipCode] = useState<string>('');
  const [zipCodeError, setZipCodeError] = useState<string>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [stripeError, setStripeError] = useState<any>(null);
  const [isDefault, setIsDefault] = useState(false);
  const [isSaveCard, setIsSaveCard] = useState(
    handlePaymentForAddPaymentUser ? true : false,
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStripeError(null);
    setPaymentMethodNameError(null);
    setZipCodeError(null);
    if (isSaveCard && paymentMethodName.trim() == '') {
      setPaymentMethodNameError('Payment Method required.');
      return;
    }
    setLoading(true);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardNumberElement),
      billing_details: {
        name: paymentMethodName,
      },
    });

    if (error) {
      setStripeError(error);
      setLoading(false);
      return;
    }
    try {
      zipCodeSchema.validateSync(zipCode);
    } catch (error) {
      setZipCodeError(error.message);
      setLoading(false);
      return;
    }

    const data = {
      customer: {
        name: userInfo?.full_name,
        email: userInfo?.email,
        id: userInfo.id,
      },
      card: {
        id: paymentMethod?.id,
        card_ending_num: paymentMethod?.card?.last4,
        exp_year: paymentMethod?.card?.exp_year,
        exp_month: paymentMethod?.card?.exp_month,
      },
      name: paymentMethodName,
      postal_code: zipCode,
      is_saved: isSaveCard,
      is_default: isDefault,
    };

    await addPayment(
      { newPaymentMethod: data },
      {
        onSuccess: (data) => {
          if (isCheckout) {
            handleCheckoutNewPaymentSuccess(data, isSaveCard);
          } else {
            queryClient.invalidateQueries('paymentMethod');
            closeProfileModal();
          }
          setLoading(false);
        },
        onError: (error: any) => {
          bugSnagNotifyAPIError("Add Payments", data, error?.response?.data.message);
          Toast_Func({ status: false, message: error?.response?.data?.message || 'Something went wrong' });
          setLoading(false);
        },
      },
    );
  };
  const options = {
    style: {
      base: {
        fontSize: '16px',
        color: '#000',
        letterSpacing: '0.025em',
        lineHeight: '30px',
        fontFamily: 'Source Code Pro, monospace',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#9e2146',
      },
    },
  };
  return (
    <form
      onSubmit={handleSubmit}
      className="new_form_design my-0 mx-auto delivery_location_select full_width_mob"
    >
      {authInfo.type != GUEST_USER ? (
        <div className="form-group">
          <label className="d-flex justify-content-between align-items-center">
            Name Payment Method
          </label>
          <input
            name="name"
            type="text"
            className="form-control no-style"
            onChange={(e) => {
              setPaymentMethodName(e.target.value);
            }}
            value={paymentMethodName}
          />
          {paymentMethodNameError ? (
            <span className={'clr-dark-red f-s11'}>
              {paymentMethodNameError}
            </span>
          ) : null}
        </div>
      ) : null}

      <div className="c">
        <label>Card number </label>
        <CardNumberElement options={options} className="form-control" />
        {stripeError && stripeError?.code.includes('number') ? (
          <span className={'clr-dark-red f-s11'}>{stripeError?.message}</span>
        ) : null}
      </div>

      <div className="credentials row py-3">
        <div className="col-md-6 col-sm-12 pb-3 pb-md-0 form-group">
          <label>CVC</label>
          <CardCvcElement options={options} className="form-control" />
          {stripeError && stripeError?.code.includes('cvc') ? (
            <span className={'clr-dark-red f-s11'}>{stripeError?.message}</span>
          ) : null}
        </div>

        <div className="col-md-6 col-sm-12 form-group">
          <label>Expiration date</label>
          <CardExpiryElement options={options} className="form-control" />
          {stripeError && stripeError?.code.includes('expiry') ? (
            <span className={'clr-dark-red f-s11'}>{stripeError?.message}</span>
          ) : null}
        </div>
      </div>

      <div className="form-group mb-3">
        <label className="d-flex justify-content-between align-items-center">
          ZIP CODE
        </label>
        <input
          type="text"
          className="form-control no-style"
          placeholder="Enter Zip Code"
          onChange={(e) => {
            setZipCode(e.target.value);
          }}
          value={zipCode}
        />
        {zipCodeError ? (
          <span className={'clr-dark-red f-s11'}>{zipCodeError}</span>
        ) : null}
      </div>

      {isCheckout &&
      authInfo.type != GUEST_USER &&
      !handlePaymentForAddPaymentUser ? (
        <>
          <InputCheckbox
            id="save_carda"
            name="save_carda"
            onChange={(e) => {
              setIsSaveCard((state) => !state);
            }}
            checked={isSaveCard}
            labelText="Save card information to my account"
          />
          {isSaveCard && (
            <InputCheckbox
              id="default_payment"
              name="is_default"
              onChange={(e) => {
                setIsDefault((state) => !state);
              }}
              checked={isDefault}
              labelText="Make this my default payment method"
            />
          )}
        </>
      ) : null}

      <div className="d-flex justify-content-center mt-4">
        <Button
          type="submit"
          className=" btn-large py-2 f-s16"
          disabled={!stripe || loading}
        >
          {/* { loading ? "Add Payment Method" : <BasicSpinner/>} */}
          Add Payment Method
        </Button>
      </div>
    </form>
  );
};
export default CheckoutForm;
