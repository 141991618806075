import { Field, Formik } from "formik";
import React, { useEffect, useState } from "react";
import {IUserProfileFields, IUserStatusData} from "../../models/customer.modal";
import {contactInfoFormSchema} from "../../validationSchemas/contactInfoFormSchema";
import FormField from '../../components/FormFields/FormField';
import {getUser, getVisitorId} from "../../helper/helperMethods";
import {CURRENT_USER, ID_FIELD, LOCATION_FIELD, PHONE_FIELD} from "../../constants";
import useUpdateProfile from "../../react-query-hooks/useUpdateProfile";
import {Toast_Func} from "../../helper/toast.helper";
import {API_ERROR_MESSAGE} from "../../models/error.model";
import { useQueryClient } from "react-query";
import {PHONE_NUMBER_MASK} from "../../constants";
import MaskedInput from 'react-text-mask'
import Button from "../../components/Button/Button";


interface IDynamicContactInfoForm {
    userStatusData: IUserStatusData
    handleContentChange?: () => void;
    closeModal: () => void;
    isSignInFlow?: boolean
}

const DynamicContactInfoForm: React.FC<IDynamicContactInfoForm> = ({userStatusData, handleContentChange, closeModal, isSignInFlow= false}) => {
    const userProfileData = userStatusData?.user
    const { mutateAsync } = useUpdateProfile();
    const queryClient = useQueryClient()
    const keys = Object.keys(userProfileData)

    const initialFormState: IUserProfileFields = {
        first_name: userProfileData?.first_name || "",
        last_name:  userProfileData?.last_name || "",
        email:  userProfileData?.email || "",
        phone:  userProfileData?.phone || "",
        locations: userProfileData?.locations || []
    };

    const locations = userStatusData?.allLocations?.map((market) => ({ value: market.name, label: market.name, id: market.id }));

    const isTextType = (key: string) => {
        return key !== PHONE_FIELD && key !== LOCATION_FIELD && key !== ID_FIELD
    }

    const labelText = (key:  string) => {
        return key.replace("_", " ")
    }

    const handleFormSubmission = async (
        values: IUserProfileFields,
        { setSubmitting, setFieldError  }: any
    ) => {
        if(!values?.locations?.length) {
            setFieldError('locations', "Favorite location is required")
            return
        }
        let favoriteLocations = values.locations?.map((loc) => loc.id);
        let user = getUser()
        let updatedValues = {
            ...values,
            id: user.id,
            location: favoriteLocations
        }
        return mutateAsync(
            {
                editedProfile: updatedValues,
                authentication_token: "",
            },
            {
                onSuccess: (data: any) => {
                    queryClient.invalidateQueries("profile");
                    user.full_name = values.first_name.concat(' ',values.last_name)
                    localStorage.setItem(
                        CURRENT_USER,
                        JSON.stringify({ ...user, visitor_id: getVisitorId(), ...values  })
                    );
                    handleContentChange();
                    Toast_Func({
                        status: true,
                        message: "Your  Information has been successfully updated in profile.",
                    });
                    closeModal();
                },
                onError: (error, variables, context) => {
                    const {
                        data: {
                            message,
                        },
                    } = (error as API_ERROR_MESSAGE).response;
                    Toast_Func({ status: false, message });
                },
            }
        );
    };
    return(
        <div>
            <Formik
                initialValues={initialFormState}
                validationSchema={contactInfoFormSchema}
                onSubmit={handleFormSubmission}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      setFieldValue,
                      handleSubmit,
                      isSubmitting,
                      submitForm,
                  }) => (
                    <form className="new_form_design  w-75 my-0 mx-auto  full_width_mob" onSubmit={handleSubmit}>
                        {
                            keys.map((key) => {
                                    return(
                                        <>
                                            {
                                                !userProfileData[`${key}`]?.length && isTextType(key) &&
                                                <FormField
                                                    labelText={`${key?.toUpperCase()?.replace('_', ' ')}`}
                                                    type={"text"}
                                                    name={`${key}`}
                                                    errors={errors}
                                                    touched={touched}
                                                    onChange={handleChange}
                                                    value={values[`${key}`]}
                                                />
                                            }
                                            {
                                                key === PHONE_FIELD && !userProfileData[`${key}`]?.length &&
                                                <div className="form-group">
                                                    <label htmlFor="phone">
                                                        Phone Number
                                                    </label>
                                                    <Field
                                                        name="phone"
                                                        render={({ field }) => (
                                                            <MaskedInput
                                                                {...field}
                                                                mask={PHONE_NUMBER_MASK}
                                                                id="phone"
                                                                placeholder="(223) 123-1234"
                                                                type="text"
                                                                value={values.phone}
                                                                onChange={(e) => setFieldValue('phone', e.target.value.replace(/[() -]/g,''))}
                                                                //setFieldValue('phone',value.replace(/[() -]/g,''))}
                                                                guide={true}
                                                                className={`${errors.phone && touched.phone ? "input_error" : "text-input"} form-control`}
                                                            />
                                                        )}
                                                    />
                                                    {errors.phone && touched.phone && (
                                                        <div className="input-feedback clr-dark-red f-s14 font-rale mt-1 d-inline-block">{errors.phone}</div>
                                                    )}
                                                </div>
                                            }
                                            {
                                                key === LOCATION_FIELD && !userProfileData[`${key}`]?.length &&
                                                <div className="form-group custom_select">
                                                    <FormField
                                                        options={locations}
                                                        labelText={"favorite location"}
                                                        name="locations"
                                                        type={"multiselect"}
                                                        placeholder={"Make a selection"}
                                                        errors={errors}
                                                        touched={touched}
                                                        isSearchable={true}
                                                        value={values.locations}
                                                        onChange={(location) => {
                                                            setFieldValue("locations", location);
                                                        }}
                                                        inputFieldClass={"mt-0"}
                                                    />
                                                </div>
                                            }
                                        </>
                                    )
                                }
                            )
                        }
                        <div className="d-flex justify-content-center">
                            <Button
                                type="submit"
                                className="btn btn-large py-2 f-s16 mb-4 mt-3 text-uppercase"
                                disabled={isSubmitting}
                                loading={isSubmitting}
                                onClick={submitForm}

                            >
                                {isSignInFlow ? "START ORDERING" : "CONFIRM CONTACT INFO"}
                            </Button>
                        </div>
                    </form>
                )}
            </Formik>
        </div>
    )
};
export default DynamicContactInfoForm;