import { Col } from 'react-bootstrap';
import styles from '../itemDetails.module.scss';

const ItemDetailImage = ({ path }) => {
  return (
    <Col lg="7" sm="12">
      <div className={styles.item_detail_img}>
        <img src={path} alt="varietySoup" className="w-100" />
      </div>
    </Col>
  );
};

export default ItemDetailImage;
