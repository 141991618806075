import { createSlice } from '@reduxjs/toolkit';
import { SELECTED_STORE } from 'src/constants';

const selectedStore = localStorage.getItem(SELECTED_STORE)
  ? JSON.parse(localStorage.getItem(SELECTED_STORE))
  : '';

export const initialState = {
  selectedStore: '' || selectedStore,
  error: '',
};
export const locationSlice = createSlice({
  name: 'location',
  initialState: initialState,
  reducers: {
    setLocation: (state, action) => {
      state.selectedStore = action.payload;
    },
    setLocationError: (state, action) => {
      state.selectedStore = null;
      state.error = action.payload;
    },
  },
});

export const { setLocation, setLocationError } = locationSlice.actions;

export const locationSliceReducer = locationSlice.reducer;
