import { FC } from 'react';
import { NavLink } from 'react-bootstrap';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import RewardIcon from 'src/assets/images/Svgs/reward.svg';
import * as CONSTANT from 'src/constants';
import { logout } from 'src/Features/AccountSetup/redux/actions';
import useProfile from 'src/react-query-hooks/useProfile';

import styles from './NavbarComponent.module.scss';

interface IUserNameProps {
  userId?: string | number;
  userType?: string;
  expandHandler?: () => void;
}

const UserNavComponent: FC<IUserNameProps> = ({
  userId,
  userType,
  expandHandler,
}) => {
  const queryClient = useQueryClient();
  const { data: userProfileData } = useProfile(userId, userType);
  const dispatch = useDispatch();
  const history = useHistory();

  const logoutHandler = () => {
    logout(dispatch);
    queryClient.removeQueries();
    history.push('/');
  };

  return (
    <div className="text-center px-4">
      <h6 className="f-s18 text-start lh-normal ls-normal font-Cls f-bold mt-5 ">{`Welcome, ${
        userProfileData && userProfileData?.first_name
      }`}</h6>

      <ul
        className={`${styles.sub_menu_list} ${styles.user_menu_listing} list-inline mb-1 ps-0`}
      >
        <li>
          <Link
            to={CONSTANT.ROUTE_PROFILE_DETAILS}
            className={styles.sub_menu_item}
            onClick={expandHandler}
          >
            <div className="d-flex">
              <img
                src={`${CONSTANT.s3BaseUrl}/images/icons/UserCircle.svg`}
                className="img-fluid me-1 "
                alt="icon"
              />
              Profile & Preferences
            </div>
          </Link>
        </li>
        <>
          <li>
            <Link
              to={CONSTANT.ROUTE_LOYALTY}
              className={styles.sub_menu_item}
              onClick={expandHandler}
            >
              <div className="d-flex">
                <img src={RewardIcon} className="img-fluid me-1 " alt="icon" />
                Loyalty & Rewards
              </div>
            </Link>
          </li>
          {/* <li>
            <NavLink href="/" className={styles.sub_menu_item}>
              Rewards Policy
            </NavLink>
          </li> */}
        </>
      </ul>
      <button
        type="button"
        className="btn-large dark-grey f-s14 lh-normal mt-5 mb-4"
        onClick={() => {
          logoutHandler();
          expandHandler();
        }}
      >
        SIGN OUT
      </button>
      <p className="f-s14 pt-8 mb-5 d-flex justify-content-center gap-1">
        <NavLink
          href="/"
          className={`${styles.login_btn} font-Vcf  text-decoration-none`}
        >
          Terms and Conditions{' '}
        </NavLink>{' '}
        and
        <NavLink
          href="/"
          className={`${styles.login_btn} font-Vcf  text-decoration-none`}
        >
          Privacy Policy
        </NavLink>
      </p>
    </div>
  );
};

export default UserNavComponent;
