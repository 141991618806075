import { useMutation } from 'react-query';
import { ItemFields } from '../../models/Menu.model';
import { customerBusinessService } from '../../services';

interface IPayload {
  location_id: string;
  customer_id: string;
}

const addFavorite = async (payload) => {
  const data = {
    customer_id: payload.customer_id,
    locations: [payload.location_id],
  };
  const response = await customerBusinessService.locationAddToFavorites(data);
  return response.data.data;
};

export default function useAddFavoritesLocation() {
  return useMutation((payload: IPayload) => addFavorite(payload));
}
