import React from 'react';
import { useSelector } from 'react-redux';
import RequiredModifiers from 'src/containers/Modifiers/ItemRequiredModifiers';
import { bucketKeyForNonRequiredTray } from 'src/helper/helperMethods';
import {
  IItemsRequiredModifierGroups,
  ISubCategories,
  ItemDetails,
} from 'src/models/item.model';

type propTypes = {
  trays: ISubCategories[];
  handleIngredientSelection: (data, id) => void;
  bucketkey: any;
  isBoxLunch: boolean;
  editCartItem?: any;
  manualSelectedItem: ItemDetails;
  traysItemRequiredModifierGroup: IItemsRequiredModifierGroups[];
  subItemRequiredModifiersAtItemDetailSectionToHandleScroll: {
    refToScrollToMissingRequiredModifiers: any[];
    isRequiredModifierSelected: {
      status: boolean;
      highLightArea: number;
    };
    setIsRequiredModifierSelected: React.Dispatch<
      React.SetStateAction<{ status: boolean; highLightArea: any }>
    >;
  };
  requiredModifiersForDynamicSelectedItemAtItemDetailSectionToHandleScroll: {
    refToScrollToMissingRequiredModifiers: any[];
    isRequiredModifierSelected: {
      status: boolean;
      highLightArea: number;
    };
    setIsRequiredModifierSelected: React.Dispatch<
      React.SetStateAction<{ status: boolean; highLightArea: any }>
    >;
  };
};

const PackagesItemRequiredModifiers: React.FC<propTypes> = (props) => {
  const {
    trays,
    handleIngredientSelection,
    bucketkey,
    isBoxLunch,
    editCartItem,
    manualSelectedItem,
    traysItemRequiredModifierGroup,
    subItemRequiredModifiersAtItemDetailSectionToHandleScroll,
    requiredModifiersForDynamicSelectedItemAtItemDetailSectionToHandleScroll,
  } = props;

  const order = useSelector((state: any) => state.itemCustomization);

  const showRedBorderAnimation = (index, refStates) => {
    return refStates
      ? !refStates.isRequiredModifierSelected.status &&
          refStates.isRequiredModifierSelected.highLightArea === index
      : false;
  };

  const traysRequiredModifiersListing = (
    traysItemRequiredModifierGroup,
    useTrayIdToFindIndexInRedux = false,
    refStates,
  ) => {
    return traysItemRequiredModifierGroup?.map(
      (group: IItemsRequiredModifierGroups, index: number) => (
        <div key={group.id} className="mt-4">
          <div
            className={`pt-3 pb-2`}
            ref={
              refStates
                ? refStates.refToScrollToMissingRequiredModifiers[index]
                : null
            }
          >
            <h5 className="d-flex align-items-center justify-content-between f-sm-s18 f-s20 mb-0 lh-normal font-Cls text-capitalize">
              Choose Your {group.label || group.name}
            </h5>
          </div>
          <div className={`select_size_box_dressing`}>
            <RequiredModifiers
              requiredModifierGroup={group}
              handleIngredientSelection={handleIngredientSelection}
              bucketkey={
                useTrayIdToFindIndexInRedux
                  ? bucketKeyForNonRequiredTray(order, group.trayId)
                  : bucketkey
              }
              isBoxLunch={isBoxLunch}
              editCartItem={editCartItem}
              showRedBorderAnimation={showRedBorderAnimation(index, refStates)}
            />
          </div>
        </div>
      ),
    );
  };

  return (
    <React.Fragment>
      {traysRequiredModifiersListing(
        manualSelectedItem?.items_required_modifier_groups,
        false,
        requiredModifiersForDynamicSelectedItemAtItemDetailSectionToHandleScroll,
      )}
      {traysRequiredModifiersListing(
        traysItemRequiredModifierGroup,
        true,
        subItemRequiredModifiersAtItemDetailSectionToHandleScroll,
      )}
    </React.Fragment>
  );
};
export default PackagesItemRequiredModifiers;
