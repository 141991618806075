import React from 'react';

import { persistZero } from '../../../helper/helperMethods';

interface IItemPrice {
  price: number;
}

const ItemPrice: React.FC<IItemPrice> = (props) => {
  const { price } = props;
  return (
    <div className="text-center">
      <span className="d-inline-block f-w5 font-Vcf f-s16 lh-normal">
        <>
          <span className="d-inline-block">
            <span className="clr-text-grey f-s14 font-Visby-cf d-block text-center mb-1">
              Total
            </span>
            <span>{['$', persistZero(price)].join('')}</span>
          </span>
        </>
      </span>
    </div>
  );
};

export default ItemPrice;
