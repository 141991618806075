import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { EditPencilIcon } from 'src/assets/images/Svgs/svg';
import { s3BaseUrl } from 'src/constants';
import useCheckMobileScreen from 'src/hooks/useCheckMobileScreen';
import styleClasses from '../order.module.scss';
import ChangeLocation from './ChangeLocation';

interface IStoreLocationProps {
  StoreLocationHide?: any;
  StoreLocationCenter?: any;
  StoreLocationShrink?: any;
  location?: {
    id: null | number;
    name: string;
    address: string;
    city_state_zip: string;
    phone: string;
  };
  titleStyle?: string;
  isDeliveryOrder: boolean;
  isChangeAble?: boolean;
  img?: boolean;
  name?: string;
  address?: string;
}
const StoreLocation = (props: IStoreLocationProps) => {
  const {
    StoreLocationHide,
    StoreLocationCenter,
    StoreLocationShrink,
    location,
    isDeliveryOrder,
    isChangeAble = true,
    img = true,
    name = false,
    address = false,
    titleStyle,
  } = props;
  const [openChangeLocationModal, setOpenChangeLocationModal] = useState(false);
  const [isDeviceIos, setIsDeviceIos] = React.useState(false);
  const history = useHistory();
  const isItMobile = useCheckMobileScreen();
  const openModal = () => {
    setOpenChangeLocationModal(true);
  };

  const closeModal = () => {
    setOpenChangeLocationModal(false);
  };

  useEffect(() => {
    var userAgent = navigator?.userAgent || navigator?.vendor;
    if (/iPad|iPhone|iPod|Mac/.test(userAgent) && !window?.MSStream) {
      setIsDeviceIos(true);
    } else {
      setIsDeviceIos(false);
    }
  }, []);

  return (
    <>
      <div className="pb-md-0 pb-4 ">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center justify-content-between w-100">
            <h2
              className={`f-s20 mb-0 text-start h-22 text-uppercase font-Cls fw-normal f-sm-s16 f-w5 mb-2  ${StoreLocationHide} ${titleStyle}`}
            >
              {isDeliveryOrder ? 'Delivered From' : 'Store Location'}
            </h2>
          </div>
        </div>
        <div
          className={`${styleClasses.location_wrapper} ${StoreLocationCenter} d-flex mt-md-3 pb-2 align-items-start`}
        >
          {img && (
            <div
              className={`${styleClasses.ser_user_img_wrap} me-3 d-none d-md-block`}
            >
              <img
                className={styleClasses.location_image}
                src={
                  isDeviceIos
                    ? `${s3BaseUrl}/Locations/` +
                      location?.id +
                      '/location-' +
                      location?.id +
                      '.jpeg'
                    : `${s3BaseUrl}/Locations/` +
                      location?.id +
                      '/location-' +
                      location?.id +
                      '.webp'
                }
                alt="image"
              />
            </div>
          )}
          <div
            className={`${styleClasses.location_info_box} flex-fill ${StoreLocationShrink}`}
          >
            {isItMobile ? (
              <p className=" text-start f-s4 font-Visby-cf  mb-0">
                {name ? name : location?.name}
              </p>
            ) : (
              <h3 className="f-s22 text-start h-22 text-uppercase font-Visby-cf f-w6 mb-md-1 f-sm-s14 f-sm-w5 mb-0">
                {name ? name : location?.name}
              </h3>
            )}
            <p
              className={`${styleClasses.location_address} mb-0 text-style2 text-start font-rale f-sm-w5 f-s18 f-sm-s14 `}
            >
              {address ? address : location?.address}
            </p>
            <p
              className={`${styleClasses.location_address} mb-0 text-style2 text-start font-rale f-sm-w5 f-s18 f-sm-s14`}
            >
              {location?.city_state_zip}
            </p>
          </div>
          {isChangeAble && (
            <button
              type="button"
              className={`${StoreLocationHide} text-decoration-underline btn  f-s16 text_underline clr-text-grey ms-3 p-0 f-w6 `}
              onClick={openModal}
              disabled={false}
            >
              {isItMobile ? <EditPencilIcon /> : 'Change'}
            </button>
          )}
        </div>
        <hr className="custom_hr_sty   d-none d-md-block" />
      </div>
      {/* change location modal */}
      <Modal
        show={openChangeLocationModal}
        centered
        size="xl"
        className="chose-item-modal edit-location-store theme-modal-style app_container_wrap no-padding border-radius-15 border-radius-sm-8"
        onHide={closeModal}
      >
        <Modal.Header className="x-4 justify-content-center">
          <button
            type="button"
            className="btn modal-close-button pe-0"
            onClick={closeModal}
          >
            Close
          </button>
          <h5 className="f-s22 font-Cls text-md-start text-center fw-normal pt-5 pb-0 text-capitalize w-100 mb-0 border-bottom">
            Edit Store Location
          </h5>
        </Modal.Header>
        <Modal.Body>
          <ChangeLocation
            location={location}
            history={history}
            closeModal={closeModal}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default StoreLocation;
