import React from 'react';

import { awsAssetsFetcher } from 'src/utils/awsAssetsFetcher';
import { selectedBtnStyle } from '../../../components/Molecules/Card/cardStyle';
import { INoDressing, INoDressingModifier } from '../../../models/item.model';
import styleClasses from '../try2.module.scss';

const NoDressing: React.FC<INoDressing> = (props) => {
  const {
    label = '',
    groupId = 0,
    itemNo,
    noDressingHandler,
    isNoDressing,
    noDressingCardBorderRadius,
    showRedBorderAnimation,
  } = props;
  const noDressingClickHandler = () => {
    const payload: INoDressingModifier = {
      modifier_id: 0,
      modifier_name: `No ${label}`,
      display_price: 0,
      modifier_calories: '0',
      type: 'selected',
      modifier_group_id: groupId,
      modifier_group_max: 0,
      code: 'NO',
      modifier_type: 'TRY_2_COMBO_REQUIRED_MODIFIERS',
      in_slide: true,
      quantity: isNoDressing ? 0 : 1,
      additionalPrice: 0,
      groupId: groupId,
    };
    noDressingHandler(payload, itemNo);
  };
  return (
    <div
      className={`${styleClasses.add_on_item_wrap} ${
        styleClasses.no_dressing_card
      } ${noDressingCardBorderRadius} cursor-pointer shadow-box d-flex flex-column ${
        isNoDressing && styleClasses.selected
      } ${isNoDressing && styleClasses.selected} ${
        showRedBorderAnimation ? 'border-size-select' : ''
      }`}
      onClick={noDressingClickHandler}
    >
      <div
        className={`mb-2 flex-fill d-flex align-items-center justify-content-center`}
      >
        <img
          src={awsAssetsFetcher('static/Prohibit', 'svg')}
          className={`img-fluid no-dressing-img ${styleClasses.noDressingImg}`}
          alt="ingredient"
        />
      </div>
      <h3 className={`${styleClasses.item_title} font-rale mb-3`}>
        {`No ${label}`}
      </h3>
      <div className={`${styleClasses.buttons_wraper}`}>
        <button
          type="button"
          className={`${styleClasses.item_select_btn} item-select-btn mx-auto mw-auto`}
          style={{
            ...(isNoDressing && {
              ...selectedBtnStyle,
            }),
          }}
          onClick={noDressingClickHandler}
        >
          {isNoDressing ? 'Selected' : 'Select'}
        </button>
      </div>
    </div>
  );
};

export default NoDressing;
