import { QRCodeCanvas } from 'qrcode.react';
import React from 'react';
import { Modal } from 'react-bootstrap';
import useCheckMobileScreen from 'src/hooks/useCheckMobileScreen';

interface ModalProps {
  showModal: boolean;
  closeModal: () => void;
  edit: boolean;
  item: any;
}
export const DownloadAppModal: React.FunctionComponent<ModalProps> = (
  props,
) => {
  const { showModal, closeModal, item, edit } = props;
  const isItMobile = useCheckMobileScreen();

  const links = {
    191: 'https://cafezupasprod.app.link/qiXAfQNYBHb',
    192: 'https://cafezupasprod.app.link/vVgBAQNYBHb',
    193: 'https://cafezupasprod.app.link/wfYZWQNYBHb',
    194: 'https://cafezupasprod.app.link/55GigRNYBHb',
  };

  return (
    <Modal
      show={showModal}
      centered
      className="theme-modal-style app_container_wrap xs-modal border-radius-15 align-sm-bottom"
      onHide={closeModal}
    >
      <Modal.Header className="pt-0">
        <button
          type="button"
          className="btn modal-close-button pe-0"
          onClick={closeModal}
        >
          Close
        </button>
      </Modal.Header>
      <Modal.Body className="px-md-0 px-5">
        <div>
          <p className="font-Visby-cf f-s14 text-center text-uppercase lh-base clr-dar-gray">
            <span className="f-w8">App Exclusive</span>
          </p>
          <hr className="mt-3 mb-0" />
          <p className="font-Visby-cf f-s14 text-center lh-base clr-dar-gray cz-mb-32 mt-3">
            {edit
              ? isItMobile
                ? 'You can only edit this item in the Cafe Zupas Catering App. Download now by scanning the QR code or clicking the button below!'
                : 'You can only edit this item in the Cafe Zupas Catering App. Download now by scanning the QR code below!'
              : 'Download the Cafe Zupas Catering App now to order our new Packages!'}
          </p>
          <div className="text-center cz-mt-10 cz-mb-40">
            <QRCodeCanvas
              value={'https://cafezupas-catering.app.link/TIVYc9ZjNu'}
              size={200}
            />
          </div>
        </div>
        {isItMobile && (
          <div className="d-flex justify-content-center">
            <a
              className="btn-large mb-4"
              href="https://cafezupas-catering.app.link/TIVYc9ZjNu"
              target={'_blank'}
            >
              Download the app
            </a>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};
