import { Dropdown } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import {
  CateringUser,
  IndividualUser,
  UserCircle,
  UserCircleIcon,
} from 'src/assets/images/Svgs/svg';
import { ROUTE_CREATE_ACCOUNT, ROUTE_LOGIN } from 'src/constants';

import styles from './signUpBtn.module.scss';

const SignUpButton = () => {
  const history = useHistory();

  const LoginHandleClick = () => {
    history.push(ROUTE_LOGIN);
  };

  const createAccountHandleClick = () => {
    history.push(ROUTE_CREATE_ACCOUNT);
  };

  return (
    <div className={`d-none d-md-block ${styles.signInWrapper}`}>
      <div className="d-flex align-items-center flex-nowrap">
        <UserCircleIcon />
        <div className="d-flex flex-column align-items-start">
          <a
            onClick={LoginHandleClick}
            className="clr-dark-grey f-w6 f-s16 text-decoration-none cz-lh-15"
          >
            {' '}
            SIGN-IN{' '}
          </a>
          <a
            onClick={createAccountHandleClick}
            className="clr-dark-grey f-w6 f-s10 text-decoration-none"
          >
            {' '}
            OR CREATE AN ACCOUNT{' '}
          </a>{' '}
        </div>
      </div>
    </div>
  );
};

export default SignUpButton;
