import { useQuery } from 'react-query';

import { customerBusinessService } from '../../services';
import { QUERY_KEY_USER_POINTS_EARNED } from 'src/constants';

const getUserPointsEarned = async () => {
  const response = await customerBusinessService.getUserPointsEarned();
  return response.data.data;
};

export default function useGetUserPointsEarned(isUser) {
  return useQuery<any>([QUERY_KEY_USER_POINTS_EARNED], () =>
    getUserPointsEarned(),{enabled:isUser}
  );
}
