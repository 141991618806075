import { Modal } from 'react-bootstrap';
import Button from 'src/components/Button/Button';

const ServingTextModal = (props) => {
  const { showModal, closeModal, servingText, itemLabel } = props;
  return (
    <section>
      <Modal
        show={showModal}
        centered
        // className="theme-modal-style app_container_wrap xs-modal border-radius-15 align-sm-bottom added-modal-size"
        className={`theme-modal-style app_container_wrap xs-modal border-radius-15 align-sm-bottom`}
        onHide={closeModal}
      >
        <Modal.Header className="pt-0">
          <button
            type="button"
            className="btn modal-close-button pe-0"
            onClick={closeModal}
          >
            Close
          </button>
        </Modal.Header>
        <Modal.Body className="px-0 pb-5 pt-3">
          <h6 className="f-s26 text-center pt-3">{itemLabel}</h6>
          <p className="pt-3 text-center">{servingText}</p>
          <Button className="btn-large w-100 mt-3" onClick={closeModal}>
            Ok
          </Button>
        </Modal.Body>
      </Modal>
    </section>
  );
};
export default ServingTextModal;
