import React, { useEffect, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { useQueryClient } from "react-query";
import CustomModal from "src/components/customModal/CustomModal";
import ItemOrderModal from "src/components/ItemOrderModal/ItemOrderModal";
import { MAX_PROFILE_ADDRESS_COUNT } from "src/constants";
import { Toast_Func } from "src/helper/toast.helper";
import { API_ERROR } from "src/models/error.model";
import useProfile from "src/react-query-hooks/useProfile";
import { updateIsOrderTypeSelected, updateOrderDetails } from "src/redux/slices/cartSlice";
import { useAppDispatch, useAppSelector } from "src/redux/store/store";

interface IAddressConfirmationForUser {
    closeModal: () => void;
    addAddress: any;
    isAddingAddress: boolean;
    showAddressConfirmation: boolean;
    setAddressConfirmationModal: (showModal) => void;
}

const AddressConfirmationModal: React.FC<IAddressConfirmationForUser> = ({ closeModal, addAddress, isAddingAddress, showAddressConfirmation, setAddressConfirmationModal }) => {
    const dispatch = useAppDispatch()
    const queryClient = useQueryClient();
    const [changeAddress, setChangeAddress] = useState<boolean>(false);
    const { user: authInfo } = useAppSelector((state) => state.user);


    const {
        isFetching: isFetchingUserProfileData,
        data: userProfileData,
    } = useProfile(authInfo.userId, authInfo.type);

    const deliveryAddress = useAppSelector((state) => state.cart?.orderDetails?.delivery_address || "")


    useEffect(() => {
        if (
            !isFetchingUserProfileData &&
            !changeAddress &&
            userProfileData?.addresses?.length === MAX_PROFILE_ADDRESS_COUNT
        ) {
            setChangeAddress(true);
            dispatch(updateOrderDetails({ "delivery_address": "" }))
            dispatch(updateIsOrderTypeSelected(false));
        }
    }, [isFetchingUserProfileData]);


    const saveAddress = () => {
        let { address_name, street_name, city, zipcode, state } = deliveryAddress;
        address_name = address_name?.trim()
        let modifiedValues = {
            ...deliveryAddress,
            customer_id: authInfo.userId,
            full_address: `${street_name} ${city} ${zipcode} ${state}`,
            is_default: 0,
            ...(address_name ? { address_name } : { address_name: street_name }),
        };

        return addAddress(
            {
                deliveryAddress: modifiedValues,
                authentication_token: authInfo.authentication_token,
            },
            {
                onSuccess: (data, variables, context) => {
                    queryClient.invalidateQueries("profile");
                    const newAddress = {
                        value: data.address_name,
                        label: data.address_name,
                        id: data.id,
                        isDefault: data.is_default,
                        street: data.street_name,
                        ...data,
                    };
                    dispatch(updateOrderDetails({ "delivery_address": newAddress }))
                    Toast_Func({
                        status: true,
                        message: "Address successfully added",
                    });
                    closeModal();
                },
                onError: (error, variables, context) => {
                    const {
                        data: {
                            data: { errors },
                        },
                    } = (error as API_ERROR).response;
                    closeModal();
                    Toast_Func({ status: false, message: errors[0] });
                },
            }
        );
    }
    if (isFetchingUserProfileData)
        return <Spinner/>;

    return (
        <>
            <CustomModal
                showModal={showAddressConfirmation}
                title={''}
                closeModal={() => {
                    !isAddingAddress &&
                        setAddressConfirmationModal(
                            {
                                showCart: false,
                                showModal: false,
                            }
                        );
                    setChangeAddress(false);
                }}
                modalDialogClasses={'itemOrderModal'}
                modalBodyClass={'overflow-visible'}
            >
            {!changeAddress ? (
                <>
                    <h4 className="f-s22 font-Cls text-center fw-normal lh-22 mb-0 text-capitalize">Confirm delivery address</h4>
                    <div className="w-50 mx-auto">
                        <div className="justify-content-center mb-4 mt-2">
                            <span className="d-block f-s14 font-Visby-cf f-w5">
                                {deliveryAddress.address_name}
                            </span>
                            <span className="d-block f-s14 font-Visby-cf f-w5">
                                {deliveryAddress.street} {deliveryAddress?.apartment_no ? `, ${deliveryAddress?.apartment_no} ` : ''}
                            </span>
                            <span className="d-block f-s14 font-Visby-cf f-w5">
                                {deliveryAddress.city}, {deliveryAddress.state}{" "}
                                {deliveryAddress.zipcode}
                            </span>
                        </div>
                    </div>

                    <div className="justify-content-center app_container_wrap px-1 py-2 text-center">
                        <div className="text-center">
                            <button
                                type="button"
                                className="btn-large"
                                onClick={() => saveAddress()}
                                disabled={isAddingAddress}
                            >
                                Save Address and Proceed
                            </button>
                            <button
                                type="button"
                                    className="btn mt-3 p-0 f-w6 p-16 clr-dark-red text-center w-100 mb-5 font-Vcf"
                                onClick={() => {
                                    setChangeAddress(true)
                                    dispatch(updateOrderDetails({ "delivery_address": "" }))
                                    dispatch(updateIsOrderTypeSelected(false));
                                }}
                                disabled={isAddingAddress}
                            >
                                Change Address
                            </button>
                        </div>
                    </div>
                </>
            ) : (
                    <ItemOrderModal closeOpenOrderModal={closeModal} />
                )}
                </CustomModal>
        </>
    );
};

export default AddressConfirmationModal;
