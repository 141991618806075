import React from 'react';
import { MinusCircle, PlusCircle } from 'src/assets/images/Svgs/svg';
import styleClasses from '../../../../src/components/Molecules/Card/SelectCard/selectCard.module.scss';
import NotAvailable from 'src/components/Button/NotAvailable';
import { selectedBtnStyle } from 'src/components/Molecules/Card/cardStyle';

type ActionsTypes = {
  styles: any;
  count: number;
  modifierAction: {
    onAdded: () => void;
    onRemove: () => void;
  };
  isActive: boolean;
  limitCompleted: boolean;
};

const Actions: React.FC<ActionsTypes> = (props) => {
  const { styles, count, modifierAction, isActive, limitCompleted } = props;

  const onDecreaseButtonClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.stopPropagation();
    if (!count) return;
    modifierAction.onRemove();
  };

  const onIncreaseButtonClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.stopPropagation();
    if (limitCompleted) return;
    modifierAction.onAdded();
  };

  return isActive ? (
    <>
      <div className="text-start">
        <button
          type="button"
          className={`${styles.plus_minus_btns}`}
          data-testid="counter-card-dec"
          disabled={!count}
          onClick={onDecreaseButtonClick}
        >
          <MinusCircle />
        </button>
      </div>

      <span
        className={`${styles.counter_number} f-s18 font-rale f-w5`}
        data-testid="counter-card-count"
      >
        {count}
      </span>
      <div className="text-end">
        <button
          type="button"
          className={`${styles.plus_minus_btns}`}
          data-testid="counter-card-inc"
          onClick={onIncreaseButtonClick}
        >
          <PlusCircle />
        </button>
      </div>
    </>
  ) : (
    <NotAvailable selectedBtnStyle={selectedBtnStyle} />
  );
};

export default Actions;
