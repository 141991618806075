import React from 'react';

interface IActionButtons {
  onEdit: () => void;
  onRemove: () => void;
  isFree?: boolean;
  notEditable?: boolean;
  isCombo?: boolean;
}

const ActionButtons: React.FC<IActionButtons> = (props) => {
  const { onEdit, onRemove, isFree, isCombo, notEditable } = props;
  return (
    <span className="d-flex justify-content-between">
      <button
        type="button"
        className="btn border-0 text-decoration-underline p-0 ms-3 f-s14 font-rale clr-dark-gray f-w5"
        onClick={onEdit}
        disabled={isFree || isCombo || notEditable ? true : false}
      // disabled
      >
        {'Edit'}
      </button>
      <button
        type="button"
        className="btn border-0 text-decoration-underline clr-dark-gray p-0 ms-3 f-s14 font-rale f-w5"
        onClick={onRemove}
      >
        {'Remove'}
      </button>
    </span>
  );
};

export default ActionButtons;
