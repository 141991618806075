import { useState } from 'react';
import {
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import {
  FooterFbIcon,
  FooterInstaIcon,
  FooterPintresrIcon,
  FooterTwitterIcon,
} from 'src/assets/images/Svgs/svg';
import CustomModal from 'src/components/customModal/CustomModal';
import { awsAssetsFetcher } from 'src/utils/awsAssetsFetcher';

import EmailListModel from './EmailListModel';
import styles from './footer.module.scss';
import { ROUTE_OTHER_ITEM } from 'src/constants';

const navLinks = [
  {
    link: '/talk',
    text: 'contact Us',
  },
  {
    link: '/assets/Cafe-Zupas_Nutrition.pdf',
    text: 'NUTRITIONAL INFO',
  },
  {
    link: '/assets/catering-2024.pdf',
    text: 'CATERING PRINT MENU',
  },
];

const socialLinks = [
  {
    link: 'https://www.facebook.com/CafeZupas/',
    icon: <FooterFbIcon />,
  },
  {
    link: 'https://www.instagram.com/cafezupas/',
    icon: <FooterInstaIcon />,
  },
  {
    link: 'https://www.pinterest.com/cafezupas/_created/',
    icon: <FooterPintresrIcon />,
  },
  {
    link: 'https://twitter.com/cafezupas',
    icon: <FooterTwitterIcon />,
  },
];

const Footer = () => {
  const location = useLocation();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleClick = () => setShow(true);
  const [showGlutenModal, setShowGlutenModal] = useState(false);
  return (
    <>
      <footer className={`${styles.footerWrap} pt-5`}>
        {/* <div className={styles.footerImg}>
        <img src={awsAssetsFetcher('CZ_FooterImage', 'png')} alt="footerImg" />
      </div> */}
        <div
          className={`${
            location.pathname.startsWith(ROUTE_OTHER_ITEM)
              ? styles.itemDetail
              : ''
          } ${styles.footerContent}`}
        >
          <Container fluid className="px-4 px-lg-5">
            <Row>
              <Col
                lg="3"
                className="border-right gray-border d-none d-lg-block"
              >
                <h5 className="">ALWAYS KNOW WHAT’S NEW!</h5>
                <p className="clr-sec-white h-16">
                  Nourish the Good Life with exclusive Café Zupas offers,
                  updates and seasonal menus sent straight to your inbox.
                </p>
                <button
                  onClick={handleClick}
                  type="button"
                  className="btn btn-large btn-white"
                >
                  JOIN OUR EMAIL LIST
                </button>
              </Col>
              <Col lg="5" className="offset-lg-1 p-0">
                <div className={`${styles.footerLinks}`}>
                  {navLinks.map((item, i) => {
                    return (
                      <Link
                        key={i}
                        className="h-30"
                        to={item.link}
                        target={
                          item.link.indexOf('.pdf') !== -1 ? '_blank' : ''
                        }
                      >
                        {item.text}
                      </Link>
                    );
                  })}
                </div>
                <div className={styles.privacyLinks}>
                  <Link to="/privacyPolicy">PRIVACY POLICY</Link>
                  <Link to="/terms">TERMS & CONDITIONS</Link>
                  <Link to="">
                    <a
                      onClick={() => {
                        setShowGlutenModal(true);
                      }}
                    >
                      *Gluten Sensitive{' '}
                    </a>
                  </Link>
                </div>
              </Col>
              <Col
                lg="3"
                className={`${styles.mediaLinksWrap} order-first order-lg-last d-flex align-items-center justify-content-between d-lg-block`}
              >
                <h5 className="mb-0 text-uppercase">Stay Connected:</h5>
                <div className={styles.footerSocialLinks}>
                  {socialLinks.map((item, i) => (
                    <a href={item.link} key={i} target="_blank">
                      <span className="d-block">{item.icon}</span>
                    </a>
                  ))}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </footer>
      <Row>
        <Col xs="12" sm="12" md="6">
          <EmailListModel showPopup={show} closePopup={handleClose} />
        </Col>
      </Row>
      <Modal
        show={showGlutenModal}
        title={'*Gluten Sensitive'}
        onHide={() => {
          setShowGlutenModal(false);
        }}
        className="theme-modal-style app_container_wrap  border-radius-15 "
        centered
      >
        <ModalHeader className="pb-0">
          <button
            type="button"
            className="btn modal-close-button pe-0"
            onClick={() => {
              setShowGlutenModal(false);
            }}
          >
            close
          </button>
          <h5
            className={`f-s22 font-Cls  mb-0 text-capitalize text-center w-100 mt-4`}
          >
            *Gluten Sensitive
          </h5>
        </ModalHeader>
        <ModalBody className="px-0 ">
          <p className="text-justify ">
            Even with strict adherence toward maintaining clean and organized
            kitchens, we have too much wheat and gluten present to be able to
            eliminate the cross-contamination on our equipment and food
            preparation areas. If you are highly allergic or intolerant to
            gluten, we ask you to exercise judgment regarding your individual
            needs.
          </p>
        </ModalBody>
      </Modal>
    </>
  );
};
export default Footer;
