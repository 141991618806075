import { appliedPaymentMethods } from 'src/helper/checkout/PaymentHelper';
import {
  getUser,
  isCartItemsUnavailable,
  isCartModifiersUnavailable,
} from 'src/helper/helperMethods';
import { Toast_Func } from 'src/helper/toast.helper';

import {
  DELIVERY_ORDER_TYPE,
  NO_TIME_SLOTS_PRESENT,
  PICK_UP_METHOD_CURBSIDE,
  PICK_UP_ORDER_TYPE,
} from '../constants';

interface IUsePlaceOrder {
  cart: any;
  checkout: any;
  customer: any;
}

interface IValidateInformation {
  setOrderTypeInvalidMessage?: (message: string) => void;
  setOrderDeliveryInvalidMessage?: (message: string) => void;
  setPaymentInvalidMessage?: (message: string) => void;
  setContactInfoInvalidMessage?: (message: string) => void;
  totalAmount?: number;
  setShowSplitPayment: (boolean) => void;
  isExternalPayment?: boolean;
  resetSlots: () => void;
  handleShowContactForm: () => void;
}
const usePlaceOrder = (cart: any, checkout: any, user: any) => {
  const placeOrderDetail = cart.orderDetails;
  const orderSlotDetails = placeOrderDetail?.PickupSlotDetails;
  // const [invalidInformation, setInvalidInformation] = useState<boolean>(false)

  const validateInformation = ({
    totalAmount,
    setOrderTypeInvalidMessage,
    setShowSplitPayment,
    isExternalPayment = false,
    resetSlots,
    setOrderDeliveryInvalidMessage,
    setPaymentInvalidMessage,
    setContactInfoInvalidMessage,
    handleShowContactForm,
  }: IValidateInformation) => {
    const customer = getUser();
    let validationToastMessage = '';
    let invalidInformation: boolean =
      isCartItemsUnavailable(cart?.items) ||
      isCartModifiersUnavailable(cart?.items);
    const allSelectedCards = appliedPaymentMethods(
      checkout?.paymentMethods,
      checkout?.giftCard,
    );
    if (cart?.orderType === PICK_UP_ORDER_TYPE) {
      if (
        orderSlotDetails?.pickupMethod === PICK_UP_METHOD_CURBSIDE &&
        !placeOrderDetail?.vehicleDetails
      ) {
        invalidInformation = true;
        setOrderDeliveryInvalidMessage('Vehicle Information is required');
        validationToastMessage = 'Vehicle Information is required';
      }
    } else if (cart?.orderType === DELIVERY_ORDER_TYPE) {
      if (!placeOrderDetail?.delivery_address) {
        invalidInformation = true;
        setOrderDeliveryInvalidMessage('Delivery Location is required');
        validationToastMessage = 'Delivery Location is required';
      }
    }
    if (cart?.items?.find((item) => !item.available)) {
      invalidInformation = true;
    }
    if (!isExternalPayment) {
      if (
        checkout?.paymentMethods?.length == 0 &&
        checkout?.giftCard.length == 0
      ) {
        invalidInformation = true;
        setPaymentInvalidMessage('Please add a payment method');
        validationToastMessage = 'Please add a payment method';
      }
      if (
        (checkout?.paymentMethods.length || checkout?.giftCard.length) &&
        calculateTotal(allSelectedCards) != totalAmount
      ) {
        invalidInformation = true;
        setPaymentInvalidMessage("Split amount doesn't match the total.");
        validationToastMessage = "Split amount doesn't match the total.";
        setShowSplitPayment(true);
        Toast_Func({
          status: false,
          message: "Split amount doesn't match the total.",
        });
        return true;
      }
      if (checkout?.giftCard.length) {
        const czCard = checkout?.giftCard;
        if (czCard?.amount > checkout?.defaultGiftCard?.balance) {
          invalidInformation = true;
          setPaymentInvalidMessage(
            'CZ Dollars are less than the amount added.',
          );
          setShowSplitPayment(true);
          Toast_Func({
            status: false,
            message: 'CZ Dollars are less than the amount added.',
          });
          return true;
        }
      }
    }
    if (!customer?.phone?.length || !customer?.email?.length) {
      invalidInformation = true;
      setContactInfoInvalidMessage('Contact Information is missing');
      handleShowContactForm();
      Toast_Func({
        status: false,
        message: 'Contact Information is missing',
      });
      return true;
    }
    if (cart?.orderType) {
      if (
        orderSlotDetails?.time === NO_TIME_SLOTS_PRESENT ||
        !orderSlotDetails?.date?.length
      ) {
        invalidInformation = true;
        resetSlots();
        setOrderTypeInvalidMessage('Kindly Select Time Slot');
        Toast_Func({
          status: false,
          message: 'Kindly Select Time Slot.',
        })
        return true;
      }
    }
    if (!invalidInformation) {
      setOrderTypeInvalidMessage('');
      setOrderDeliveryInvalidMessage('');
      setPaymentInvalidMessage('');
      setContactInfoInvalidMessage('');
    }
    if (validationToastMessage.length) {
      Toast_Func({
        status: false,
        message: validationToastMessage,
      });
    }
    return invalidInformation;
  };

  const calculateTotal = (payments) => {
    let amount = 0;
    payments?.map((item) => (amount = amount + item?.amount));
    return Number(amount.toFixed(2));
  };
  return { validateInformation };
};

export default usePlaceOrder;
