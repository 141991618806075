import React from 'react';
import { useDispatch } from 'react-redux';
import { EditPencilIcon, TrashIcon } from 'src/assets/images/Svgs/svg';

import { itemBuckets } from '../../../priceCalculation/buckets';
import {
  removeTemporarilyAddedPriceForItem,
  resetOrderInBucket,
} from '../../../redux/slices/itemCustomizationSlice';

import styleClasses from './itemBox.module.scss';

interface ISelectedItemsBox {
  orderedIngredients: any[];
  isItMobile: boolean;
  showModifierModal: () => void;
}

const ItemsBox: React.FC<ISelectedItemsBox> = (props) => {
  const { orderedIngredients = [], isItMobile } = props;

  return (
    <React.Fragment>
      <div className="bg-white rounded-3 modifiers-box text-start py-4 ps-4 pe-md-4 pe-2 my-4 position-relative">
        <div className={styleClasses.edit_btn}></div>
        <div className={`${isItMobile ? '' : 'modifiers-scroll'}`}>
          {orderedIngredients?.map((item) => {
            return (
              <div
                className={`${styleClasses.select_modifier_lisiting} d-flex align-items-center`}
              >
                <div className={`${styleClasses.selected_modifier_image}`}>
                  <img src={item?.image} alt={item?.modifier_name} />
                </div>
                <div
                  className="font-Visby-cf f-s14 fw-bold lh-lg"
                  key={item.modifier_id}
                >
                  {item?.modifier_name}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </React.Fragment>
  );
};

export default ItemsBox;
