import { useMutation } from 'react-query';

import { paymentService } from '../services';
import { bugSnagNotifyAPIError } from 'src/helper/bugSnagHelper';

const updateMethod = async (data: any, recordID: string) => {
  const response = await paymentService.updatePaymentMethod(recordID, data);
  return response.data.data;
};

export default function useUpdatePaymentMethod() {
  return useMutation((data: { newPaymentMethod: any; recordID: string }) =>
    updateMethod(data.newPaymentMethod, data.recordID),
    {
      onError: (error: any) => {
        bugSnagNotifyAPIError("Update Payments", error?.response?.data, error?.response?.data.message)
      }
    }
  );
}
