import React, { Dispatch, SetStateAction } from 'react';
import PackegeItemSelect from 'src/containers/ItemDetail/RequiredListing/Packages/PackegesRequiredItemSelect';
import {
  IItemsRequiredModifierGroups,
  IRefStatesForScrolling,
  ISubCategories,
  ItemDetails,
} from 'src/models/item.model';
import ItemRequiredModifiers from './ItemRequiredModifiers';
import PackagesItemRequiredModifiers from './Packages/PackagesItemRequiredModifiers';
import { REQUIRED_ITEM, REQUIRED_MODIFIERS } from 'src/constants';

type propTypes = {
  isPackege: boolean;
  ItemReqModifierGroup: IItemsRequiredModifierGroups[];
  handleItemSelect: (item: ItemDetails) => void;
  manualSelectedItem: ItemDetails;
  manualSelectedItemId?: 2 | 3;
  trays: ISubCategories[];
  handleIngredientSelection: (
    data,
    id,
    priorIdForCustomizationSlice?: string,
  ) => void;
  setIsRequiredModifierSelected?: Dispatch<SetStateAction<boolean>>;
  isRequiredModifierSelected?: boolean;
  openItemInfoModal?: (item: ItemDetails) => void;
  bucketkey: any;
  isBoxLunch: boolean;
  editCartItem?: any;
  requiredModifiersAtItemDetailSectionToHandleScroll?: IRefStatesForScrolling;
  subItemRequiredModifiersAtItemDetailSectionToHandleScroll?: IRefStatesForScrolling;
  requiredModifiersForDynamicSelectedItemAtItemDetailSectionToHandleScroll?: IRefStatesForScrolling;
  requiredItemForDynamicSelectedItemAtItemDetailSectionToHandleScroll?: IRefStatesForScrolling;
  traysItemRequiredModifierGroup?: IItemsRequiredModifierGroups[];
  setScrollToNextMissingSectionAndIgnoreSaladTrayItems?: any;
};
const RequiredListing: React.FC<propTypes> = ({
  isPackege,
  ItemReqModifierGroup,
  handleItemSelect,
  manualSelectedItem,
  trays,
  handleIngredientSelection,
  setIsRequiredModifierSelected,
  isRequiredModifierSelected,
  openItemInfoModal,
  bucketkey = 1,
  isBoxLunch,
  editCartItem,
  requiredModifiersAtItemDetailSectionToHandleScroll,
  manualSelectedItemId,
  traysItemRequiredModifierGroup,
  subItemRequiredModifiersAtItemDetailSectionToHandleScroll,
  requiredModifiersForDynamicSelectedItemAtItemDetailSectionToHandleScroll,
  requiredItemForDynamicSelectedItemAtItemDetailSectionToHandleScroll,
  setScrollToNextMissingSectionAndIgnoreSaladTrayItems,
}) => {
  const addPriorIdForParentItemRequiredModifiers = (data, id) => {
    if (isPackege && setScrollToNextMissingSectionAndIgnoreSaladTrayItems) {
      setScrollToNextMissingSectionAndIgnoreSaladTrayItems(true);
    }
    if (isPackege) {
      const priorIdForCustomizationSlice = REQUIRED_ITEM;
      handleIngredientSelection(data, id, priorIdForCustomizationSlice);
    } else {
      handleIngredientSelection(data, id);
    }
  };

  return (
    <>
      {isPackege && (
        <React.Fragment>
          <PackegeItemSelect
            handleSelect={handleItemSelect}
            selectedItem={manualSelectedItem?.id}
            trays={trays}
            openItemInfoModal={openItemInfoModal}
            requiredItemForDynamicSelectedItemAtItemDetailSectionToHandleScroll={
              requiredItemForDynamicSelectedItemAtItemDetailSectionToHandleScroll
            }
          />
          <PackagesItemRequiredModifiers
            trays={trays}
            subItemRequiredModifiersAtItemDetailSectionToHandleScroll={
              subItemRequiredModifiersAtItemDetailSectionToHandleScroll
            }
            requiredModifiersForDynamicSelectedItemAtItemDetailSectionToHandleScroll={
              requiredModifiersForDynamicSelectedItemAtItemDetailSectionToHandleScroll
            }
            traysItemRequiredModifierGroup={traysItemRequiredModifierGroup}
            handleIngredientSelection={(data, id) => {
              if (
                isPackege &&
                setScrollToNextMissingSectionAndIgnoreSaladTrayItems
              ) {
                setScrollToNextMissingSectionAndIgnoreSaladTrayItems(true);
              }
              handleIngredientSelection(data, id);
            }}
            bucketkey={manualSelectedItemId}
            isBoxLunch={isBoxLunch}
            editCartItem={editCartItem}
            manualSelectedItem={manualSelectedItem}
          />
        </React.Fragment>
      )}
      <ItemRequiredModifiers
        ItemReqModifierGroup={ItemReqModifierGroup}
        requiredModifiersAtItemDetailSectionToHandleScroll={
          requiredModifiersAtItemDetailSectionToHandleScroll
        }
        handleIngredientSelection={addPriorIdForParentItemRequiredModifiers}
        bucketkey={bucketkey}
        isBoxLunch={isBoxLunch}
        editCartItem={editCartItem}
      />
    </>
  );
};

export default RequiredListing;
