import { Row } from 'react-bootstrap';

type DetailsProps = {
  name: string;
  description: string;
  DescriptionScrollRef?: any;
};

const Details: React.FC<DetailsProps> = (props) => {
  const { name, description } = props;
  return (
    <Row ref={props.DescriptionScrollRef}>
      <h2 className="f-s30 f-sm-s18 font-Cls fw-normal clr-dark-gray">
        {name}
      </h2>
      <p className="font-rale f-w5 f-s18 f-sm-s14 pb-3">{description}</p>
    </Row>
  );
};

export default Details;
