import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import RadioSelect from 'src/components/RadioSelect/RadioSelect';

import { HALF_SIZE } from '../../../constants';
import styleClasses from '../../../containers/Modifiers/try2.module.scss';

interface ISectionDetail {
  currentItemName: string;
  defaultItemName: string;
  image: string;
  id: string;
  changeItemSize: string;
  title: string;
  isHalf?: number;
  selectedtitle?: string;
  weight?: string;
}
const SectionDetailTry2Combo: React.FC<ISectionDetail> = (props) => {
  const {
    currentItemName,
    defaultItemName,
    image,
    id,
    changeItemSize,
    title,
    isHalf,
    selectedtitle,
    weight,
  } = props;

  return (
    <label
      htmlFor={id}
      className={`
      ${styleClasses.optional_selector}
       f-s18 font-Vcf w-100  optional-selector d-flex align-items-center  justify-content-start p-0 cz-px-20 ${
         currentItemName !== defaultItemName &&
         `${styleClasses.optional_selector_selected}`
       } ${styleClasses.try2_combo_optional_selector}`}
    >
      <div className={`${styleClasses.try2combo_inner_div} w-100`}>
        <div
          className={
            image
              ? `img_box px-2 mx-auto mx-md-0`
              : `${styleClasses.rounded_box} rounded-box clr-sec-white mx-auto mx-md-0`
          }
        >
          {image ? (
            <img
              src={image}
              className={`img-fluid mb-1 ${styleClasses.image_box}`}
              alt="icon"
            />
          ) : (
            <span className="font-Vcf">{id}</span>
          )}
        </div>
        {currentItemName !== defaultItemName ? (
          <div
            className={`${styleClasses.try2_selected_item_content} ${styleClasses.try2_title_content} f-s0 w-100`}
          >
            <p
              className={`${changeItemSize} p-16 clr-lt-black ls-normal d-md-none mb-3 mb-md-4 px-2 f-sm-s11`}
            >
              {currentItemName}

              <span className="d-block">{weight}</span>
            </p>
            <h6
              className={`${changeItemSize} ${styleClasses.title_font_size} mb-0 font-Cls clr-lt-black ls-normal my-3 d-none  d-md-block`}
            >
              {currentItemName}

              <span className="d-block">{weight}</span>
            </h6>
            <span
              className={`${styleClasses.edit_btn} d-inline-block py-1 bg-lt-black py-md-3 px-3 d-md-none f-s16 clr-sec-white f-sm-s10text-uppercase`}
            >
              {selectedtitle ? selectedtitle : 'click to edit'}
            </span>
          </div>
        ) : (
          <div className="pe-md-4 ps-md-5 mx-auto mx-md-0">
            <h6
              className={`${changeItemSize} ${styleClasses.title_font_size}  font-Visby-cf f-w6 clr-lt-black ls-normal my-3 d-none  d-md-block`}
            >
              {currentItemName}
            </h6>
            <p className={`${styleClasses.inn_title_wrap} d-md-none f-s11`}>
              <strong className="f-s14">{title}</strong>
              <br />
              {currentItemName}
            </p>
          </div>
        )}
      </div>
      {currentItemName !== defaultItemName && (
        <div
          className={`${styleClasses.edit_btn}  f-s14 d-none d-md-block text-uppercase  f-w6`}
        >
          {selectedtitle ? selectedtitle : 'click to edit'}
        </div>
      )}
    </label>
  );
};

export default SectionDetailTry2Combo;
