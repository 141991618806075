import React, { useEffect, useState } from 'react';
import styleClasses from '../order.module.scss';
import { CARD_AMOUNT_REG_EXPRESSION, GIFT_CARD } from 'src/constants';
import { isZeroOnStart } from 'src/helper/utils';
import { IGiftCard } from 'src/models/payment.model';
import { TrashIcon } from 'src/assets/images/Svgs/svg';
interface ISelectedGiftCardProps {
  giftCard?: IGiftCard;
  removeCard?: (cardNumber: string | number) => void;
  handleGiftSplitAmount?: (amountAdded: number, index: number) => void;
  index?: number;
  handleAmountAdded?: (amountAdded: any) => void;
  resetInvalid: () => void;
  addedCards?;
  disableRemove?: boolean;
}

const SelectedGiftCard = (props: ISelectedGiftCardProps) => {
  const {
    giftCard,
    removeCard,
    addedCards,
    index,
    handleAmountAdded,
    resetInvalid,
    disableRemove,
  } = props;
  const [addedCardSplitAmount, setAddedCardSplitAmount] = useState<string>(
    giftCard.amount.toString(),
  );

  const handleCardSplit = (event) => {
    resetInvalid();
    let value = event?.target?.value;
    value = value?.replace('$', '');
    if (isZeroOnStart(value)) return;
    if (value !== '' && value < 1) return;
    if (
      value === '' ||
      value === '.' ||
      CARD_AMOUNT_REG_EXPRESSION.test(value)
    ) {
      setAddedCardSplitAmount(value);
      // handleGiftSplitAmount(value, index)
      handleAmountAdded({
        amount: Number(value),
        index: index,
        type: GIFT_CARD,
      });
    }
  };
  useEffect(() => {
    setAddedCardSplitAmount(giftCard.amount.toString());
  }, [addedCards]);
  return (
    <div className={styleClasses.splitBox}>
      <div className={styleClasses.splitList}>
        <div className="d-flex  justify-content-between align-items-center">
          <div>
            <div className="pt-3">
              <h5 className="font-Visby-cf f-w8 f-s16">{giftCard.name}</h5>
            </div>
            <div className="d-flex  justify-content-between align-items-center">
              <div>
                <span className="d-block font-Visby-cf f-normal f-s14 ">
                  Available: {giftCard.balance}
                </span>
              </div>
            </div>
          </div>
          <form className={`new_form_design`}>
            <div className="d-flex align-items-center gap-3 gap-md-5">
              <div className={`form-group mb-0 ${styleClasses.split_input}`}>
                <input
                  name="amount"
                  type="text"
                  className="form-control no-style f-s22 f-sm-s18 f-w8 text-center split-input"
                  value={'$' + addedCardSplitAmount}
                  placeholder={'Enter Amount'}
                  onChange={(e) => handleCardSplit(e)}
                />
              </div>
              <div className='opacity-0'>
                <TrashIcon />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SelectedGiftCard;
