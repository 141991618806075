import { useMutation } from 'react-query';
import { customerBusinessService } from '../../services';

interface IPayload {
  locationId?: string;
  userId?: string;
}

const removeFavorite = async (payload: IPayload) => {
  const response = await customerBusinessService.removeFavoritesLocation(
    payload?.locationId,
    payload?.userId,
  );
  return response.data.data;
};

export default function useRemoveFavoriteLocation() {
  return useMutation((payload: IPayload) => removeFavorite(payload));
}
