import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import { useAppSelector  } from 'src/redux/store/store';

import { ROUTE_LOGIN, GUEST_USER } from '../../constants';

interface PrivateRouteProps extends RouteProps {
  component: any;
}

const RequireAuth: React.FunctionComponent<PrivateRouteProps> = ({
  component: Component,
  ...restOfProps
}) => {
  const { user } = useAppSelector((state) => state.user);
  const { location } = restOfProps;
  const query = new URLSearchParams(location.search);
  const category_id = query.get('category_id');
  return (
    <Route
      {...restOfProps}
      render={(props) =>
        user?.type !== GUEST_USER ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: ROUTE_LOGIN,
              state: {
                referrer: location.pathname,
                category_id: category_id,
              },
            }}
          />
        )
      }
    />
  );
};

export default RequireAuth;
