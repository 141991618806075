import React from 'react';
import styleClasses from '../Popup.module.scss';
import { useRef, useState, useEffect } from 'react';

interface videoProps {
  source: any;
  fluid?: string;
  videoScale?: string;
  playBackSpeed?: string;
}

const Video: React.FunctionComponent<videoProps> = ({
  source,
  fluid,
  videoScale,
  playBackSpeed,
}) => {
  const videoRef = useRef<HTMLVideoElement>();
  const [playbackRate, setPlaybackRate] = useState(1);

  useEffect(() => {
    videoRef!.current!.playbackRate = playbackRate;
  }, [playbackRate]);

  const setPlayBack = () => {
    if (playBackSpeed) {
      setPlaybackRate(2);
    }
  };
  return (
    <>
      <div
        className={fluid ? 'container-fluid' : 'container px-md-4 mb-5 px-0'}
      >
        <div
          className={styleClasses.embed_video}
          style={{ paddingBottom: `${videoScale}` }}
        >
          <div className={styleClasses.embed_video_wrapper}>
            <video
              className={styleClasses.video}
              autoPlay
              playsInline
              loop
              controls
              ref={videoRef}
              onLoadStart={() => setPlayBack()}
            >
              <source src={`${source}`} type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
    </>
  );
};

export default Video;
