import { Formik } from 'formik';
import { useState } from 'react';
import { IGiftCardFromFields } from '../../models/forms.model';
import {
  addGiftCard,
  updateAmountOnSplit,
  updateGiftCard,
  updateIsSplitPayment,
  updatePaymentCard,
} from '../../redux/slices/checkoutSlice';
import {
  addGiftCardForTextToPayFlow,
  updateAmountOnSplitForTextToPayFlow,
  updateGiftCardForTextToPayFlow,
  updateIsSplitPaymentForTextToPayFlow,
  updatePaymentCardAmountForTextToPayFlow,
  updatePaymentMethodForTextToPayFlow,
} from '../../redux/slices/addPayment/addPaymentSlice';
import { useAppDispatch, useAppSelector } from '../../redux/store/store';
import { paymentService } from '../../services';

import { Toast_Func } from 'src/helper/toast.helper';
import FormField from '../FormFields/FormField';

import SplitPaymentMethod from 'src/Features/order/PaymentMethod/SplitPaymentMethod';
import SplitPaymentToggle from 'src/Features/order/PaymentMethod/splitPaymentToggle';
import { removeGiftCard, splitAmount } from 'src/helper/checkout/PaymentHelper';
import { getAddPaymentUser } from 'src/helper/helperMethods';

interface IGiftCardFormProps {
  checkout?: any;
  addSplitPayment?: boolean;
  handleCloseGiftCard?: () => void;
  editGiftCard?;
  totalAmount: number;
  setOrderTippingAmount: (number) => void;
  handlePaymentForAddPaymentUser?: boolean;
}
const GiftCardForm = (props: IGiftCardFormProps) => {
  const {
    handleCloseGiftCard,
    editGiftCard,
    addSplitPayment,
    checkout,
    totalAmount,
    setOrderTippingAmount,
    handlePaymentForAddPaymentUser,
  } = props;
  const initialFormState: IGiftCardFromFields = {
    gift_card: editGiftCard ? editGiftCard.gift_card_no : '',
  };
  const [isCardInvalid, setIsCardInvalid] = useState<boolean>(false);
  const splitAmounts = checkout.isSplitPayment;
  const [isSplitPayment, setIsSplitPayment] = useState(splitAmounts);
  const [showSplitContent, setShowSplitContent] = useState<boolean>(false);
  const [invalidCardMessage, setInvalidCardMessage] = useState<string>('');
  const addPaymentUser = getAddPaymentUser();
  const orderId = handlePaymentForAddPaymentUser
    ? addPaymentUser.order_id
    : useAppSelector((state) => state.cart.orderId);
  const checkoutDispatch = useAppDispatch();
  // const checkout = useAppSelector((state) => state.checkout);
  const addedCards = checkout.giftCard;
  const resetInvalid = () => {
    if (isCardInvalid) {
      setIsCardInvalid(false);
    }
  };
  const removeEditedCard = async (modifiedValues: any) => {
    let updatedGiftCards = removeGiftCard(
      addedCards,
      editGiftCard.gift_card_no,
    );
    await paymentService.removeGiftCard({
      ...modifiedValues,
      gift_card_no: editGiftCard.gift_card_no,
    });
    handlePaymentForAddPaymentUser
      ? checkoutDispatch(updateGiftCardForTextToPayFlow(updatedGiftCards))
      : checkoutDispatch(updateGiftCard(updatedGiftCards));
  };
  const hideSplitPayments = () => {
    setShowSplitContent(false);
  };
  const handleFormSubmission = async (values: IGiftCardFromFields) => {
    if (values.gift_card) {
      const modifiesValues = {
        cart: {
          id: orderId,
        },
        gift_card_no: values.gift_card,
      };
      try {
        if (
          !checkout.giftCard.find(
            (card) => card.gift_card_no == values.gift_card,
          )
        ) {
          if (editGiftCard) await removeEditedCard(modifiesValues);
          const response = await paymentService.addGiftCard(modifiesValues);
          handlePaymentForAddPaymentUser
            ? checkoutDispatch(
                addGiftCardForTextToPayFlow({
                  gift_card_no: values.gift_card,
                  amount: splitAmount(checkout, totalAmount),
                }),
              )
            : checkoutDispatch(
                addGiftCard({
                  gift_card_no: values.gift_card,
                  amount: splitAmount(checkout, totalAmount),
                }),
              );
        }
        if (!isSplitPayment) {
          if (addedCards.length > 0) {
            handlePaymentForAddPaymentUser
              ? checkoutDispatch(
                  updateGiftCardForTextToPayFlow([
                    {
                      gift_card_no: values.gift_card,
                      amount: splitAmount(checkout, totalAmount),
                    },
                  ]),
                )
              : checkoutDispatch(
                  updateGiftCard([
                    {
                      gift_card_no: values.gift_card,
                      amount: splitAmount(checkout, totalAmount),
                    },
                  ]),
                );
          }
          handlePaymentForAddPaymentUser
            ? checkoutDispatch(updatePaymentMethodForTextToPayFlow([]))
            : checkoutDispatch(updatePaymentCard([]));
        }
        handlePaymentForAddPaymentUser
          ? checkoutDispatch(
              updateIsSplitPaymentForTextToPayFlow(isSplitPayment),
            )
          : checkoutDispatch(updateIsSplitPayment(isSplitPayment));
        isSplitPayment && !addSplitPayment
          ? setShowSplitContent(true)
          : handleCloseGiftCard();
        Toast_Func({
          status: true,
          message: 'Gift card applied',
        });
      } catch (error) {
        setIsCardInvalid(true);
        setInvalidCardMessage(error.response.data.message);
      }
      handlePaymentForAddPaymentUser
        ? checkoutDispatch(
            updateAmountOnSplitForTextToPayFlow({
              totalAmount: Number(totalAmount),
            }),
          )
        : checkoutDispatch(
            updateAmountOnSplit({ totalAmount: Number(totalAmount) }),
          );
    } else {
      if (isSplitPayment && checkout?.paymentMethods?.length > 0) {
        handlePaymentForAddPaymentUser
          ? checkoutDispatch(
              updateIsSplitPaymentForTextToPayFlow(isSplitPayment),
            )
          : checkoutDispatch(updateIsSplitPayment(isSplitPayment));
        // setShowSplitContent(true);
        // return;
      }
      setIsCardInvalid(true);
      setInvalidCardMessage('Gift card number is required');
    }
  };
  return (
    <>
      {!showSplitContent && (
        <div>
          <h5 className="f-s22 font-Cls  text-start mx-auto mb-2 text-capitalize full_width_mob">
            Add Gift Card
          </h5>
          <Formik
            initialValues={initialFormState}
            onSubmit={handleFormSubmission}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              isSubmitting,
              submitForm,
            }) => (
              <form
                className="new_form_design my-0 mx-auto delivery_location_select full_width_mob"
                onSubmit={handleSubmit}
              >
                <hr className="custom_hr_sty " />
                {!addSplitPayment && totalAmount >= 2 ? (
                  <SplitPaymentToggle
                    setSplitToggle={setIsSplitPayment}
                    isSplitPayment={isSplitPayment}
                    handleSplitToggle={handleSubmit}
                  />
                ) : null}
                <FormField
                  labelText={'Gift Card number'}
                  type="text"
                  name="gift_card"
                  errors={errors}
                  touched={touched}
                  value={values.gift_card}
                  onChange={(e) => {
                    handleChange(e);
                    resetInvalid();
                  }}
                  inputFieldClass={`${isCardInvalid ? 'input_error' : ''}`}
                />
                {isCardInvalid && (
                  <p className="f-s14 h-16 f-w5 font-Visby-cf w-100 my-3 text-danger mx-auto">
                    {invalidCardMessage}
                  </p>
                )}

                <hr className="custom_hr_sty my-4 " />
                <div className="d-flex justify-content-center mb-3 mt-4">
                  <button
                    type="button"
                    className="btn-large py-2 f-s16"
                    onClick={submitForm}
                    disabled={isSubmitting || isCardInvalid}
                  >
                    {!addSplitPayment ? 'Confirm Payment Method' : 'Continue'}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      )}
      {showSplitContent && (
        <SplitPaymentMethod
          setOrderTippingAmount={setOrderTippingAmount}
          backBTn="Gift Card"
          backBtnHiden={null}
          checkout={checkout}
          totalAmount={totalAmount}
          handleCloseSplitPayment={handleCloseGiftCard}
          hideSplitPayments={hideSplitPayments}
        />
      )}
    </>
  );
};

export default GiftCardForm;
