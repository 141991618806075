import React from 'react';
import { Modal } from 'react-bootstrap';

import { ISingleItemOtherDressing } from '../../models/item.model';

import OtherDressing from './ItemDressing';
import styleClasses from './try2.module.scss';

const SingleItemOtherDreesing: React.FC<ISingleItemOtherDressing> = (props) => {
  const {
    modalShow,
    closeModal,
    otherDressingData,
    requiredModifierGroup,
    noDressingHandler,
    removeSelectedIngredients,
    selectedIngredients,
    selectedAddedIngredients,
    updateSelectedModifiers,
    noDressing,
    selectedOtherDressingData,
    addSelectedIngredients,
    label,
    applyClickHandler,
    bucketkey = 1,
  } = props;

  return (
    <Modal
      show={modalShow}
      onHide={closeModal}
      scrollable={true}
      centered
      className={`${
        otherDressingData && 'other-dressings-modal'
      } theme-modal-style app_container_wrap no-padding border-radius-15 border-radius-sm-12 upperside-radius-sm-only try2_dressing_modal ${
        styleClasses.item_other_dressing
      }`}
      size="lg"
    >
      <Modal.Header className="pt-0 mb-4" id="close_button2">
        <button
          type="button"
          className="btn modal-close-button pe-0 sm-blue-icon"
          onClick={closeModal}
        >
          Close
        </button>
      </Modal.Header>
      <Modal.Body
        className={`${
          otherDressingData && 'pe-3 pe-md-0'
        } app_container_wrap px-0 px-md-0 text-center`}
      >
        <div
          className={`${styleClasses.items_wrap} ${
            !otherDressingData && 'mb-2'
          } ${styleClasses.select_dressing_wrapper}`}
        >
          <OtherDressing
            requiredModifierGroup={requiredModifierGroup}
            noDressingHandler={noDressingHandler}
            setOtherDressingData={removeSelectedIngredients}
            selectedIngredients={selectedIngredients}
            selectedAddedIngredients={selectedAddedIngredients}
            handleIngredientSelection={(data) =>
              updateSelectedModifiers(data, bucketkey)
            }
            sectionSelected={bucketkey}
            isNoDressing={noDressing}
            showRecommendedButton={false}
            addSelectedIngredients={addSelectedIngredients}
            label={label}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SingleItemOtherDreesing;
