import React from 'react';
import {
  DIGITAL_TYPE,
  CARD_TYPE,
  HOUSE_ACCOUNT_TYPE,
  INVOICED,
  GIFT_CARD_PAYMENT,
} from '../../constants';
import styleClasses from './order.module.scss';
import { formatAMPM, formatDate, formatTime } from 'src/helper/utils';
import { capitalizeSentence } from 'src/helper/helperMethods';

interface IPaymentProps {
  order: any;
}
const SubmittedPaymentMethod: React.FunctionComponent<IPaymentProps> = ({
  order,
}) => {
    const getFormattedDate = (date) => {
    let time = '';
    const splitDate = date.split(' ');
    const hoursMinsSec = splitDate?.[1]?.split(':');
    time = formatAMPM(hoursMinsSec?.[0], hoursMinsSec?.[1]);
    const dates = formatDate(splitDate?.[0]);

    return (dates + ' @ ' + time);
    }
  return (
    <>
      <div className="shadow-divider d-md-none mt-4" />
      <div className={`${styleClasses.payment_details} text-start`}>
        {
          <div className="d-flex justify-content-between">
            <h2 className="f-s20 text-start h-22 text-uppercase font-Cls fw-normal f-sm-s16 mt-3">
              Payments Received
            </h2>
          </div>
        }
        <div className="bot">
          <table className="w-100">
            <tr>
              <th className="text-start">Date</th>
              <th className="text-start">Payment Type</th>
              <th className="text-start">Amount</th>
            </tr>
            {order?.current_payment_details?.map((record, index) => {
              return (
                <tr key={index}>
                  {(record?.order_payment_type === CARD_TYPE ||
                    record?.order_payment_type === DIGITAL_TYPE) &&
                  record?.card?.id ? (
                    <>
                      <td className="font-Visby-cf f-s16 f-w5 text-start">
                        {record?.processed_at ? getFormattedDate(record?.processed_at) : 'N/A'}
                      </td>
                      <td className="font-Visby-cf f-s16 f-w5 text-start">
                        {`${capitalizeSentence(record?.card?.card_type)} x ${record?.card?.last4}`}
                      </td>
                      <td className="font-Visby-cf f-s16 f-w5 text-start">{`$${
                        record?.card?.amount.toFixed(2) || '0.00'
                      }
                        `}</td>
                    </>
                  ) : record?.order_payment_type === HOUSE_ACCOUNT_TYPE &&
                    record?.house_account &&
                    record?.house_account?.id ? (
                    <>
                      <td className="font-Visby-cf f-s16 f-w5 text-start">
                        {record?.processed_at ? getFormattedDate(record?.processed_at) : 'N/A'}
                      </td>
                      <td className="font-Visby-cf f-s16 f-w5 text-start">
                        {record?.order_payment_type}
                      </td>
                      <td className="font-Visby-cf f-s16 f-w5 text-start">{`$${
                        record?.house_account?.amount.toFixed(2) || '0.00'
                      }
                            `}</td>
                    </>
                  ) : record?.order_payment_type === INVOICED &&
                    record?.invoice &&
                    record?.invoice?.id ? (
                    <>
                      <td className="font-Visby-cf f-s16 f-w5 text-start">
                        {record?.processed_at ? getFormattedDate(record?.processed_at) : 'N/A'}
                      </td>
                      <td className="font-Visby-cf f-s16 f-w5 text-start">
                        {record?.order_payment_type}
                      </td>
                      <td className="font-Visby-cf f-s16 f-w5 text-start">{`$${
                        record?.invoice?.amount?.toFixed(2) || '0.00'
                      }
                            `}</td>
                    </>
                  ) : record?.order_payment_type === GIFT_CARD_PAYMENT &&
                    record?.gift_card?.id ? (
                    <>
                      <td className="font-Visby-cf f-s16 f-w5 text-start">
                        {record?.processed_at ? getFormattedDate(record?.processed_at) : 'N/A'}
                      </td>
                      <td className="font-Visby-cf f-s16 f-w5 text-start">
                        {record?.gift_card?.card_name}
                      </td>
                      <td className="font-Visby-cf f-s16 f-w5 text-start">{`$${
                        record?.gift_card?.amount.toFixed(2) || '0.00'
                      }
                            `}</td>
                    </>
                  ) : (
                    <></>
                  )}
                </tr>
              );
            })}
          </table>
        </div>
      </div>
    </>
  );
};

export default SubmittedPaymentMethod;
