import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import * as Constants from 'src/constants';

import { filterArraysBasedOnGroupId } from '../../helper/customizedItem/customizedItem.helper';
import {
  ICoreModifiers,
  IItemsRequiredModifierGroups,
  IModifierGroups,
  IParams,
} from '../../models/item.model';
import {
  IBucketSelectedIngredients,
  IOptionalModifier,
} from '../../models/item.model';
import { itemBuckets } from '../../priceCalculation/buckets';
import { ADDITIONAL_ADDED } from '../../priceCalculation/constants';

import CoreModifiers from './ingredients/CoreModifiers';
import ModifierGroups from './ingredients/ModifierGroups';
import RequiredModifier from './ingredients/RequiredModifier';
import { isItCreateYourOwnItem } from 'src/helper/helperMethods';
import EventBusForBYOCategoryScrollingBetweenAccordions from 'src/utils/EventBusForBYOCategoryScrollingBetweenAccordions';
import useCheckMobileScreen from 'src/hooks/useCheckMobileScreen';

const AddBoxLunchIngredients: React.FC<IOptionalModifier> = (props) => {
  const {
    coreModifiers,
    modifierGroups,
    requiredModifiers,
    handleIngredientSelection,
    sectionSelected,
    noDressingHandler,
    itemName,
    isItEdit,
    Item,
    isCYOIColSize,
    isCreateYourOwnItem,
  } = props;

  const {
    refToScrollToMissingRequiredAreaForCYOI,
    isCYOIRequiredModifierSelected,
    setIsCYOIRequiredModifierSelected,
  } = useScrollToMissingRequiredArea(requiredModifiers);

  const CYOIScrollToMissingRequiredArea = {
    refToScrollToMissingRequiredArea: refToScrollToMissingRequiredAreaForCYOI,
    isCYOIRequiredModifierSelected,
    setIsCYOIRequiredModifierSelected,
  };
  const {
    missingRequiredRef,
    modifierGroupScrolling,
    scrollToNextGroupOnExtendableSelection,
  }: {
    missingRequiredRef: MutableRefObject<{
      valForAccordian: Set<string>;
      valForSearch: { [key: number]: boolean };
      currentMissingIndex: string;
      scroll: () => void;
    }>;
    modifierGroupScrolling: MutableRefObject<{
      valForAccordian: Set<string>;
      valForSearch: { [key: number]: boolean };
      currentMissingIndex: string;
      refsToCYOIAddOnsModifier: any;
      scroll: () => void;
    }>;
    scrollToNextGroupOnExtendableSelection: (
      currentModifier: IParams | undefined,
      clickedOn: { name: 'accordion' | 'modifier'; groupId?: number },
      updatedAccorionState?: string[],
    ) => { status: boolean } | undefined | void;
  } = useScrolling(
    requiredModifiers,
    modifierGroups,
    CYOIScrollToMissingRequiredArea,
  );

  const handleCoreModifierClick = (
    data: IParams,
    modifier: ICoreModifiers,
    type: string,
  ) => {
    handleIngredientSelection({
      ...data,
      ...{
        modifier_group_id: modifier?.modifier_group_id,
        modifier_type: type,
      },
    });
  };

  const handleClick = (
    data: IParams,
    modifierGroup: IModifierGroups,
    type: string,
  ) => {
    handleIngredientSelection({
      ...data,
      ...{
        modifier_group_id: modifierGroup?.id,
        modifier_group_max: modifierGroup?.max,
        modifier_group_base: modifierGroup?.base,
        modifier_type: type,
      },
    });
  };

  const selectedAddOns = (function () {
    return itemBuckets.specificItemBucketSelectedModifiers(
      Constants.ADD_ONS,
      sectionSelected,
    );
  })();

  const selectedCore: IBucketSelectedIngredients[] = (function () {
    const result = itemBuckets.specificItemBucketSelectedModifiers(
      Constants.CORE_MODIFIERS,
      sectionSelected,
    );
    return result;
  })();

  const selectedAddedAddOns: IBucketSelectedIngredients[] = (function () {
    const fromItem: 0 | 1 = (parseInt(sectionSelected) - 1) as 0 | 1;
    return itemBuckets.getSingleBucketKeyValue({
      name: Constants.ADD_ONS,
      fromItem,
      modifierType: ADDITIONAL_ADDED,
      key: 'modifiers',
    });
  })();

  const coreModifierBucket = (function () {
    return itemBuckets.getSingleBucket({
      name: Constants.CORE_MODIFIERS,
      fromItem: parseInt(sectionSelected),
    });
  })();

  const isItCoreRelated = (groupId: number) => {
    if (coreModifierBucket?.memoryChip) {
      const isExist = coreModifierBucket['memoryChip'][groupId];
      if (isExist === undefined) {
        return false;
      } else {
        return true;
      }
    }
  };

  const isLimitExceed = (
    groupId: number,
    extendable_limit: number,
    groupMin,
  ) => {
    if (extendable_limit === 1 && groupMin === 1) {
      return false;
    } else {
      if (!isItCoreRelated(groupId)) {
        const selectedModifierForCurrentGroup: IBucketSelectedIngredients[] =
          filterArraysBasedOnGroupId([selectedAddedAddOns], groupId);
        return selectedModifierForCurrentGroup.length >= extendable_limit
          ? true
          : false;
      } else {
        if (coreModifierBucket['memoryChip'][groupId]?.changes) {
          return coreModifierBucket['memoryChip'][groupId]?.changes?.quantity >=
            extendable_limit
            ? true
            : false;
        } else {
          return coreModifierBucket['memoryChip'][groupId]?.quantity >=
            extendable_limit
            ? true
            : false;
        }
      }
    }
  };

  const handleAddOnsModifierClickHandler = (
    currentModifier: IParams,
    modifiers: IModifierGroups,
    type: string,
  ) => {
    handleClick(currentModifier, modifiers, type);
    if (
      isCreateYourOwnItem &&
      (currentModifier.type === Constants.INCREASE ||
        currentModifier.type === Constants.SELECTED)
    ) {
      scrollToNextGroupOnExtendableSelection(currentModifier, {
        name: 'modifier',
      });
    }
  };

  const handleRequiredModifierClickHandler = (currentModifier) => {
    handleIngredientSelection(currentModifier);
    if (
      isCreateYourOwnItem &&
      (currentModifier.type === Constants.INCREASE ||
        currentModifier.type === Constants.SELECTED)
    ) {
      scrollToNextGroupOnExtendableSelection(currentModifier, {
        name: 'modifier',
      });
    }
  };

  return (
    <div className="other-dressing-view try2_dressing_modal box-lunches-modal-inner">
      {/* <Scrollbars className="Modal_inner_scrollbar customization_inner_scrollbar apply-btn-view"> */}
      {coreModifiers?.length ? (
        <CoreModifiers
          coreModifiers={coreModifiers}
          selectedCore={selectedCore}
          handleCoreModifierClick={handleCoreModifierClick}
          isLimitExceed={isLimitExceed}
          Item={Item}
        />
      ) : null}
      {requiredModifiers?.length ? (
        <RequiredModifier
          sectionSelected={sectionSelected}
          itemIngredients={requiredModifiers}
          handleIngredientSelection={handleRequiredModifierClickHandler}
          noDressingHandler={noDressingHandler}
          showMaxSelection={
            isItCreateYourOwnItem(isCreateYourOwnItem) ? false : true
          }
          // customizationModule={true}
          showAdditionalPrice={true}
          Item={Item}
          isCYOIColSize={isCYOIColSize}
          CYOIScrollToMissingRequiredArea={CYOIScrollToMissingRequiredArea}
          missingRequiredRef={missingRequiredRef}
          isCreateYourOwnItem={isCreateYourOwnItem}
          scrollToNextGroupOnExtendableSelection={
            scrollToNextGroupOnExtendableSelection
          }
        />
      ) : null}
      {modifierGroups?.length ? (
        <ModifierGroups
          modifierGroups={modifierGroups}
          selectedAddOns={selectedAddOns}
          selectedCore={selectedCore}
          handleClick={handleAddOnsModifierClickHandler}
          isLimitExceed={isLimitExceed}
          isItEdit={isItEdit}
          isBoxLunch={true}
          Item={Item}
          isCreateYourOwnItem={isCreateYourOwnItem}
          modifierGroupScrolling={modifierGroupScrolling}
          scrollToNextGroupOnExtendableSelection={
            scrollToNextGroupOnExtendableSelection
          }
        />
      ) : null}
      {/* </Scrollbars> */}
      {/* <hr className="d-md-none" /> */}
    </div>
  );
};

const useScrollToMissingRequiredArea = (requiredModifiers) => {
  const [
    requiredModifiersRefsForScrollToMissingSelectedGroupInCYOI,
    setRequiredModifiersRefsForScrollToMissingSelectedGroupInCYOI,
  ] = useState([]);

  const [isCYOIRequiredModifierSelected, setIsCYOIRequiredModifierSelected] =
    useState({ status: false, highLightArea: null });
  useEffect(() => {
    if (requiredModifiers) {
      assignRefForScroll({
        requiredSection: requiredModifiers,
        refState: requiredModifiersRefsForScrollToMissingSelectedGroupInCYOI,
        setRefState:
          setRequiredModifiersRefsForScrollToMissingSelectedGroupInCYOI,
      });
    }
  }, [requiredModifiers]);

  function assignRefForScroll({ requiredSection, refState, setRefState }) {
    const divRefsNew = refState;
    divRefsNew.length = requiredSection?.length;
    // Initialize the refs
    for (let i = 0; i < divRefsNew.length; i++) {
      divRefsNew[i] = divRefsNew[i] || React.createRef();
    }
    setRefState(divRefsNew);
  }

  return {
    refToScrollToMissingRequiredAreaForCYOI:
      requiredModifiersRefsForScrollToMissingSelectedGroupInCYOI,
    isCYOIRequiredModifierSelected,
    setIsCYOIRequiredModifierSelected,
  };
};

const useScrolling = (
  requiredModifiers: IItemsRequiredModifierGroups[],
  modifierGroups: IModifierGroups[],
  CYOIScrollToMissingRequiredArea: {
    refToScrollToMissingRequiredArea: any[];
    isCYOIRequiredModifierSelected: {
      status: boolean;
      highLightArea: number;
    };
    setIsCYOIRequiredModifierSelected: React.Dispatch<
      React.SetStateAction<{ status: boolean; highLightArea: any }>
    >;
  },
) => {
  const isItMobile: boolean = useCheckMobileScreen();

  useEffect(() => {
    EventBusForBYOCategoryScrollingBetweenAccordions.subscribe(
      'scrollToMissingIndex',
      handleScrolling,
    );
    return () => {
      EventBusForBYOCategoryScrollingBetweenAccordions.unsubscribe(
        'scrollToMissingIndex',
        handleScrolling,
      );
    };
  }, []);

  const missingRequiredRef: MutableRefObject<{
    valForAccordian: Set<string>;
    valForSearch: { [key: number]: boolean };
    currentMissingIndex: string;
    scroll: () => void;
  }> = useRef({
    valForAccordian: new Set('0'),
    valForSearch: {},
    currentMissingIndex: null,
    scroll: null,
  });

  const modifierGroupScrolling: MutableRefObject<{
    valForAccordian: Set<string>;
    valForSearch: { [key: number]: boolean };
    currentMissingIndex: string;
    refsToCYOIAddOnsModifier: any;
    scroll: () => void;
  }> = useRef({
    valForAccordian: new Set(),
    valForSearch: {},
    currentMissingIndex: null,
    refsToCYOIAddOnsModifier: [],
    scroll: null,
  });

  const scrollToNextGroupOnExtendableSelection = (
    currentModifier: IParams | undefined,
    clickedOn: { name: 'accordion' | 'modifier'; groupId?: number },
    updatedAccorionState?: string[],
  ): { status: boolean } | undefined | void => {
    const selectedRequiredModifier = itemBuckets.getSingleBucket({
      name: Constants.REQUIRED_MODIFIERS,
      fromItem: Constants.SINGLE_ITEM_SELECTED_SECTION,
    });
    const selectedAddOnsModifier = itemBuckets.getSingleBucket({
      name: Constants.ADD_ONS,
      fromItem: Constants.SINGLE_ITEM_SELECTED_SECTION,
    });
    const selectedModifier = [
      ...selectedRequiredModifier.modifiers.concat(
        selectedAddOnsModifier.modifiers,
      ),
    ];
    const itemIngredients: (IItemsRequiredModifierGroups | IModifierGroups)[] =
      [...requiredModifiers, ...modifierGroups];

    const commulativeItems = [];
    let missingGroupIndex = null;
    const currentModifierGroupId: number =
      clickedOn.name === 'accordion'
        ? clickedOn.groupId
        : currentModifier.modifier_group_id;
    selectedModifier?.forEach((element) => {
      const index = commulativeItems.findIndex(
        (e) => e.groupId === element?.modifier_group_id,
      );
      if (index !== -1) {
        commulativeItems[index].quantity =
          commulativeItems[index].quantity + element?.quantity;
        commulativeItems[index].noDressing = false;
      } else {
        const trackObj = {
          groupId: element?.modifier_group_id,
          groupMax: element?.modifier_group_max,
          groupMin: element?.modifier_group_min,
          extendableLimitValue: element?.extendableLimitValue,
          quantity: element?.quantity,
          noDressing: false,
        };
        commulativeItems.push(trackObj);
      }
    });
    const data = commulativeItems.find(
      (item) => item.groupId === currentModifierGroupId,
    );
    const startScrollingFunctionality =
      data?.extendableLimitValue === data?.quantity;
    if (!startScrollingFunctionality && clickedOn.name === 'modifier') {
      return;
    }

    let isMinExeceeded = null;
    let limitForCompare: { type: 'min' | 'extendable_limit'; value: number } = {
      type: null,
      value: null,
    };
    const currentModifierGroupIndex = itemIngredients.findIndex(
      (itemIngredient) => itemIngredient.id === currentModifierGroupId,
    );
    for (let i = 0; i < itemIngredients.length; i++) {
      const itemIndex = commulativeItems.findIndex(
        (e) => e.groupId === itemIngredients[i].id,
      );
      missingGroupIndex = i;
      limitForCompare = { type: null, value: null };
      let minReached: boolean = false;

      if (clickedOn.name === 'modifier' && i < currentModifierGroupIndex) {
        limitForCompare.value = itemIngredients[i].min;
        limitForCompare.type = 'min';
      } else if (
        clickedOn.name === 'accordion' &&
        i <= currentModifierGroupIndex
      ) {
        limitForCompare.value = itemIngredients[i].min;
        limitForCompare.type = 'min';
      } else {
        limitForCompare.value = itemIngredients[i].extendable_limit;
        limitForCompare.type = 'extendable_limit';
      }
      if (commulativeItems[itemIndex]?.noDressing === true) {
        minReached = true;
      }
      if (
        (commulativeItems[itemIndex]?.noDressing === false &&
          commulativeItems[itemIndex]?.quantity > 0 &&
          commulativeItems[itemIndex]?.quantity >= limitForCompare.value) ||
        limitForCompare.value === 0
      ) {
        minReached = true;
      }

      if ((itemIndex === -1 || !minReached) && limitForCompare.value != 0) {
        isMinExeceeded = false;
        break;
      } else {
        isMinExeceeded = true;
      }
    }
    if (isMinExeceeded) {
      return { status: false };
    } else if (
      clickedOn.name === 'accordion' &&
      missingGroupIndex >= currentModifierGroupIndex
    ) {
      missingRequiredRef.current.currentMissingIndex = `${currentModifierGroupIndex}`;
      return { status: false };
    } else {
      const requiredModifiersIndexLength = requiredModifiers.length;
      if (
        missingGroupIndex >= requiredModifiersIndexLength &&
        clickedOn.name === 'modifier'
      ) {
        missingGroupIndex = missingGroupIndex - requiredModifiersIndexLength;
        modifierGroupScrolling.current.currentMissingIndex = `${missingGroupIndex}`;
        modifierGroupScrolling.current.scroll = () =>
          handleScrolling({
            status: true,
            missingGroupIndex: missingGroupIndex,
            startScrollingFor: 'addons',
            limitForCompareType: limitForCompare.type,
          });
      } else {
        missingRequiredRef.current.currentMissingIndex = `${missingGroupIndex}`;
        missingRequiredRef.current.scroll = () =>
          handleScrolling({
            status: true,
            missingGroupIndex: missingGroupIndex,
            startScrollingFor: 'required',
            limitForCompareType: limitForCompare.type,
          });
        EventBusForBYOCategoryScrollingBetweenAccordions.emit(
          'refUpdatedForBuildYourOwnRequiredScrollOnAddOnsAccorionClick',
          EventBusForBYOCategoryScrollingBetweenAccordions.getState(),
        );
      }

      //If click accordion from the addons one
      if (
        missingGroupIndex < requiredModifiersIndexLength &&
        currentModifierGroupIndex >= requiredModifiersIndexLength &&
        clickedOn.name === 'accordion'
      ) {
        EventBusForBYOCategoryScrollingBetweenAccordions.emit(
          'refUpdatedForBuildYourOwnRequiredScrollOnAddOnsAccorionClick',
          EventBusForBYOCategoryScrollingBetweenAccordions.getState(),
        );
      }
    }
  };

  function handleScrolling(data: {
    status: boolean;
    missingGroupIndex: number;
    startScrollingFor: 'required' | 'addons';
    limitForCompareType: 'min' | 'extendable_limit';
  }): void | null {
    if (!data.status) return;
    if (data.startScrollingFor === 'addons') {
      modifierGroupScrolling.current.valForAccordian.add(
        `${data.missingGroupIndex}`,
      );
      modifierGroupScrolling.current.valForSearch[data.missingGroupIndex] =
        true;
      const modalContainer = document.getElementById(
        'modal_handle_for_byo_items',
      ); // Replace with the actual modal content class or ref
      if (modalContainer) {
        modifierGroupScrolling.current.refsToCYOIAddOnsModifier[
          data.missingGroupIndex
        ]?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });

        // Ensure modal itself scrolls to the top
        modalContainer.scrollTop =
          modifierGroupScrolling.current.refsToCYOIAddOnsModifier[
            data.missingGroupIndex
          ].offsetTop;
      } else {
        modifierGroupScrolling.current.refsToCYOIAddOnsModifier[
          data.missingGroupIndex
        ]?.scrollIntoView({
          behavior: 'smooth',
          block: `${isItMobile ? 'start' : 'center'}`,
        });
      }
      // Reset currentMissingIndex from refs
      modifierGroupScrolling.current.currentMissingIndex = null;

      return;
    }
    const {
      refToScrollToMissingRequiredArea,
      setIsCYOIRequiredModifierSelected,
    } = CYOIScrollToMissingRequiredArea;
    if (data.limitForCompareType === 'min') {
      setIsCYOIRequiredModifierSelected({
        status: data.status,
        highLightArea: data.missingGroupIndex,
      });
      let animatedOnScrollId;
      clearTimeout(animatedOnScrollId);
      animatedOnScrollId = setTimeout(() => {
        setIsCYOIRequiredModifierSelected({
          status: false,
          highLightArea: null,
        });
      }, 2000);
    }
    for (let i = 0; i < refToScrollToMissingRequiredArea.length; i++) {
      if (i === data.missingGroupIndex) {
        if (!missingRequiredRef.current.valForSearch[data.missingGroupIndex]) {
          missingRequiredRef.current.valForAccordian.add(
            `${data.missingGroupIndex}`,
          );
          missingRequiredRef.current.valForSearch[data.missingGroupIndex] =
            true;
        }
        const modalContainer = document.getElementById(
          'modal_handle_for_byo_items',
        );
        if (modalContainer) {
          refToScrollToMissingRequiredArea[i].current?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest',
          });

          // Ensure modal itself scrolls to the top
          modalContainer.scrollTop =
            refToScrollToMissingRequiredArea[i].current.offsetTop;
        } else {
          refToScrollToMissingRequiredArea[i].current?.scrollIntoView({
            behavior: 'smooth',
            block: `${isItMobile ? 'start' : 'center'}`,
          });
        }
        // Reset currentMissingIndex from refs
        missingRequiredRef.current.currentMissingIndex = null;
        break;
      }
    }
  }

  return {
    missingRequiredRef,
    modifierGroupScrolling,
    scrollToNextGroupOnExtendableSelection,
  };
};

export default AddBoxLunchIngredients;
