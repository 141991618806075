import {
  MinusCircle,
  PlusCircle,
  QuantityCaretDown,
  QuantityCaretUp,
  QuantityMinus,
  QuantityPlus,
} from 'src/assets/images/Svgs/svg';
import { MAX_BOX_LUNCH_QUANTITY } from 'src/constants';
import { ItemDetailForm } from 'src/models/item.model';

import FormField from '../FormFields/FormField';
import MultiTagSelect from '../MultiTagSelect/MultiTagSelect';
type propTypes = {
  formState: ItemDetailForm;
  handleFromFields: (formState: ItemDetailForm) => void;
  isBoxLunch?: boolean;
};

const OrderNameForm: React.FC<propTypes> = ({
  formState,
  handleFromFields,
}) => {
  const incrementChangeHandler = () => {
    formState.quantity = Number(formState.quantity) + 1;
    handleFromFields(formState);
  };

  const decrementChangeHandler = () => {
    if (formState.quantity > 1) {
      formState.quantity = formState.quantity - 1;
      handleFromFields(formState);
    }
  };

  return (
    <>
      <h3 className="f-s20 font-Cls fw-normal clr-dark-gray mb-0 f-sm-s18">
        Quantity
      </h3>
      <div className="new_form_design">
        <div className="position-relative">
          <FormField
            type={'number'}
            inputFieldClass={
              formState?.quantity < 1 ||
              formState?.quantity > MAX_BOX_LUNCH_QUANTITY
                ? 'input_error'
                : ''
            }
            onChange={(e) => {
              formState.quantity = e?.target?.value;
              handleFromFields(formState);
            }}
            placeholder="1"
            value={formState?.quantity}
            minValue={1}
            onWheel={(event) => event.currentTarget.blur()}
          />
          <div className="quantityCarets">
            <QuantityMinus onClick={decrementChangeHandler} />
            <QuantityPlus onClick={incrementChangeHandler} />
          </div>
        </div>
        <>
          <div className="d-flex justify-content-between mt-4 user-select-none">
            <h3 className="f-s20 font-Cls fw-normal clr-dark-gray mb-0 f-sm-s18 mb-1">
              Name(s)
            </h3>
            <p className="f-s14 mb-0">(Optional)</p>
          </div>
          <MultiTagSelect
            formState={formState}
            handleFromFields={handleFromFields}
            fontSizeLabel={'f-s16'}
          />
        </>
        <div className="d-flex justify-content-between">
          <h3 className="f-s20 font-Cls fw-normal clr-dark-gray mb-0 f-sm-s18">
            Special Instructions
          </h3>
          <p className="f-s14 mb-0">(Optional)</p>
        </div>

        <FormField
          inputFieldClass={
            formState?.instructions.length > 150 ? 'input_error' : ''
          }
          type="text"
          placeholder="150 Characters"
          value={formState?.instructions}
          onChange={(e) => {
            if (e?.target?.value.length > 150) return;
            formState.instructions = e?.target?.value;
            handleFromFields(formState);
          }}
          name="name"
        />
      </div>
    </>
  );
};
export default OrderNameForm;
