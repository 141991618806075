import React from 'react';

import {
  PRIVACY_POLICY_ROUTE,
  REWARDS_DETAILS_ROUTE,
  ROUTE_CAREERS,
  ROUTE_CATERING,
  ROUTE_CATERING_REQUEST_FORM,
  ROUTE_FUNDRAISING,
  ROUTE_HOME,
  ROUTE_LOCATIONS,
  ROUTE_MENU,
  ROUTE_MENU_CATERING,
  ROUTE_ORDER_LANDING,
  ROUTE_ORDER_ONLINE,
  ROUTE_OUR_KITCHEN,
  ROUTE_TALK_TO_US,
  TERMS_ROUTE,
  TERMS_ROUTE_HTML,
} from '../../constants';
/**
 * TODO: Add meta tags for each page for SEO optimization
 * META_TAGS["KEY NAME"] KEY NAME should match the exact pathname
 */
export const META_TAGS: { [key: string]: JSX.Element[] } = {
  '/menu/Sandwiches': [
    <title>Grilled Panini & Gourmet Sandwiches — Café Zupas</title>,
    <meta name="keywords" content="gourmet sandwich"></meta>,
  ],
  '/menu/Bowls': [
    <title>Protein Bowls [Keto Options] — Café Zupas</title>,
    <meta name="keywords" content="protein bowls"></meta>,
  ],
  "/menu/Kid's+Combo": [
    <title>Kids Combo Meal & Menu — Café Zupas</title>,
    <meta name="keywords" content="cafe zupas kids menu"></meta>,
  ],
  '/menu/Seasonal': [
    <title>Seasonal Menu — Flavors For A Limited Time — Café Zupas</title>,
    <meta name="keywords" content="seasonal menu"></meta>,
  ],
  '/menu/Soups': [
    <title>House-Made, Globally-Inspired Soups — Café Zupas</title>,
    <meta name="keywords" content="zupas soup"></meta>,
  ],
  '/menu/Salads': [
    <title>Chef-Crafted Fresh Salads — Café Zupas</title>,
    <meta name="keywords" content="fresh salads"></meta>,
  ],
  '/menu/Desserts+&+Sides': [
    <title>Desserts and Sides — Café Zupas</title>,
    <meta name="keywords" content="dessert cafe"></meta>,
  ],
  '/nutrition': [<title>Nutrition Facts — Café Zupas</title>],
};

META_TAGS[ROUTE_OUR_KITCHEN] = [
  <title>Our Kitchen: House-Made, Quality Ingredients — Café Zupas</title>,
  <meta name="keywords" content="cafe ingredients"></meta>,
];
META_TAGS[ROUTE_MENU] = [
  <title>Menu — Salads, Soups, Sandwiches, Protein Bowls — Café Zupas</title>,
  <meta name="keywords" content="cafe zupas menu"></meta>,
];
META_TAGS[ROUTE_LOCATIONS] = [
  <title>Locations Near Me — Café Zupas</title>,
  <meta name="keywords" content="zupas near me"></meta>,
];
META_TAGS[ROUTE_ORDER_ONLINE] = [
  <title>Order Online — Café Zupas</title>,
  <meta name="keywords" content="zupas order online"></meta>,
];
META_TAGS[ROUTE_ORDER_LANDING] = [
  <title>Start An Online Order — Café Zupas</title>,
  <meta name="keywords" content="zupas order online"></meta>,
];
META_TAGS[ROUTE_CAREERS] = [
  <title>Careers — We're Hiring! — Café Zupas</title>,
  <meta name="keywords" content="zupas hiring"></meta>,
];
META_TAGS[ROUTE_MENU_CATERING] = [
  <title>Catering Menu — Boxed Lunches & Party Salads — Café Zupas</title>,
  <meta name="keywords" content="zupas catering menu"></meta>,
];
META_TAGS[ROUTE_CATERING] = [
  <title>Catering — Variety Sandwiches, Soups and Salads — Café Zupas</title>,
  <meta name="keywords" content="zupas catering"></meta>,
];
META_TAGS[ROUTE_HOME] = [
  <title>Soup, Salad and Sandwich Kitchen & Catering — Café Zupas</title>,
  <meta name="keywords" content="soup and salad"></meta>,
];
META_TAGS[ROUTE_FUNDRAISING] = [
  <title>Fundraiser Events — Support Your Local School — Café Zupas</title>,
  <meta name="keywords" content="fundraiser events"></meta>,
];
META_TAGS[REWARDS_DETAILS_ROUTE] = [
  <title>Catering Rewards — Café Zupas</title>,
  <meta name="keywords" content="catering rewards"></meta>,
];
META_TAGS[ROUTE_TALK_TO_US] = [<title>Contact Us — Café Zupas</title>];
META_TAGS[ROUTE_CATERING_REQUEST_FORM] = [
  <title>Request Catering — Café Zupas</title>,
];
META_TAGS[TERMS_ROUTE_HTML] = [<title>Terms & Conditions — Café Zupas</title>];
META_TAGS[TERMS_ROUTE] = [<title>Terms & Conditions — Café Zupas</title>];
META_TAGS[PRIVACY_POLICY_ROUTE] = [<title>Privacy Policy — Café Zupas</title>];
