import React, { useState } from 'react';

import { substitutedAddedModifiers } from '../../../helper/helperMethods';
import { toFixedNumber } from '../../../priceCalculation/helper';
import styleClasses from '../CartItems/CartItems.module.scss';

import ComboItemModifiers from './ComboItemModifiers';
import { FULL_SIZE } from 'src/constants';
import { getSizeName, getSizeNameInCaseOfHalf } from 'src/helper/utils';

interface ICartComboModifiers {
  modifiers: any[];
  isPackage: boolean;
}

const CartComboModifiers: React.FC<ICartComboModifiers> = ({
  modifiers,
  isPackage,
}) => {
  const [showMore, setShowMore] = useState(false);

  const handleShowMoreOrLess = () => {
    if (showMore) setShowMore(false);
    else setShowMore(true);
  };

  const extraPrice = (modifier) => {
    if (modifier?.price > 0) {
      return (
        <span className={`f-s14 font-rale text-end `}>
          +${toFixedNumber(modifier.price)}
        </span>
      );
    }
    return null;
  };

  let hasMore = false;

  const itemAsModifierSize = (item) => {
    const itemAsModifierSize = item?.modifier_size;
    return itemAsModifierSize === FULL_SIZE.id
      ? getSizeName(item?.category_name).upper_case
      : getSizeNameInCaseOfHalf(item?.category_name, itemAsModifierSize);
  };

  return (
    <ul className="list-inline f-s14 font-rale clr-dark-grey text-start mt-md-2 mt-0 ps-0 position-relative">
      {modifiers.map((modifier, i: number) => {
        if (!hasMore && modifier?.modifiers?.length > 1) hasMore = true;
        return (
          <li className="f-s14" key={i}>
            <div className="d-flex justify-content-between indent-first">
              <span className="f-s14 font-Visby-cf f-w5 clr-medium-gray">
                {modifier.modifier_name}
              </span>{' '}
              {extraPrice(modifier)}
            </div>
            {!!(isPackage && !modifier?.is_salad_tray) && (
              <span className="d-block f-s14 font-Visby-cf text-start f-w5 clr-medium-gray indent-second">
                {!!modifier?.is_item && itemAsModifierSize(modifier)}
              </span>
            )}
            {/* <p className={`clr-text-grey f-s14 font-Visby-cf d-flex flex-column text-center ${styleClasses.item_price_cart}`}> Extra <span className={`clr-red-dark f-s14 font-Vcf`}>$12.00</span></p> */}
            <ComboItemModifiers
              modifiers={substitutedAddedModifiers(modifier)}
              paragraphClassName={'f-s14 text-start mb-0 indent-second'}
              showMore={showMore}
              isPackage={isPackage}
            />
          </li>
        );
      })}
      {hasMore && (
        <span
          className="cursor-pointer clr-dark-red"
          onClick={handleShowMoreOrLess}
        >
          {!showMore ? 'see more' : 'see less'}
        </span>
      )}
    </ul>
  );
};

export default CartComboModifiers;
