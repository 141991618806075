import { createSlice } from '@reduxjs/toolkit';
import { appliedPaymentMethods } from 'src/helper/checkout/PaymentHelper';

import {
  floorNumberToDecimal,
  roundToTwo,
} from '../../../priceCalculation/helper';

const initialStateValues = {
  paymentMethods: [],
  giftCard: [],
  isSplitPayment: false,
  defaultGiftCard: {
    name: null,
    id: null,
    balance: null,
  },
  addPaymentUser: {
    authentication_token: '',
    type: null,
    id: '',
  },
};

export const addPaymentSlice = createSlice({
  name: 'addPayment',
  initialState: initialStateValues,
  reducers: {
    updateAddPaymentUserData: (state, action) => {
      state.addPaymentUser = {
        ...initialStateValues.addPaymentUser,
        authentication_token: action.payload.authentication_token,
        id: action.payload.customer_id,
      };
    },
    addPaymentMethodForTextToPayFlow: (state, action) => {
      state.paymentMethods.push(action.payload);
    },
    addGiftCardForTextToPayFlow: (state, action) => {
      state.giftCard.push(action.payload);
    },
    updatePaymentMethodForTextToPayFlow: (state, action) => {
      state.paymentMethods = action.payload;
    },
    updateGiftCardForTextToPayFlow: (state, action) => {
      state.giftCard = action.payload;
    },
    updatePaymentCardAmountForTextToPayFlow: (state, action) => {
      if (state.paymentMethods.length > 0) {
        const duplicatePayments = [...state.paymentMethods];
        duplicatePayments[action.payload.index].amount =
          action.payload.amountAdded;
        state.paymentMethods = duplicatePayments;
      }
    },
    updateGiftCardAmountForTextToPayFlow: (state, action) => {
      if (state.giftCard.length > 0) {
        const duplicateGiftCards = [...state.giftCard];
        duplicateGiftCards[action.payload.index].amount =
          action.payload.amountAdded;
        state.giftCard = duplicateGiftCards;
      }
    },
    updateIsSplitPaymentForTextToPayFlow: (state, action) => {
      state.isSplitPayment = action.payload;
    },
    updateAmountOnSplitForTextToPayFlow: (state, action) => {
      const totalCards = appliedPaymentMethods(
        state.paymentMethods,
        state.giftCard,
      );
      const lastNumber = action.payload.totalAmount.toString();
      const isOddAmount =
        Number(lastNumber[lastNumber.length - 1]) % 2 != 0 &&
        totalCards.length > 0;
      const isRounded = false;
      const paymentCards = [...state.paymentMethods];
      const giftCards = [...state.giftCard];
      const devidedAmount = floorNumberToDecimal(
        action.payload.totalAmount / totalCards.length,
      );
      // if one gift card is selected for Split. This code will verify the card balance
      if (totalCards.length > 0) {
        let giftCardAmount = 0;
        giftCards.map((card) => {
          giftCardAmount =
            state.defaultGiftCard?.balance >= devidedAmount
              ? devidedAmount
              : state.defaultGiftCard?.balance;
          card.amount = giftCardAmount;
        });
        paymentCards.map((card) => {
          if (!giftCards?.length) {
            card.amount = devidedAmount;
          } else {
            card.amount = roundToTwo(
              action.payload.totalAmount - giftCardAmount,
            );
          }
        });
      }
      //if total amount was odd number this code will make it round to two deicmel
      if (isOddAmount && state.defaultGiftCard?.balance >= devidedAmount) {
        if (paymentCards.length > 0)
          paymentCards[0].amount = roundToTwo(
            action.payload.totalAmount / totalCards.length,
          );
        else
          giftCards[0].amount = roundToTwo(
            action.payload.totalAmount / totalCards.length,
          );
      }
      state.paymentMethods = paymentCards;
      state.giftCard = giftCards;
    },
    updateDefaultGiftCardForTextToPayFlow: (state, action) => {
      state.defaultGiftCard = action.payload;
    },
    resetCheckoutForTextToPayFlow: (state) => {
      return {
        paymentMethods: [],
        giftCard: [],
        isSplitPayment: false,
        defaultGiftCard: {
          name: null,
          id: null,
          balance: null,
        },
        addPaymentUser: {
          authentication_token: '',
          type: null,
          id: '',
        },
      };
    },
    removeAddPaymentUserData: (state) => {
      state.addPaymentUser = {
        authentication_token: '',
        type: null,
        id: '',
      };
    },
  },
});

export const {
  addPaymentMethodForTextToPayFlow,
  addGiftCardForTextToPayFlow,
  updatePaymentMethodForTextToPayFlow,
  updateGiftCardForTextToPayFlow,
  updatePaymentCardAmountForTextToPayFlow,
  updateAmountOnSplitForTextToPayFlow,
  updateGiftCardAmountForTextToPayFlow,
  resetCheckoutForTextToPayFlow,
  updateIsSplitPaymentForTextToPayFlow,
  updateDefaultGiftCardForTextToPayFlow,
  removeAddPaymentUserData,
  updateAddPaymentUserData,
} = addPaymentSlice.actions;

export const addPaymentSliceReducer = addPaymentSlice.reducer;
