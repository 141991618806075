import { useMutation } from 'react-query';

import { orderMicroService } from '../services';

const exitEditOrderHelper = async (id: number) => {
  const response = await orderMicroService.exitEditOrder(id);
  return response.data.data;
};

export default function useExitEditOrder() {
  return useMutation((data: { id }) => exitEditOrderHelper(data.id));
}
