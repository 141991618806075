import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import CustomModal from 'src/components/customModal/CustomModal';
import { REQUIRED_MODIFIERS } from 'src/constants';
import OtherDressing from 'src/containers/Modifiers/ItemDressing';
import { IAddToBoxLunches, IModifiersObjects } from 'src/models/try2Combo';
import { itemBuckets } from 'src/priceCalculation/buckets';
import { ADDITIONAL_ADDED } from 'src/priceCalculation/constants';

import stylesClasses from '../../boxlunches.module.scss';

import Sliders from './Sliders';

const AddToBoxLunches: React.FunctionComponent<IAddToBoxLunches> = (props) => {
  const {
    showCustomizationModal,
    closeCustomizationModal,
    requiredModifierGroups,
    handleIngredientSelection,
    addItemtoCombo,
    sectionSelected,
    isShowingRequiredModalOnCustomization,
    noDressingHandler,
    otherDreesingState,
    isInItemPresentState,
    cleaningForRequiredModifiers,
    styles,
    setSelectedItem,
  } = props;
  const dispatch = useDispatch();
  const { otherDressingData, setOtherDressingData } = otherDreesingState;
  const { isInItemPresent, setIsInItemPresent } = isInItemPresentState;
  const [selectedOtherDressingData, setSelectedOtherDressingData] = useState<
    IModifiersObjects[]
  >([]);

  useEffect(() => {
    if (requiredModifierGroups && requiredModifierGroups.length > 0) {
      const modifiers =
        requiredModifierGroups[0]?.required_modifiers_groups_modifiers;
      const inItemFlag = modifiers.filter((modifier) => modifier.in_item);
      inItemFlag.length > 0
        ? setIsInItemPresent(true)
        : setIsInItemPresent(false);
    }
  }, [requiredModifierGroups, showCustomizationModal]);

  const updateSelectedModifiers = (modifier) => {
    updateSelectedOtherDressingData(modifier);
    handleIngredientSelection(modifier);
  };

  const updateSelectedOtherDressingData = (modifier) => {
    const index = selectedOtherDressingData?.findIndex(
      (selectedModifier) =>
        selectedModifier.modifier_id === modifier.modifier_id,
    );
    if (index !== -1) {
      selectedOtherDressingData[index] = modifier;
      setSelectedOtherDressingData([...selectedOtherDressingData]);
    } else {
      setSelectedOtherDressingData([...selectedOtherDressingData, modifier]);
    }
  };

  const addSelectedIngredients = () => {
    if (!isShowingRequiredModalOnCustomization) {
      setOtherDressingData(false);
      setSelectedOtherDressingData([]);
    }
    addItemtoCombo();
  };

  const closeModal = () => {
    cleaningForRequiredModifiers();
    closeCustomizationModal();
    // setSelectedItem('');
  };

  const otherDressingHandler = () => {
    itemBuckets.createBucketCopy();
    setOtherDressingData(true);
  };

  const selectedIngredients = (function () {
    return itemBuckets.specificItemBucketSelectedModifiers(
      REQUIRED_MODIFIERS,
      sectionSelected,
    );
  })();
  const selectedAddedIngredients = (function () {
    const fromItem: any = parseInt(sectionSelected) - 1;
    return itemBuckets.getSingleBucketKeyValue({
      name: REQUIRED_MODIFIERS,
      fromItem,
      modifierType: ADDITIONAL_ADDED,
      key: 'modifiers',
    });
  })();

  const noDressingModifiers = (function () {
    const requiredModifiersBucket = itemBuckets.getSingleBucket({
      name: REQUIRED_MODIFIERS,
      fromItem: parseInt(sectionSelected),
    });
    return requiredModifiersBucket?.noDressingModifiers;
  })();

  const sorted = (required_modifiers_groups_modifiers) => {
    // Remove quantity keyword
    for (let i = 0; i < required_modifiers_groups_modifiers.length; i++) {
      if (required_modifiers_groups_modifiers[i].quantity) {
        delete required_modifiers_groups_modifiers[i].quantity;
      }
    }
    // Add selected quantity to modifier
    for (let i = 0; i < required_modifiers_groups_modifiers.length; i++) {
      for (let j = 0; j < selectedIngredients.length; j++) {
        if (!required_modifiers_groups_modifiers[i].quantity) {
          if (
            required_modifiers_groups_modifiers[i].id ===
            selectedIngredients[j].modifier_id
          ) {
            required_modifiers_groups_modifiers[i].quantity =
              selectedIngredients[j].quantity;
          } else {
            required_modifiers_groups_modifiers[i].quantity = 0;
          }
        }
      }
    }
    required_modifiers_groups_modifiers.sort((a, b) => {
      return b.quantity - a.quantity;
    });
  };

  const items_required_modifier_groups =
    requiredModifierGroups &&
    requiredModifierGroups.length > 0 &&
    requiredModifierGroups[0];
  return (
    <CustomModal
      showModal={showCustomizationModal}
      closeModal={closeModal}
      title={''}
      size="lg"
      modalBodyClass={stylesClasses.boxCustomizeModal}
      modalDialogClasses={stylesClasses.boxCustomizationWraper}
      scrollable={false}
    >
      <div
        className={` ${!otherDressingData && 'mb-4 mb-sm-5 '} ${
          stylesClasses.scrollDiv
        }`}
      >
        {otherDressingData ? (
          <>
            <OtherDressing
              requiredModifierGroup={items_required_modifier_groups}
              setOtherDressingData={() => {
                // removeSelectedIngredients();
                sorted(
                  requiredModifierGroups[0].required_modifiers_groups_modifiers,
                );
                setOtherDressingData(false);
              }}
              otherDressingHandler={otherDressingHandler}
              noDressingHandler={noDressingHandler}
              selectedIngredients={selectedIngredients}
              selectedAddedIngredients={selectedAddedIngredients}
              handleIngredientSelection={(data) =>
                updateSelectedModifiers(data)
              }
              sectionSelected={sectionSelected}
              selectedOtherDressingData={selectedOtherDressingData}
              addSelectedIngredients={addSelectedIngredients}
              label={items_required_modifier_groups?.label}
            />
          </>
        ) : (
          <>
            <Sliders
              items_required_modifier_groups={requiredModifierGroups}
              otherDressingHandler={otherDressingHandler}
              selectedIngredients={selectedIngredients}
              handleIngredientSelection={handleIngredientSelection}
              setOtherDressingData={() => setOtherDressingData(false)}
              selectedAddedIngredients={selectedAddedIngredients}
              isInItemPresent={isInItemPresent}
              sectionSelected={sectionSelected}
              noDressingHandler={noDressingHandler}
              noDressingModifiers={noDressingModifiers}
              addItemtoCombo={addItemtoCombo}
              addSelectedIngredients={addSelectedIngredients}
              label={items_required_modifier_groups?.label}
            />
          </>
        )}
      </div>
    </CustomModal>
  );
};
export default AddToBoxLunches;
