import React from 'react';
import { Col } from 'react-bootstrap';
import ItemGrid from 'src/Features/Menu/ItemGrid/ItemGrid';
import { s3BaseUrl } from 'src/constants';
import { checkIsItemActive } from 'src/helper/customizedItem/customizedItem.helper';
import { ISubCategories, ItemDetails } from 'src/models/item.model';
import { useAppSelector } from 'src/redux/store/store';

type ItemsTypes = {
  items: ISubCategories;
  styles: CSSModuleClasses;
  handleChooseDressing: () => void;
  handleCustomize: (item: any) => void;
  addToComboClickHandler?: (item: ItemDetails) => void;
};

const Items: React.FC<ItemsTypes> = (props) => {
  const { items, styles, handleChooseDressing, handleCustomize } = props;
  const isOrderTypeSelected = useAppSelector(
    (state) => state.cart.isOrderTypeSelected,
  );
  const savedOrderType = useAppSelector((state) => state.cart.orderType);
  const orderType = isOrderTypeSelected ? savedOrderType : '';
  return (
    <div className="row">
      {items?.items?.map((data, index) => {
        return (
          <Col xl="4" md="6" lg="6" sm="12" className="mb-4" key={index}>
            <ItemGrid
              favIcon={`${styles.fav_icon_fill}`}
              itemTitle={data.name}
              itemImage={`${s3BaseUrl}${data?.image}`}
              itemDescription={data.description}
              selectButton="add to box"
              customizeButton="customize"
              handleChooseDressing={handleChooseDressing}
              handleCustomize={handleCustomize}
              item={data}
              disableItem={!checkIsItemActive(data, orderType)}
              addToComboClickHandler={props.addToComboClickHandler}
              disabledParentClick={false}
            />
          </Col>
        );
      })}
    </div>
  );
};

export default Items;
