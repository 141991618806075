import { useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import {
  FabCloseIcon,
  FabIcon,
  RewardIcon,
  TimerIcon,
} from 'src/assets/images/Svgs/svg';

import * as ROUTE_CONSTANT from '../../constants';
import { ROUTE_PROFILE_RECENT_ORDERS } from '../../constants';

import styles from './floatingActionBtn.module.scss';

const FloatingActionBtn = () => {
  const [actionButton, setActionButton] = useState<boolean>(false);
  const location = useLocation();

  const history = useHistory();

  const rewardsHandle = () => {
    if (actionButton === true) {
      setActionButton(false);
    }
    history.push(ROUTE_CONSTANT.ROUTE_LOYALTY);
  };
  const recentHandler = () => {
    if (actionButton === true) {
      setActionButton(false);
    }
    history.push(ROUTE_CONSTANT.ROUTE_PROFILE_RECENT_ORDERS);
  };

  return (
    <div
      className={`${
        location.pathname.startsWith(ROUTE_CONSTANT.ROUTE_OTHER_ITEM)
          ? styles.itemDetailFab
          : ''
      } ${styles.fabWrapper}`}
    >
      <input
        id="fabCheckbox"
        type="checkbox"
        className={styles.fabCheckbox}
        onChange={(e) => setActionButton(e.target.checked)}
        checked={actionButton}
      />
      <label className={styles.fab} htmlFor="fabCheckbox">
        <div className={styles.menuIcon}>
          <FabIcon />
        </div>
        <div className={styles.fabCloseBtn}>
          <FabCloseIcon />
        </div>
      </label>
      <div className={styles.fabWheel}>
        <button
          type="button"
          className={`${styles.fabAction} ${styles.fabAction2}`}
          onClick={rewardsHandle}
        >
          <RewardIcon height="18" width="18" className="cz-mr-8" />
          Rewards
        </button>
        <button
          type="button"
          className={`${styles.fabAction} ${styles.fabAction3}`}
          onClick={recentHandler}
        >
          <TimerIcon height="18" width="18" className="cz-mr-8" />
          Recents
        </button>
      </div>
      <div className={styles.fabOverlay}></div>
    </div>
  );
};
export default FloatingActionBtn;
