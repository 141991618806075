import { FC } from 'react';
import { Modal } from 'react-bootstrap';

import { ModalProps } from './types';

const CustomModal: FC<ModalProps> = ({
  showModal,
  title,
  closeModal,
  children,
  modalBodyClass,
  modalDialogClasses,
  size,
  titletextAlign,
  showClose = true,
  scrollable,
  headerClasss,
  id,
}) => {
  return (
    <Modal
      scrollable={scrollable}
      show={showModal}
      centered
      className={`${
        modalDialogClasses ? modalDialogClasses : ''
      } theme-modal-style app_container_wrap  border-radius-15 px-5"`}
      onHide={showClose && closeModal}
      size={size}
      id={id}
    >
      <Modal.Header className={`${headerClasss} px-5`}>
        {showClose && (
          <button
            type="button"
            className="btn modal-close-button pe-0"
            onClick={closeModal}
          >
            close
          </button>
        )}
        <h5
          className={`f-s22 font-Cls ${titletextAlign} mb-0 text-capitalize full_width_mob`}
        >
          {title}
        </h5>
      </Modal.Header>
      <Modal.Body
        className={`${modalBodyClass ? modalBodyClass : 'px-lg-5 '} px-0 pt-0`}
      >
        {children}
      </Modal.Body>
    </Modal>
  );
};

export default CustomModal;
