import React from 'react';

import Actions from './Actions';
import styles from './CounterCard.module.scss';
import { InfoOutline } from 'src/assets/images/Svgs/svg';

type proptypes = {
  id?: string;
  name?: string;
  image?: any;
  modifierAction: {
    onAdded: () => void;
    onRemove: () => void;
  };
  isSelected: boolean;
  count: number;
  limitCompleted: boolean | undefined;
  isActive: boolean;
  selectedItem?: any;
  showRedBorderAnimation: boolean;
  infoIconClick?: (e) => void;
};

const CounterCard: React.FC<proptypes> = (props) => {
  const {
    id,
    name,
    image,
    modifierAction,
    isSelected,
    count,
    limitCompleted,
    isActive,
    selectedItem,
    showRedBorderAnimation,
    infoIconClick,
  } = props;

  const onCardClick = () => {
    !count && isActive && !limitCompleted && modifierAction.onAdded();
  };

  return (
    <>
      <div
        className={`${styles.counter_card_wrapper} ${
          !isActive && styles.unavailableItems
        } ${isSelected && styles.counter_card_select} ${
          limitCompleted && !isSelected && styles.disabled
        } ${showRedBorderAnimation && !count ? 'border-size-select' : ''}`}
        key={id}
        onClick={onCardClick}
      >
        <div
          onClick={infoIconClick}
          className={`d-md-block d-none ${styles.info_outline}`}
        >
          <InfoOutline />
        </div>
        <div className={styles.items_image}>
          <img src={image} alt="img" />
        </div>

        <div className={`${styles.item_increments} w-100   `}>
          <span
            className={`f-s14 font-rale text-center  mb-2 text-capitalize ${styles.counter_card_title}`}
          >
            {name.toLowerCase()}
          </span>
          <div className="d-flex justify-content-center gap-md-5 gap-3 mb-2 ">
            <Actions
              styles={styles}
              count={count}
              modifierAction={modifierAction}
              isActive={isActive}
              limitCompleted={limitCompleted}
            />
          </div>
          <h6
            className={`w-100 text-right f-s10 font-rale d-flex justify-content-between mb-0 lh-base text-nowrap ${styles.addtiinalPrices}`}
          >
            {selectedItem?.additionalPrice ? (
              <span className="text-danger font-Visby-cf f-w5 w-100 text-end">
                ${selectedItem?.additionalPrice}
              </span>
            ) : null}
          </h6>
        </div>
      </div>
    </>
  );
};

export default CounterCard;
