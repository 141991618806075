import React, { KeyboardEventHandler, useEffect, useRef } from 'react';
import { useLocation } from 'react-router';
import CreatableSelect from 'react-select/creatable';
import { AddPlusIcon } from 'src/assets/images/Svgs/svg';
import { ItemDetailForm } from 'src/models/item.model';
import styles from './MultiTagSelect.module.scss';

const components = {
  DropdownIndicator: null,
};

interface Option {
  readonly label: string;
  readonly value: number;
}

const createOption = (label: string, value: number) => ({
  label,
  value: value,
});

interface MultiTagSelectProps {
  formState: ItemDetailForm;
  handleFromFields: any;
  fontSizeLabel?: string;
}
const regeNamePtrn = /^[a-zA-Z0-9\s]+$/;

const MultiTagSelect: React.FC<MultiTagSelectProps> = ({
  formState,
  handleFromFields,
  fontSizeLabel,
}) => {
  const { search } = useLocation();

  const query = new URLSearchParams(search);
  const dbId = query.get('id');

  const updateTagsFirstTime = useRef(false);

  const [inputValue, setInputValue] = React.useState('');
  const [value, setValue] = React.useState<readonly Option[]>([]);
  const idCounter = useRef(0);

  useEffect(() => {
    if (formState.reset) {
      setValue([]);
      idCounter.current = 0;
    }
    if (dbId && formState.name.length > 0 && !updateTagsFirstTime.current) {
      updateTagsFirstTime.current = true;
      const options = [];
      for (let index = 0; index < formState.name.length; index++) {
        options.push(createOption(formState.name[index], idCounter.current));
        idCounter.current = idCounter.current + 1;
      }
      setValue(options);
    }
  }, [dbId, formState]);

  useEffect(() => {
    if (formState.reset) {
      idCounter.current = 0;
      return;
    }
    const newState = [];
    for (let index = 0; index < value.length; index++) {
      newState.push(value[index].label);
    }
    formState.name = newState;
    handleFromFields(formState);
  }, [value]);
  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        if (!regeNamePtrn.test(inputValue)) break;
        formState.reset = false;
        setValue((prev) => [
          ...prev,
          createOption(inputValue, idCounter.current),
        ]);
        idCounter.current = idCounter.current + 1;

        setInputValue('');
        event.preventDefault();
    }
  };

  const onChange = (newValue) => {
    setValue(newValue);
  };

  const onInputChange = (newValue, action) => {
    if (action === 'input-change') {
      setInputValue(newValue);
    }
  };
  const handleSubmit = () => {
    if (!regeNamePtrn.test(inputValue) || !inputValue) return;
    formState.reset = false;
    setValue((prev) => [...prev, createOption(inputValue, idCounter.current)]);
    idCounter.current = idCounter.current + 1;
    setInputValue('');
  };

  return (
    <>
      <div className="form-group mb-0 position-relative">
        <CreatableSelect
          components={components}
          inputValue={inputValue}
          isMulti
          isClearable={false}
          menuIsOpen={false}
          onChange={(newValue) => onChange(newValue)}
          onInputChange={(newValue, { action }) =>
            onInputChange(newValue, action)
          }
          onKeyDown={handleKeyDown}
          value={value}
          onBlur={handleSubmit}
          placeholder={'Name(s)'}
          className={`form-control custom_multi_select name_select_feild ${fontSizeLabel}`}
          classNamePrefix="custom-creatable"
        />
        <div className={`${styles.addPlusIcon}`} onClick={handleSubmit}>
          <AddPlusIcon />
        </div>
        <span className={'clr-dark-red f-s14 font-rale mt-1 d-inline-block'}>
          {!regeNamePtrn.test(inputValue) &&
            inputValue &&
            'Name must not include any special chracters'}
        </span>
      </div>
    </>
  );
};
export default MultiTagSelect;

// handleFromFields: (formState: ItemDetailForm) => void;
//    const onClick = (newValue) => {
//      ;
//     formState.name = newValue;
//     handleFromFields(formState);
//   };
