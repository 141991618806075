import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import { useAppSelector } from 'src/redux/store/store';

import { ROUTE_MENU } from '../../constants';

interface PrivateRouteProps extends RouteProps {
    component: any;
}

const RequiresLocation: React.FunctionComponent<PrivateRouteProps> = ({
    component: Component,
    ...restOfProps
}) => {
    const locationInfo = useAppSelector((state) => state.location);
    const { location } = restOfProps;
    const query = new URLSearchParams(location.search);
    const category_id = query.get('category_id');
    return (
        <Route
            {...restOfProps}
            render={(props) =>
                !locationInfo?.selectedStore?.id ?
                    (
                        <Redirect
                            to={{
                                pathname: ROUTE_MENU,
                                state: {
                                    referrer: location.pathname,
                                    category_id: category_id,
                                },
                            }}
                        />
                    )
                    :
                    (
                        <Component {...props} />
                    )
            }
        />
    );
};

export default RequiresLocation;
