import React from 'react';
import { MapIcon } from 'src/assets/images/Svgs/svg';

import styles from './location.module.scss';

interface ILocationMarker {
  lat?: string | number;
  lng?: string | number;
}

const LocationMarker: React.FC<ILocationMarker> = () => {
  return (
    <>
      <div className={`${styles.location_marker}`}>
        <MapIcon />
      </div>
    </>
  );
};

export default LocationMarker;
