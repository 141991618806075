import { useQuery } from 'react-query';
import { orderMicroService } from '../services';

const verifyPaymentLink = async (id: string) => {
  const response = await orderMicroService.verifyPaymentLink(id);
  return response.data.data;
};

export default function useVerifyPaymentLink(id: string) {
  return useQuery(id, () => verifyPaymentLink(id), {
    retry: false,
    enabled: Boolean(id),
  });
}
