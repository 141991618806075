import { useState } from 'react';
import { Button, Collapse, Nav, Navbar } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import {
  ArrowDown,
  ArrowRightIcon,
  NotesIcon,
} from 'src/assets/images/Svgs/svg';
import * as CONSTANT from 'src/constants';
import useCheckMobileScreen from 'src/hooks/useCheckMobileScreen';
import useGetMenuCategories from 'src/react-query-hooks/useGetCategories';
import { useAppSelector } from 'src/redux/store/store';
import { awsAssetsFetcher, formatImageName } from 'src/utils/awsAssetsFetcher';
import GuestNavComponent from './GuestNavComponent';
import styles from './NavbarComponent.module.scss';
import UserNavComponent from './UserNavComponent';

const NavComponent = () => {
  const { user } = useAppSelector((state) => state.user);
  const userId = user?.id ?? '';
  const userType = user?.type ?? '';

  const [expanded, setExpanded] = useState(false);

  const [open, setOpen] = useState(false);
  const [isHamburger, setIsHamburger] = useState(true);
  const toggleHamburger = () => {
    setIsHamburger(!isHamburger);
  };
  const { data: subMenus = [] } = useGetMenuCategories();

  const history = useHistory();

  const isItMobile = useCheckMobileScreen();
  const handleClick = (href: string) => {
    if (
      href === 'https://cafezupas.com/' ||
      href.startsWith('https://cafezupas.com/')
    ) {
      window.open(href, '_blank');
    } else {
      history.push(href);
    }
    isItMobile && expandHandler();
  };
  const menu = [
    {
      item: 'OUR Menu',
      href: '/menu',
    },
    {
      item: 'loyalty',
      href: '/loyalty',
    },
    {
      item: 'OUR KITCHEN',
      href: 'https://cafezupas.com/ourKitchen',
    },
    {
      item: 'FUNDRAISERS',
      href: 'https://cafezupas.com/fundraising',
    },
    {
      item: 'Individual',
      href: 'https://cafezupas.com/',
    },
    {
      item: 'CAREERS',
      href: 'https://cafezupas.com/careers',
    },
  ];
  const handleMenuClick = (subMenu) => {
    history.push(
      `${CONSTANT.ROUTE_MENU}/${subMenu.name.replace(/\s/g, '%20')}`,
    );
    history.go(0);
    setExpanded(false);
  };

  const expandHandler = () => {
    setExpanded(!expanded);
    setIsHamburger(!isHamburger);
  };

  return (
    <>
      <Navbar
        expand="md"
        // expanded={expanded ? expanded : false}
        className={`justify-content-center pb-0 pt-0 ${styles.nav_wrapper} ${styles.header_content}`}
      >
        <Navbar.Toggle
          className={` p-0 ${styles.nav_toggle_btn}`}
          aria-controls="basic-navbar-nav"
          onClick={expandHandler}
        >
          <div
            id={styles.navIcon}
            className={!isHamburger ? styles.open : ''}
            onClick={toggleHamburger}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
        </Navbar.Toggle>
        <Navbar.Collapse
          id="basic-navbar-nav"
          className={`${expanded ? 'd-block' : 'd-none'}`}
        >
          <hr className="shadow-divider  border-0 mb-2 mt-0" />

          <div className="d-md-none ">
            <a
              onClick={() => setOpen(!open)}
              className={`${styles.submenuToggleBtn} ${styles.sub_menu_btn}`}
              aria-controls="example-collapse-text"
              aria-expanded={open}
            >
              Our Menu
              <span className="fa fa-caret-down right">
                <ArrowDown />
              </span>
            </a>

            <Collapse in={open}>
              <ul
                className={`${styles.sub_menu_list} ps-0`}
                id="example-collapse-text"
              >
                <div className="d-flex justify-content-center">
                  <button
                    className="btn btn-large outline my-4"
                    onClick={() => {
                      history.push(CONSTANT.ROUTE_MENU);
                      setExpanded(false);
                    }}
                  >
                    view Full menu{' '}
                  </button>
                </div>
                {subMenus.length > 0 ? (
                  subMenus.map((subMenu) => {
                    return (
                      <li
                        key={subMenu.name}
                        className={`${styles.sub_menu_item} `}
                        onClick={() => handleMenuClick(subMenu)}
                      >
                        <div className={styles.menuInnerDiv}>
                          <div className="d-flex align-items-center gap-2">
                            <img
                              src={awsAssetsFetcher(
                                `categories/${formatImageName(subMenu.name)}`,
                                'png',
                              )}
                              className="img-fluid"
                              alt={subMenu.name}
                            />
                            <h6>{subMenu.name} </h6>
                          </div>

                          <div className={styles.iconDiv}>
                            <ArrowRightIcon />
                          </div>
                        </div>
                      </li>
                    );
                  })
                ) : (
                  <h5 className="ps-3 text-style2">Loading...</h5>
                )}
                {userId && userType !== CONSTANT.GUEST_USER && (
                  <>
                    <li
                      className={styles.sub_menu_item}
                      onClick={() => {
                        history.push(CONSTANT.VIEW_MORE_RECENT_ORDER);
                        setExpanded(false);
                      }}
                    >
                      <div className={styles.menuInnerDiv}>
                        <div className="d-flex align-items-center gap-2">
                          <NotesIcon />
                          <h6>Recent Orders </h6>
                        </div>

                        <div className={styles.iconDiv}>
                          <ArrowRightIcon />
                        </div>
                      </div>
                    </li>
                    {/* <li
                      className={styles.sub_menu_item}
                      onClick={() => {
                        history.push(CONSTANT.ROUTE_PROFILE_DETAILS);
                        setExpanded(false);
                      }}
                    >
                      <div className={styles.menuInnerDiv}>
                        <div className="d-flex align-items-center gap-2">
                          <NotesIcon />
                          <h6>Favorite Orders </h6>
                        </div>

                        <div className={styles.iconDiv}>
                          <ArrowRightIcon />
                        </div>
                      </div>
                    </li> */}
                    
                  </>
                )}
              </ul>
            </Collapse>
            {menu.slice(1).map((item: any, i: any) => {
              return (
                <a
                  key={i}
                  className="clr-lt-black header-menu-font text-decoration-none text-nowrap text-uppercase pointer d-block"
                  onClick={() => handleClick(item.href)}
                >
                  {item.item}
                </a>
              );
            })}
            {userId && userType !== CONSTANT.GUEST_USER ? (
              <UserNavComponent
                userId={userId}
                userType={userType}
                expandHandler={expandHandler}
              />
            ) : (
              <GuestNavComponent expandHandler={expandHandler} />
            )}
          </div>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default NavComponent;
