import React, { Children, FC } from 'react';
import { Modal } from 'react-bootstrap';
import { AnyIfEmpty } from 'react-redux';

import Button from '../../components/Button/Button';

import Ingredients from './Ingredients/Ingredients';

interface ItemCustomizationModalProps {
  showModal: boolean;
  title: string;
  closeModal: () => void;
  children?: React.ReactNode;
  applyClickHandler?: () => void;
}

const ItemCustomizationModal: FC<ItemCustomizationModalProps> = ({
  showModal,
  closeModal,
  title,
  children,
  applyClickHandler,
}) => {
  return (
    <div>
      <Modal
        centered
        show={showModal}
        onHide={closeModal}
        className="theme-modal-style app_container_wrap item-customization-modal modal-dialog-scrollable"
      >
        <Modal.Header>
          <button
            type="button"
            className="btn modal-close-button pe-0"
            onClick={closeModal}
          >
            close
          </button>
        </Modal.Header>
        <h2 className="f-s22 font-Cls text-center mb-0 pt-2 text-capitalize ">
          {title}
        </h2>
        <Modal.Body>{children}</Modal.Body>
        <Modal.Footer>
          <Button onClick={applyClickHandler}>Apply</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ItemCustomizationModal;
