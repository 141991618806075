import { Field, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Col, Container, Form, Modal, Row } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import MaskedInput from 'react-text-mask';
import useCookie from 'react-use-cookie';
import Loader from 'src/components/Loader/Loader';
import { getUser } from 'src/helper/helperMethods';
import { Toast_Func } from 'src/helper/toast.helper';
import useCheckMobileScreen from 'src/hooks/useCheckMobileScreen';
import useKeypress from 'src/hooks/useKeypress';
import { SectionDataModel } from 'src/models/sections.model';
import useMarketLocations from 'src/react-query-hooks/useLocations';
import { customerBusinessService } from 'src/services';
import { UserFormValidationSchema } from 'src/validationSchemas/contactUsSchema';
import * as CONSTANT from '../../../constants';
import Confirmation from './Confirmation';
import styleClasses from './Popup.module.scss';

interface footerProps {
  showPopup: boolean;
  closePopup?: () => void;
}
interface UserFormData {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  location: string;
}

const EmailListModel: React.FunctionComponent<footerProps> = ({
  showPopup,
  closePopup,
}) => {
  useKeypress('Escape', () => {
    handleClose();
  });
  const isItMobile = useCheckMobileScreen();
  let history = useHistory();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [showConfirmation, setshowConfirmation] = useState(false);
  const [cookies, setCookie] = useCookie('EmailModal');
  const { data: locations, isFetching, isSuccess } = useMarketLocations();

  const defaultUserFormState: UserFormData = {
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    location: '',
  };
  const [Confirmation_data] = useState<SectionDataModel>({
    section_title: 'THANK YOU!',
    section_details: 'You are now on our mailing list.',
    sub_title: 'Work Up an Appetite?',
    button_class: '',
    Section_controller: 'pb-4 pb-sm-5',
    button_text: 'START AN ORDER',
    button_link: CONSTANT.ROUTE_ORDER_LANDING,
  });
  const [UserFormState, setUserFormState] =
    useState<UserFormData>(defaultUserFormState);
  const handleClose = () => {
    closePopup();
    setshowConfirmation(false);
  };

  const handCloseFooter = (e) => {
    e.preventDefault();
    handleClose();
    if (e.target.pathname == '/privacyPolicy') {
      history.push(CONSTANT.PRIVACY_POLICY_ROUTE);
    }
    if (e.target.pathname == '/terms') {
      history.push(CONSTANT.TERMS_ROUTE);
    }
    window.location.reload();
  };

  /**
   * update the tabindex of the buttons and anchor tags.
   */
  const updateTabIndex = (val: string) => {
    setTimeout(() => {
      document
        .querySelectorAll(
          `#root[aria-hidden=true] a[href],
        #root[aria-hidden=true] area[href],
        #root[aria-hidden=true] input:not([disabled]),
        #root[aria-hidden=true] select:not([disabled]),
        #root[aria-hidden=true] textarea:not([disabled]),
        #root[aria-hidden=true] button:not([disabled]),
        #root[aria-hidden=true] [tabindex]:not([disabled]),
        #root[aria-hidden=true] [contenteditable=true]:not([disabled])`,
        )
        .forEach(function (e) {
          // @ts-ignore: Unreachable code error
          e.tabIndex = val;
        });
    }, 600);
  };

  useEffect(() => {
    if (showPopup) {
      updateTabIndex('-1');
      if (cookies !== 'true') {
        setCookie('true');
      }
    }
  }, [showPopup, cookies]);

  useEffect(() => {
    if (isSuccess) {
      updateTabIndex('-1');
    }
  }, [isSuccess]);
  return (
    <>
      <div>
        <Row>
          <Col xs="12" sm="12" md="6">
            <Modal
              className={`${styleClasses.modal_box} ${styleClasses.newsletter_modal} footer-modal-wrap new_form_design`}
              show={showPopup}
              onClose={handleClose}
              backdrop="static"
              tabIndex="-1"
              keyboard={false}
              aria-labelledby="Newsletter2"
              aria-describedby="Description2"
              aria-label="close_button2"
              id="notes2"
            >
              <Modal.Header
                className={styleClasses.unset_style}
                id="close_button2"
              >
                <button
                  className={`${styleClasses.modal_close_button} border-0 close e-club-close-button p-0`}
                  onClick={handleClose}
                  aria-controls="notes2"
                >
                  Close
                </button>
              </Modal.Header>
              <Modal.Body
                className="app_container_wrap px-1 py-2"
                id="Description2"
              >
                <Container>
                  {showConfirmation ? (
                    ' '
                  ) : (
                    <Row>
                      <Col xs="12" sm="12" className="pt-4">
                        <h5
                          id="Newsletter2"
                          className={styleClasses.modal_title}
                        >
                          ALWAYS KNOW WHAT’S NEW!
                        </h5>
                        <p
                          className={`${styleClasses.modal_description} f-s14`}
                        >
                          Nourish the Good Life with exclusive Café Zupas
                          offers, updates and seasonal menus sent straight to
                          your inbox.
                        </p>
                      </Col>
                    </Row>
                  )}
                  {showConfirmation ? (
                    <Confirmation Confirmation_data={Confirmation_data} />
                  ) : isLoading || isFetching ? (
                    <Loader className={styleClasses.recentOrderSpinner} />
                  ) : (
                    <Row>
                      <Col xs="12" sm="12" className="text-center mt-3">
                        <Formik
                          onSubmit={async (
                            values,
                            { resetForm, setFieldValue, setSubmitting },
                          ) => {
                            setSubmitting(true);
                            setLoading(true);
                            try {
                              const user = getUser();
                              const payload = Object.assign(
                                {
                                  customer_id: user.id,
                                  visitor_id: user.visitor_id,
                                },
                                values,
                              );
                              await customerBusinessService.subscribeEmail(
                                payload,
                              );
                              resetForm();
                              setshowConfirmation(true);
                              document.body.scrollTop = 0;
                              document.documentElement.scrollTop = 0;
                            } catch (error) {
                              Toast_Func({
                                status: false,
                                message: error.response.data.message,
                              });
                            } finally {
                              setSubmitting(false);
                              setLoading(false);
                            }
                          }}
                          initialValues={UserFormState}
                          validationSchema={UserFormValidationSchema}
                        >
                          {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleSubmit,
                            isSubmitting,
                            setFieldValue,
                          }) => (
                            <React.Fragment>
                              <form onSubmit={handleSubmit}>
                                <Row>
                                  <Col
                                    md={6}
                                    xs={6}
                                    className="text-start pe-2"
                                  >
                                    <div className="form-group">
                                      <label htmlFor="first_name_sub_popup">
                                        FIRST NAME
                                      </label>
                                      <Form.Control
                                        id="first_name_sub_popup"
                                        type="text"
                                        name="first_name"
                                        value={values.first_name}
                                        onChange={handleChange}
                                      />
                                      {!touched || !touched.first_name ? (
                                        <span></span>
                                      ) : (
                                        <span className={styleClasses.error}>
                                          {errors.first_name}
                                        </span>
                                      )}
                                    </div>
                                  </Col>
                                  <Col
                                    md={6}
                                    xs={6}
                                    className="text-start ps-2"
                                  >
                                    <div className="form-group">
                                      <label htmlFor="last_name_sub_popup">
                                        LAST NAME
                                      </label>
                                      <Form.Control
                                        id="last_name_sub_popup"
                                        type="text"
                                        name="last_name"
                                        value={values.last_name}
                                        onChange={handleChange}
                                      />
                                      {!touched || !touched.last_name ? (
                                        <span></span>
                                      ) : (
                                        <span className={styleClasses.error}>
                                          {errors.last_name}
                                        </span>
                                      )}
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md={12} xs={12} className="text-start">
                                    <div className="form-group">
                                      <label htmlFor="email_sub_popup">
                                        EMAIL ADDRESS
                                      </label>
                                      <Form.Control
                                        id="email_sub_popup"
                                        type="text"
                                        name="email"
                                        value={values.email}
                                        onChange={handleChange}
                                      />
                                      {!touched || !touched.email ? (
                                        <span></span>
                                      ) : (
                                        <span className={styleClasses.error}>
                                          {errors.email}
                                        </span>
                                      )}
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col className="text-start">
                                    <label htmlFor="phone">Phone Number</label>
                                    <Field
                                      name="phone"
                                      render={({ field }) => (
                                        <MaskedInput
                                          {...field}
                                          mask={CONSTANT.PHONE_NUMBER_MASK}
                                          id="phone"
                                          placeholder="(223) 123-1234"
                                          type="text"
                                          value={values.phone.replace(
                                            /[() -]/g,
                                            '',
                                          )}
                                          onChange={(e) =>
                                            setFieldValue(
                                              'phone',
                                              e.target.value.replace(
                                                /[() -]/g,
                                                '',
                                              ),
                                            )
                                          }
                                          guide={true}
                                          className={`
                           ${
                             errors.phone && touched.phone
                               ? 'input_error'
                               : 'text-input'
                           }
                           form-control`}
                                        />
                                      )}
                                    />
                                    {errors.phone && touched.phone && (
                                      <span
                                        className={`f-s10 clr-dark-red d-block mb-1`}
                                      >
                                        {errors.phone}
                                      </span>
                                    )}
                                    <p className="f-s12 clr-text-grey mt-2 mb-4 float-left">
                                      *By entering your phone number and
                                      submitting this form, you agree to receive
                                      recurring automated marketing text
                                      messages from Cafe Zupas (e.g. cart
                                      reminders) at the phone number provided.
                                      Consent is not a condition to purchase.
                                      Msg & data rates may apply. Msg frequency
                                      varies. Reply HELP for help and STOP to
                                      cancel. View our{' '}
                                      <Link
                                        to={'/privacyPolicy'}
                                        className={styleClasses.modal_href}
                                        onClick={handCloseFooter}
                                      >
                                        Privacy Policy
                                      </Link>{' '}
                                      and{' '}
                                      <Link
                                        to={'/terms'}
                                        className={styleClasses.modal_href}
                                        onClick={handCloseFooter}
                                      >
                                        Terms of Service
                                      </Link>
                                      .
                                    </p>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col
                                    md={12}
                                    xs={12}
                                    className="text-start custom_select mb-4"
                                  >
                                    <div
                                      className={`${styleClasses.custom_react_select} form-group`}
                                    >
                                      <label htmlFor="select_location">
                                        Favorite location
                                      </label>
                                      <Form.Control
                                        value={values.location}
                                        className={`${styleClasses.inputField} input_select`}
                                        as="select"
                                        name="location"
                                        onChange={handleChange}
                                        id="select_location"
                                      >
                                        <option value="">
                                          Select Locations
                                        </option>
                                        {locations.map((state) => (
                                          <option
                                            value={state.id}
                                            key={state.id}
                                          >
                                            {state.state}: {state.name}
                                          </option>
                                        ))}
                                      </Form.Control>

                                      {!touched || !touched.location ? (
                                        <span></span>
                                      ) : (
                                        <span className={styleClasses.error}>
                                          {errors.location}
                                        </span>
                                      )}
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <button
                                      className={`${styleClasses.signup_button} btn-large dark-grey`}
                                      disabled={isSubmitting}
                                      type="submit"
                                      value="SIGN UP"
                                    >
                                      SIGN UP
                                    </button>
                                    <p className={styleClasses.bottom_text}>
                                      By clicking ‘SIGN UP’, you accept our{' '}
                                      <Link
                                        to={'/privacyPolicy'}
                                        onClick={handCloseFooter}
                                      >
                                        Privacy Policy
                                      </Link>
                                      .
                                    </p>
                                  </Col>
                                </Row>
                              </form>
                            </React.Fragment>
                          )}
                        </Formik>
                      </Col>
                    </Row>
                  )}
                </Container>
              </Modal.Body>
            </Modal>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default EmailListModel;
