import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import {
  ArrowRight,
  CountDownIcon,
  TimerIcon,
} from 'src/assets/images/Svgs/svg';

import Countdown from 'react-countdown';
import { ROUTE_GROUP_ORDER_DETAILS } from 'src/constants';
import { timeStrintToMS } from 'src/helper/helperMethods';
import { getOrderDateTime } from 'src/helper/tracking/orderTracking';
import styles from './futureOrderListing.module.scss';

const FutureOrderListing: FC<any> = (props) => {
  const {
    orderType,
    status,
    date,
    time,
    person,
    platform,
    item_name,
    timeRemaining,
    viewMoreOrder,
    remainingTime = '0',
    fireTime,
    id,
    setMultiOrderModal,
    isProfile = false,
    address,
    handlePrintReceipt
  } = props;
  const history = useHistory();

  const formattedDate = getOrderDateTime(date, false);

  return (
    <>
      <div
        className={`${styles.futureOrderListingWrapper} flex-column flex-md-row align-items-start align-items-md-center`}
      >
        <div className="flex-fill w-100">
          {platform?.toLowerCase() === 'call-center' ? (
            <p className={`${styles.orderType} mb-0 fw-bold text-uppercase`}>
              {item_name}
            </p>
          ) : (
            <>
              <div className={styles.titleWrap}>
                <p
                  className={`${styles.orderType} mb-0 fw-bold text-uppercase`}
                >
                  {orderType}
                </p>
                <div className={styles.orderStatus}>
                  <p className={styles.inProgess}>{status}</p>
                  {/* <p className={styles.groupOrder}>group order</p> */}
                </div>
              </div>
              <p className="f-s18 f-w8 font-Visby-cf clr-red cz-mb-8 cz-mt-16 text-capitalize ">
                {person}
              </p>
            </>
          )}
          <div className={styles.deliveryDetails}>
            {/* <p className="mb-0 text-capitalize">{address}</p> */}
            <p className="mb-0 text-capitalize">{formattedDate}</p>
            <p className="mb-0 text-capitalize">{time}</p>
          </div>
          {timeRemaining && (
            <div>
              <p
                className={`${styles.timeRemaining} font-Visby-cf clr-red cz-mb-8 text-capitalize mb-0 fw-bold`}
              >
                <TimerIcon height="18" width="18" className="cz-mr-8" />
                01:15:32 remaining
              </p>
            </div>
          )}
          {remainingTime !== '0' && (
            <div className="gap-2 d-flex align-items-center pt-2">
              <CountDownIcon />
              <Countdown
                className="f-s16 f-w8 font-Visby-cf clr-red text-capitalize"
                date={Date.now() + timeStrintToMS(remainingTime)}
                autoStart={true}
              />
            </div>
          )}
        </div>
        <div className="w-100">
          {platform?.toLowerCase() === 'call-center' ? null :
            <>
              <button
            className={`${styles.viwBtn} btn-text f-s14 d-flex align-items-center font-raleway p-0 mt-4 mt-md-0 w-100 justify-content-end`}
            onClick={() => {
              !isProfile && setMultiOrderModal(false);
              const route = {
                pathname: `${`${ROUTE_GROUP_ORDER_DETAILS}/${id}`}`,
                state: {
                  isEdit: remainingTime !== '0' && remainingTime ? true : false,
                },
              };
              history.push(route);
            }}
          >
            <span className="f-sm-s14 me-2">
              {remainingTime !== '0' && remainingTime ? 'View/Edit' : 'View'}
            </span>

            <ArrowRight />
              </button>
              {
                handlePrintReceipt &&
                <button
                  className={`${styles.viwBtn} btn-text f-s14 d-flex align-items-center font-raleway p-0 mt-4 mt-md-0 w-100 justify-content-end`}
                  onClick={() => {
                    handlePrintReceipt(id);
                  }}
                >
                  <span className="f-sm-s14 me-2 ">
                    Print Receipt
                  </span>
                </button>

              }
            </>
          
          }
        </div>
      </div>
      {/* {viewMoreOrder && (
        <div className={`${styles.recentOrderBtn} mt-4 mb-4`}>
          <Link
            to="/profileDetails/more-future-orders"
            className="btn btn-custom font-Visby-cf f-w8 f-s14 clr-red p-0 ls-normal text-decoration-underline"
          >
            View More Future Orders...
          </Link>
        </div>
      )} */}
    </>
  );
};

export default FutureOrderListing;
