import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import {
  GUEST_USER,
  INDIVIDUAL_CAFEZUPAS_DEV_ROUTE,
  INDIVIDUAL_CAFEZUPAS_ROUTE,
  ROUTE_LOGIN,
} from 'src/constants';
import useCheckMobileScreen from 'src/hooks/useCheckMobileScreen';
import { useAppSelector } from 'src/redux/store/store';
import styles from './header.module.scss';
import { CateringDirectionIcon } from 'src/assets/images/Svgs/svg';

interface TopHeaderBarProps {
  handleLocationButton: () => void;
  locationLabel: any;
}
const TopHeaderBar = ({ handleLocationButton, locationLabel }) => {
  const { type } = useAppSelector((state) => state.user.user);
  const isGuestUser = type !== GUEST_USER;

  const getBaseUrl = () => {
    return import.meta.env.VITE_REACT_APP_ENV  === 'production'
      ? INDIVIDUAL_CAFEZUPAS_ROUTE
      : INDIVIDUAL_CAFEZUPAS_DEV_ROUTE;
  };

  return (
    <div className={`${styles.topHeaderWrapper}`}>
      <div className="container-fluid px-md-4 d-flex align-items-center flex-column-reverse flex-md-row justify-content-between">
        <div className="d-flex align-items-center mt-1 mt-md-0 justify-content-md-start justify-content-between w-100">
          <div className={styles.switcher}>
            <a
              href={getBaseUrl()}
              className={`${styles.switcherLink}`}
            >
              {' '}
              Individual{' '}
            </a>
            <a
              href={'/'}
              className={`${styles.switcherLink} ${styles.activeLink}`}
            >
              {' '}
              Catering{' '}
            </a>
          </div>
          <div
            className={`d-md-flex d-none cz-ml-16 cz-p align-items-center`}
          >
            <CateringDirectionIcon />
            <p className="mb-0 ms-2 clr-white-clr font-Visby-cf f-w7 f-s16 text-capitalize">
              {" "}
              Looking for Individual?
            </p>
          </div>
          <div className="d-md-none ">
            {isGuestUser ? (
              <button
                type="button"
                className={`${styles.locationNavSection} d-flex justify-content-center ms-auto align-items-center`}
                onClick={handleLocationButton}
              >
                {locationLabel()}
              </button>
            ) : (
              <div className="ms-auto">
                <NavLink to={ROUTE_LOGIN} className={styles.SignInBtn}>
                  Sign In
                </NavLink>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopHeaderBar;
